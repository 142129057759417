const BASE_URL = "https://chekadbamapi.ir";

export const GET_NOTIFICATION = BASE_URL + "/api/registered/notifications";
// REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";
export const POST_REGISTER = BASE_URL + "/api/auth/register";
export const CHANGE_PASSWORD = BASE_URL + "/api/registered/changePassword";

// LOGIN
export const POST_FAKE_LOGIN = "/auth/signin";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";
export const POST_LOGIN = BASE_URL + "/api/auth/login";

export const POST_ACTIVATE = BASE_URL + "/api/auth/activate";

//admin

//admin
// export const GET_CUSOMER_NAME = BASE_URL + "/api/admin/customerlist";
export const GET_CUSTOMER_NAME = BASE_URL + "/api/admin/customerlist";
export const GET_ADMIN_PROJECT_LIST = BASE_URL + "/api/admin/projectlist";
export const GET_ADMIN_MEMBERLIST = BASE_URL + "/api/admin/memberlist";
export const GET_ADMINDASHBOARD = BASE_URL + "/api/admin/dashboard";
export const POST_ADMIN_PROJECT = BASE_URL + "/api/admin/orderproject";
export const GET_ADMIN_ROLELIST = BASE_URL + "/api/admin/roleList";
export const POST_ADMIN_ADDROLETOMEMEBR =
  BASE_URL + "/api/admin/changeMemberRole";
// export const GET_CUSOMER_NAME = BASE_URL + "/api/admin/customerlist";

//customer
export const GET_CUSTOMER_DASHBOARD = BASE_URL + "/api/customer/dashboard";
export const GET_PROJECT_LIST = BASE_URL + "/api/customer/projectlist";
export const GET_PROJECT_PROCESS = BASE_URL + "/api/customer/projectprocess";
export const POST_CUSTOMER_PROJECT_ORDER =
  BASE_URL + "/api/customer/orderproject";

//login
export const GET_CUSTOMER_PROFILE = BASE_URL + "/api/registered/getprofile";

//Accountant
//Accountant
export const GET_PROJECTLIST_ACCOUNTANT =
  BASE_URL + "/api/accounting/projectlist";
export const GET_REQUESTOFPROJECT_ACCOUNTANT =
  BASE_URL + "/api/accounting/reqWorkOfProject";
export const GET_MEMBERREQUESTOFPROJECT_ACCOUNTANT =
  BASE_URL + "/api/accounting/membersOfReqWork";
export const GET_WORKOFREQUESTWORK_ACCOUNTANT =
  BASE_URL + "/api/accounting/worksOfReqWork";
export const GET_WORKERS_ACCOUNTANT = BASE_URL + "/api/accounting/workers";
export const GET_INVOICEDETAILWORKER_ACCOUNTANT =
  BASE_URL + "/api/accounting/invoiceDetail";
export const GET_ALLWORKERINVOICE_ACCOUNTANT =
  BASE_URL + "/api/accounting/allWorkerInvoices";
export const GET_REQUESTWORKINVOICESWORKER_ACCOUNTANT =
  BASE_URL + "/api/accounting/requestWorkWorkerInvoice";
export const GET_WORKERINVOICE_ACCOUNTANT =
  BASE_URL + "/api/accounting/workerInvoices";

export const GET_ACCOUNTANTDASHBOARD = BASE_URL + "/api/Accountant/dashboard";

//Accountant


export const GET_INVOICEOFREQUESTWORK_ACCOUNTANT =
  BASE_URL + "/api/accounting/requestWorkWorkerInvoice";

export const GET_PRODUCTOFPROJECT_ACCOUNTANT =
  BASE_URL + "/api/accounting/projectProductList";
export const GET_REQUESTWORK_ACCOUNTANT =
  BASE_URL + "/api/accounting/requestWorks";





//Warehouse

export const GET_WAREHOUSEDASHBOARD = BASE_URL + "/api/Warehouse/dashboard";
//Worker

export const GET_WORKERDASHBOARD = BASE_URL + "/api/Worker/dashboard";
//Manger
export const POST_MANAGER_EDITWORKCOUNT =
  BASE_URL + "/api/projectmanager/editWorkFormRequestWork";
//Manager
export const GET_MANAGER_PROJECTLIST =
  BASE_URL + "/api/projectmanager/projectlist";
export const GET_REQUESTWORK_MANAGER =
  BASE_URL + "/api/projectmanager/requestWorks";
export const GET_MEMBERREQUESTOFPROJECT_MANAGER =
  BASE_URL + "/api/projectmanager/membersOfReqWork";
export const GET_WORKOFREQUESTWORK_MANAGER =
  BASE_URL + "/api/projectmanager/worksOfReqWork";
export const GET_MANAGER_APPROOVEWORK =
  BASE_URL + "/api/projectmanager/workOfRequestWorkDone";
export const POST_MANAGER_ADDMEMBERHOUR =
  BASE_URL + "/api/projectmanager/addHourToWorker";
export const GET_REQUESTWORKOFPROJECT_MANAGER =
  BASE_URL + "/api/projectmanager/reqWorkOfProject";
export const GET_MANGERDASHBOARD = BASE_URL + "/api/projectmanager/dashboard";
export const GET_PRODUCTSTOPROJECT_MANAGER =
  BASE_URL + "/api/projectmanager/productsOfProject";
//Designer

export const GET_DESIGNERDASHBOARD = BASE_URL + "/api/Designer/dashboard";
//TeamHead

export const GET_TEAMHEADDASHBOARD = BASE_URL + "/api/TeamHead/dashboard";

export const GET_TEAMHEAD_PROJECTLIST = BASE_URL + "/api/teamhead/projectlist";
export const GET_TEAMHEAD_WORKSINREQ = BASE_URL + "/api/teamhead/projectlist";
export const POST_REQUESTWORK_TEAMHEAD = BASE_URL + "/api/teamhead/requestWork";
export const GET_MANAGER_TEAMHEAD = BASE_URL + "/api/teamhead/managerList";
export const GET_PROJECTWORK_TEAMHEAD =
  BASE_URL + "/api/teamhead/worksOfReqWork";
export const GET_PROJECTLISTWORK_TEAMHEAD =
  BASE_URL + "/api/teamhead/workActivities";
export const POST_PROJECTADDWORK_TEAMHEAD =
  BASE_URL + "/api/teamhead/addWorkToRequestWork";
export const POST_PROJECTADDCUSTUMWORK_TEAMHEAD =
  BASE_URL + "/api/teamhead/createWorkActivity";
export const POST_MANAGER_TEAMHEAD =
  BASE_URL + "/api/teamhead/addManagerToReqWork";
//delete
export const POST_DELETEWORK_TEAMHEAD =
  BASE_URL + "/api/teamhead/deleteWorkFromRequestWork";

export const POST_TEAMHEAD_DESIGNCONTRACT =
  BASE_URL + "/api/teamhead/createDesignContract";
export const GET_TEAMHEAD_DESIGNCONTRACTSBYPROJECTID =
  BASE_URL + "/api/teamhead/getContactDesignByProjectId";
export const POST_TEAMHEAD_DESIGNCONTRACTEDIT =
  BASE_URL + "/api/teamhead/createDesignContract";
export const GET_CONTRACTDESIGNDETAIL_TEAMHEAD =
  BASE_URL + "/api/teamhead/getContactDesignByProjectId";
// export const GET_CONTRACTDESIGN_TEAMHEAD = BASE_URL + "/api/teamhead/contractDesign";
export const GET_TEAMHEAD_DASHBOARD = BASE_URL + "/api/teamhead/dashboard";
export const GET_TEAMHEAD_REQWORKS =
  BASE_URL + "/api/teamhead/reqWorkOfProject";
export const GET_CUSOMER_NAME_TEAMHEAD =
  BASE_URL + "/api/teamhead/customerlist";

export const GET_TEAMHEAD_DELETEPROJECTFILE = BASE_URL + "/api/teamhead/deleteProjectFile";
  


export const GET_WORKER_NAME_TEAMHEAD = BASE_URL + "/api/teamhead/workers";
export const GET_PRODUCTS_TEAMHEAD = BASE_URL + "/api/teamhead/productList";
export const GET_ADDPRODUCTSTOPROJECT_TEAMHEAD =
  BASE_URL + "/api/teamhead/projectProductList";
export const GET_TEAMHEAD_MEMBEROFREQWORKS =
  BASE_URL + "/api/teamhead/membersOfReqWork";
export const GET_WORKS_NAME_TEAMHEAD =
  BASE_URL + "/api/teamhead/workActivities";
export const GET_TEAMHEAD_DESIGNCONTRACTS =
  BASE_URL + "/api/teamhead/contractDesign";
export const GET_TEAMHEAD_IMPLIMENTCONTRACT =
  BASE_URL + "/api/teamhead/getImplementContract";
export const GET_TEAMHEAD_IMPLIMENTCONTRACTLIST =
  BASE_URL + "/api/teamhead/contractImplementList";
export const POST_TEAMHEADٍ_PROJECT = BASE_URL + "/api/teamhead/orderproject";
export const POST_TEAMHEADٍCREATEIMPLIMENTCONTRACT_PROJECT =
  BASE_URL + "/api/teamhead/createImplementContract";
export const POST_TEAMHEADٍ_ADDPRODUCT =
  BASE_URL + "/api/teamhead/addProductToProject";
export const POST_TEAMHEADٍ_DELETEPRODUCTFROMPROJECT =
  BASE_URL + "/api/teamhead/deleteProductFromProject";
export const POST_PROJECT_TEAMHEAD = BASE_URL + "/api/teamhead/orderProject";
export const DELETE_PROJECT_TEAMHEAD = BASE_URL + "/api/teamhead/deleteProject";
export const POST_UPDATEPROJECT_TEAMHEAD =
  BASE_URL + "/api/teamhead/editProject";
  export const GET_DETAILEPROJECT_TEAMHEAD =
  BASE_URL + "/api/teamhead/projectDetail";
export const DELETEREQUESTWORK_PROJECT_TEAMHEAD =
  BASE_URL + "/api/teamhead/deleteRequestWork";

//estimate

export const GET_TEAMHEADٍ_ESTIMATEWORKLIST =
  BASE_URL + "/api/teamhead/estimateWorkOfProject";
export const POST_TEAMHEADٍ_ADDESTIMATEWORK =
  BASE_URL + "/api/teamhead/addWorkToEstimateProject";
export const GET_TEAMHEADٍ_DELETESTIMATEWORK =
  BASE_URL + "/api/teamhead/deleteEstimate";

export const UPDATE_CUSTOMER_PROFILE =
  BASE_URL + "/api/registered/updateprofile";

export const POST_DELETEWORKERS_TEAMHEAD =
  BASE_URL + "/api/teamhead/deleteWorkerFromRequestWork";
export const POST_PROJECTADDWORKERS_TEAMHEAD =
  BASE_URL + "/api/teamhead/addWorkerToRequestWork";
export const GET_REQUESTWORKWORKERS_TEAMHEAD =
  BASE_URL + "/api/teamhead/membersOfReqWork";
  export const GET_PROJECTFILESLIST_TEAMHEAD =
  BASE_URL + "/api/teamhead/projectFilesList";
export const POST_REGISTERCUSTOMER_TEAMHEAD =
  BASE_URL + "/api/teamhead/registerCustomer";
  export const DELETE_PROJECTFILES_TEAMHEAD =
  BASE_URL + "/api/teamhead/deleteProjectFile";
  export const ADD_PROJECTFILES_TEAMHEAD =
  BASE_URL + "/api/teamhead/addProjectFile";
  export const GET_APPROVEPROJECT_TEAMHEAD =
  BASE_URL + "/api/teamhead/approveProject";

//TechnicalDeputy
//TechnicalDeputy

export const GET_TECHNICALDEPUTYDASHBOARD =
  BASE_URL + "/api/TechnicalDeputy/dashboard";
export const GET_TECHNICALDEPUTY_APPROVEPROJECT =
  BASE_URL + "/api/TechnicalDeputy/approveProject";
export const GET_TECHNICALDEPUTY_DASHBOARD =
  BASE_URL + "/api/TechnicalDeputy/dashboard";
export const GET_TECHNICALDEPUTY_PROJECTLIST =
  BASE_URL + "/api/TechnicalDeputy/projectlist";
export const GET_TECHNICALDEPUTY_DESIGNCONTRACTLIST =
  BASE_URL + "/api/TechnicalDeputy/designContracts";
export const GET_TECHNICALDEPUTY_IMPLEMENETONTRACTLIST =
  BASE_URL + "/api/TechnicalDeputy/implementContracts";
export const POST_TECHNICALDEPUTY_ADDTEAMTOPROJECT =
  BASE_URL + "/api/TechnicalDeputy/addteamtoproject";
export const GET_TEAM_NAME_TECHNICALDEPUTY =
  BASE_URL + "/api/TechnicalDeputy/teamlist";
export const GET_TECHNICALDEPUTY_REQUESTWORK =
  BASE_URL + "/api/TechnicalDeputy/requestWorks";
export const GET_TECHNICALDEPUTY_APPROOVEREQUESTWORK =
  BASE_URL + "/api/TechnicalDeputy/approverequestwork";
export const GET_TECHNICALDEPUTY_FINISHREQUESTWORK =
  BASE_URL + "/api/TechnicalDeputy/finishRequestWork";
export const GET_TECHNICALDEPUTY_DETAILREQUESTWORK =
  BASE_URL + "/api/TechnicalDeputy/requestWorkDetail";
export const GET_CUSOMER_NAME_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/customerlist";
export const GET_REQUESTOFPROJECT_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/requestWorksOfProject";
export const GET_MEMBERREQUESTOFPROJECT_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/membersOfReqWork";
export const GET_WORKOFREQUESTWORK_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/worksOfReqWork";
export const GET_REQUESTWORK_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/requestWorks";
export const GET_PRODUCTOFPROJECT_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/projectProductList";
// export const GET_ESTIMATEOFPROJECT_TECHINICALDEPUTY =
//   BASE_URL + "/api/technicaldeputy/estimateOfProject";
export const GET_TECHNICALDEPUTY_DESIGNCONTRACTFILE =
  BASE_URL + "/api/TechnicalDeputy/designContactFiles";
export const GET_TECHNICALDEPUTY_DESIGNCONTRACTINVOICEFILE =
  BASE_URL + "/api/TechnicalDeputy/designContactFiles";
  export const GET_TECHNICALDEPUTY_DOWNLOADFILE =
  BASE_URL + "/api/TechnicalDeputy/downloadFile";
export const GET_ESTIMATEOFPROJECT_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/estimateWorkOfProject";
  export const GET_TECHNICALDEPUTY_DESIGNCONTRACTINVOICES =
  BASE_URL + "/api/TechnicalDeputy/designContactInvoices";

  export const GET_TECHNICALDEPUTY_APPROVEDESIGNCONTRACT =
  BASE_URL + "/api/TechnicalDeputy/approvedesigncontract";
  
export const GET_DESIGNCONTRACTINVOICEDETAIL_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/designContactInvoice";
export const GET_TECHNICALDEPUTY_FINISHPROJECT =
  BASE_URL + "/api/TechnicalDeputy/finishProject";
export const GET_IMPCONTRACTDETAIL_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/implementContractDetail";
export const GET_DESIGNCONTRACTDETAIL_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/designContractDetail";
export const GET_IMPCONTRACTAPPROVE_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/approveImplementContract";
export const POST_DELETEWORKERS_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/deleteWorkerFromRequestWork";
export const POST_PROJECTADDWORKERS_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/addWorkerToRequestWork";
export const GET_WORKER_NAME_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/workers";
export const POST_DELETEWORK_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/deleteWorkFromRequestWork";
export const GET_PROJECTLISTWORK_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/workActivities";
export const POST_PROJECTADDWORK_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/addWorkToRequestWork";
export const POST_ADDMEMBERHOUR_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/addHourToWorker";
export const POST_EDITWORKCOUNT_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/editWorkFormRequestWork";
export const GET_DELETEWORKFROMREQUEST_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/deleteWorkFromRequestWork";
export const GET_PRODUCTS_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/productList";
export const POST_ADDPRODUCT_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/addProductToProject";
export const GET_DELETEPRODUCTFROMPROJECT_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/deleteProductFromProject";
export const GET_APPROVEWORKACTIVITY_TECHNICALDEPUTY =
  BASE_URL + "/api/TechnicalDeputy/approveWorkActivity";
export const POST_CREATEWORKACTIVITY_TECHNICALDEPUTY =
  BASE_URL + "/api/TechnicalDeputy/createWorkActivity";
export const POST_DETAILWORKACTIVITY_TECHNICALDEPUTY =
  BASE_URL + "/api/TechnicalDeputy/detailWorkActivity";
export const POST_UPDATEWORKACTIVITY_TECHNICALDEPUTY =
  BASE_URL + "/api/TechnicalDeputy/UpdateWorkActivity";
export const GET_WORKACTIVITY_TECHINICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/workActivities";
  export const GET_APPROOVEWORK_TECHNICALDEPUTY =
  BASE_URL + "/api/technicaldeputy/workOfRequestWorkDone";


//Worker
// export const GET_WORKERDASHBOARD = BASE_URL + "/api/Worker/dashboard";
// export const GET_WORKER_WORKS = BASE_URL + "/api/worker/workList";
export const GET_WORKER_WORKSDETAIL = BASE_URL + "/api/worker/workDetail";
export const GET_WORKER_INVOICES = BASE_URL + "/api/worker/invoiceList";
export const GET_WORKER_INVOICESDETAIL = BASE_URL + "/api/worker/invoiceDetail";
export const GET_WORKER_APPROVEPINVOICE =
  BASE_URL + "/api/worker/approveInvoices";

// PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

export const GET_WORKER_WORKS = BASE_URL + "/api/worker/requestsWork";




// Chat
// export const GET_CHAT = "/get-chat";
export const GET_CHAT = "/api/customer/get-chat";
export const ADD_CHAT = "/add-chat";
export const DELETE_CHAT = "/delete-chat";
export const BOOKMARK_CHAT = "/delete-chat";

// MailBox
export const GET_MAIL = "/get-mail";
export const DELETE_MAIL = "/delete-mail";
export const UNREAD_MAIL = "/unread-mail";
export const STARED_MAIL = "/stared-mail";
export const TRASH_MAIL = "/trash-mail";

// Calendar
export const GET_EVENT = "/get-event";
export const ADD_EVENT = "/add-event";
export const UPDATE_EVENT = "/edit-event";
export const DELETE_EVENT = "/delete-event";

// Category
export const GET_CATEGORY = "/get-category";
export const DELETE_CATEGORY = "/delete-category";

// Ecommerce
// Orders
export const GET_ORDERS = "/get-orders";
export const ADD_ORDERS = "/add-orders";
export const UPDATE_ORDERS = "/edit-orders";
export const DELETE_ORDERS = "/delete-orders";

// Sellers
export const GET_SELLERS = "/get-sellers";
export const ADD_SELLERS = "/add-sellers";
export const UPDATE_SELLERS = "/edit-sellers";
export const DELETE_SELLERS = "/delete-sellers";

// Products
// List View
export const GET_PRODUCT_LIST = "/get-product-list";
export const ADD_PRODUCT_LIST = "/add-product-list";
export const UPDATE_PRODUCT_LIST = "/edit-product-list";
export const DELETE_PRODUCT_LIST = "/delete-product-list";

// Grid View
export const GET_PRODUCT_GRID = "/get-product-grid";
export const ADD_PRODUCT_GRID = "/add-product-grid";
export const UPDATE_PRODUCT_GRID = "/edit-product-grid";
export const DELETE_PRODUCT_GRID = "/delete-product-grid";

// Overview
export const GET_REVIEW = "/get-review";
export const ADD_REVIEW = "/add-review";
export const UPDATE_REVIEW = "/edit-review";
export const DELETE_REVIEW = "/delete-review";

// HR Management
// Employee List
export const GET_EMPLOYEE = "/get-employee";
export const ADD_EMPLOYEE = "/add-employee";
export const UPDATE_EMPLOYEE = "/edit-employee";
export const DELETE_EMPLOYEE = "/delete-employee";

// Holidays
export const GET_HOLIDAYS = "/get-holidays";
export const ADD_HOLIDAYS = "/add-holidays";
export const UPDATE_HOLIDAYS = "/edit-holidays";
export const DELETE_HOLIDAYS = "/delete-holidays";

// Leaves Manage Employee
export const GET_LEAVE_MANAGE_EMPLOYEE = "/get-leave-manage-employee";

// Leave Manage (HR)
export const GET_LEAVE_MANAGE_HR = "/get-leave-manage-hr";
export const ADD_LEAVE_MANAGE_HR = "/add-leave-manage-hr";
export const UPDATE_LEAVE_MANAGE_HR = "/edit-leave-manage-hr";
export const DELETE_LEAVE_MANAGE_HR = "/delete-leave-manage-hr";

// Attendance
// Attendance (HR)
export const GET_ATTENDANCE = "/get-attendance";

// Main Attendance
export const GET_MAIN_ATTENDANCE = "/get-main-attendance";

// Departments
export const GET_DEPARTMENTS = "/get-departments";
export const ADD_DEPARTMENTS = "/add-departments";
export const UPDATE_DEPARTMENTS = "/edit-departments";
export const DELETE_DEPARTMENTS = "/delete-departments";

// Sales
// Estimates
export const GET_ESTIMATES = "/get-estimates";
export const ADD_ESTIMATES = "/add-estimates";
export const UPDATE_ESTIMATES = "/edit-estimates";
export const DELETE_ESTIMATES = "/delete-estimates";

// Payments
export const GET_PAYMENTS = "/get-payments";

// Expenses
export const GET_EXPENSES = "/get-expenses";
export const ADD_EXPENSES = "/add-expenses";
export const UPDATE_EXPENSES = "/edit-expenses";
export const DELETE_EXPENSES = "/delete-expenses";

// Payroll
// Employee Salary
export const GET_EMPLOYEE_SALARY = "/get-employee-salary";
export const ADD_EMPLOYEE_SALARY = "/add-employee-salary";
export const UPDATE_EMPLOYEE_SALARY = "/edit-employee-salary";
export const DELETE_EMPLOYEE_SALARY = "/delete-employee-salary";

// Notes
export const GET_NOTES = "/get-notes";
export const ADD_NOTES = "/add-notes";
export const UPDATE_NOTES = "/edit-notes";
export const DELETE_NOTES = "/delete-notes";

// Social
// Friends
export const GET_SOCIAL_FRIENDS = "/get-social-friends";

// Events
export const GET_SOCIAL_EVENTS = "/get-social-event";
export const ADD_SOCIAL_EVENTS = "/add-social-event";
export const UPDATE_SOCIAL_EVENTS = "/edit-social-event";
export const DELETE_SOCIAL_EVENTS = "/delete-social-event";

// invoice
// export const GET_INVOICE_LIST = "/get-invoice-list";
export const GET_INVOICE_LIST = BASE_URL + "/api/get-invoice-list";

// Users
// List View
export const GET_USER_LIST = "/get-userlist";
export const ADD_USER_LIST = "/add-userlist";
export const UPDATE_USER_LIST = "/edit-userlist";
export const DELETE_USER_LIST = "/delete-userlist";

// Grid View
export const GET_USER_GRID = "/get-usergrid";
export const ADD_USER_GRID = "/add-usergrid";
export const UPDATE_USER_GRID = "/edit-usergrid";
export const DELETE_USER_GRID = "/delete-usergrid";

export function getParameterByName(name: any, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
