// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_PRODUCTS_TECHINICALDEPUTY } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

export interface Products {
  id: number;
  name: string;
}
const api = new APIClient();

interface MemberOfReqWorks {
  products: Products[];
}

let val: MemberOfReqWorks;

const useFetchData = () => {
  const [products, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api.get(GET_PRODUCTS_TECHINICALDEPUTY, null).then((response) => {
          setData(JSON.parse(JSON.stringify(response)));
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return {
    products,
    loading,
  };
};

export default useFetchData;
