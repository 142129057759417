// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_DESIGNCONTRACTDETAIL_TECHINICALDEPUTY } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface DesignContract {
  conNumber: string;
  id: number;
  customerName: string;
  price: number;
  potNumber: string;
  date: string;
  description: string;
  projectCode: string;
  city: string;
  state: string;
  projectAddress: string;
  customerPhone: string;
  isApprovedByTechniacalDeputy: boolean;
}

let val: DesignContract;

const useFetchData = (projectId: any, designContractId: any) => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  if (projectId === null) {
    projectId = 0;
  }
  if (designContractId === null) {
    designContractId = 0;
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_DESIGNCONTRACTDETAIL_TECHINICALDEPUTY, {
            designContractId: designContractId,
            projectId: projectId,
          })
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
