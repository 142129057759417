import { ThunkAction } from "redux-thunk";
import { RootState } from "slices";
import { Action, Dispatch } from "redux";
import { registerFailed, registerSuccess, resetRegister } from "./reducer";
import { POST_REGISTER } from "helpers/url_helper";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface User {
  mobile: string;
  password: string;
  confirmPassword: string;
}

export const registerUser =
  (
    user: User,
    navigate: (path: string) => void
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch: Dispatch) => {
    try {
      let response: any;
      await axios
        .post(POST_REGISTER, user)
        .then((response) => {
          let jsonResultString = JSON.stringify(response);
          const data = JSON.parse(jsonResultString);
          console.log(data);
          if (data.result === 0) {
            toast.success("Success");
          }
        })
        .catch(function (error) {
          toast.error("error");
        });

      if (response) {
        toast.success("Success");
        dispatch(registerSuccess(response));
        navigate("/login");
      }
    } catch (error) {
      toast.error("error");
      dispatch(registerFailed(error));
    }
  };

export const resetRegisterFlag = () => {
  try {
    const response = resetRegister(false);
    return response;
  } catch (error) {
    return error;
  }
};
