import React, { useState } from "react";
import BreadCrumb from "Common/BreadCrumb";

// Icons
import { Save , PartyPopper } from "lucide-react";

// Images
import logoSm from "assets/images/logo-sm.png";
import { Link, useNavigate } from "react-router-dom";
import FetchData from "./FetchData";
import moment from "moment";

function getParameterByName(name: any, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const Overview = () => {
  var requestWorkId = getParameterByName("requestWorkId");
  let { data, loading } = FetchData(requestWorkId);

  const navigate = useNavigate();

  if (loading) return <p>loading ...</p>;
  if (!loading && data === undefined)
    navigate("/worker/dashboard", {
      replace: true,
    });

  if (!loading && data !== undefined)
    return (
      <React.Fragment>
        <BreadCrumb title="Overview" pageTitle="Invoices" />
        <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
          <div className="xl:col-span-12">
            <div className="card print:shadow-none print:border-none">
              <div className="card-body print:hidden">
                <div className="flex flex-col gap-5 md:items-center md:flex-row">
                  <div className="grow">
                    <h6 className="mb-1 text-16">#{data.id}</h6>
                    <ul className="flex items-center gap-3">
                    <li className="text-slate-500">{moment(data.date, "YYYY-MM-DD").format(
                              "YYYY/MM/DD"
                            ) }</li>
                  </ul>
                  </div>
                  <div className="flex items-center gap-2 shrink-0">
                    <button
                      onClick={() => window.print()}
                      type="button"
                      className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    >
                      <Save className="inline-block size-4 ltr:mr-1 rtl:ml-1" />{" "}
                      <span className="align-middle">Save & Print</span>
                    </button>
                  </div>
                  <div className="flex items-center gap-2 shrink-0">
                    <button
                      type="button"
                      className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    >
                      <PartyPopper className="inline-block size-4 ltr:mr-1 rtl:ml-1" />{" "}
                      <span className="align-middle">فاکتور</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="!pt-0 card-body">
                <div className="p-5 border rounded-md md:p-8 border-slate-200 dark:border-zink-500 print:p-0">
                  <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                    <div className="text-center xl:col-span-2 ltr:xl:text-left rtl:xl:text-right">
                      <div className="flex items-center justify-center size-16 mx-auto rounded-md bg-slate-100 dark:bg-zink-600 xl:mx-0">
                        <img src={logoSm} alt="" className="h-8" />
                      </div>
                      <h5 className="mt-4 mb-1">چکادبام</h5>
                      <p className="text-slate-500 dark:text-zink-200">
                        طراحی و ساخت روف گاردن
                      </p>
                    </div>
                    <div className="ltr:xl:text-right rtl:xl:text-left xl:col-start-10 xl:col-span-3">
                      <p className="mb-1 text-slate-500 dark:text-zink-200">
                        شماره مجری:{" "}
                        <span className="font-semibold">
                          {data.managerMobile}
                        </span>
                      </p>
                      <p className="mb-1 truncate text-slate-500 dark:text-zink-200">
                        ایمیل شرکت:{" "}
                        <span className="font-semibold">
                          info@chekadbam.com
                        </span>
                      </p>
                      <p className="mb-1 text-slate-500 dark:text-zink-200">
                        وبسایت:{" "}
                        <Link
                          to="//chekadbam.com"
                          target="_blank"
                          rel="noreferrer"
                          className="font-semibold underline text-custom-500"
                        >
                          www.chekadbam.com
                        </Link>
                      </p>
                      <p className="text-slate-500 dark:text-zink-200">
                        شماره:{" "}
                        <span className="font-semibold">
                          +(98) 21 44484801
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 mt-6 text-center divide-y md:divide-y-0 md:divide-x rtl:divide-x-reverse divide-dashed md:grid-cols-4 divide-slate-200 dark:divide-zink-500">
                    <div className="p-3">
                      <h6 className="mb-1">#{data.id}</h6>
                      <p className="text-slate-500 dark:text-zink-200">
                       کد درخواست کار
                      </p>
                    </div>
                    <div className="p-3">
                      <h6 className="mb-1">{moment(data.date, "YYYY-MM-DD").format(
                              "YYYY/MM/DD"
                            ) }</h6>
                      <p className="text-slate-500 dark:text-zink-200">
                        تاریخ انجام کار
                      </p>
                    </div>
                    <div className="p-3">
                      <h6 className="mb-1">
                        <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
                        {data.finished}
                        </span>
                      </h6>
                      <p className="text-slate-500 dark:text-zink-200">
                        وضعیت
                      </p>
                    </div>
                    <div className="p-3">
                      <h6 className="mb-1">{data.managerName}</h6>
                      <p className="text-slate-500 dark:text-zink-200">
                        نام مجری
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-5 mt-8 md:grid-cols-2">
                    <div>
                      <p className="mb-2 text-sm uppercase text-slate-500 dark:text-zink-200">
                        آدرس پروژه
                      </p>
                      <p className="mb-1 text-slate-500 dark:text-zink-200">
                        {data.address}
                      </p>                    
                    </div>
                  </div>

                  <div className="mt-6 overflow-x-auto">
                    <table className="w-full whitespace-nowrap">
                      <thead className="ltr:text-left rtl:text-right">
                        <tr>
                          <th className="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:text-zink-200 dark:border-zink-500">
                            #
                          </th>
                          <th className="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:text-zink-200 dark:border-zink-500">
                            Item Name
                          </th>
                          <th className="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:text-zink-200 dark:border-zink-500">
                            Quantity
                          </th>
                          <th className="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:text-zink-200 dark:border-zink-500">
                            Amount
                          </th>
                        </tr>
                      </thead>

                      {data.works.map((work) => (
                        <tr>
                          <td className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500">
                            1
                          </td>
                          <td className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500">
                            <h6 className="mb-1">
                              {work.name}
                            </h6>
                          </td>
                          <td className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500">
                            {work.quantity}
                          </td>
                          <td className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500">
                          {work.wholePrice}
                          </td>
                        </tr>
                      ))}
                      <tbody className="before:block before:h-3">
                        <tr>
                          <td colSpan={6}></td>
                          <td className="border-b border-slate-200 px-3.5 py-2.5 text-slate-500 dark:border-zink-500 dark:text-zink-200">
                            Sub Total
                          </td>
                          <td className="border-b border-slate-200 px-3.5 py-2.5 text-slate-500 dark:border-zink-500 dark:text-zink-200">
                            $292
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                  <div className="my-5">
                    <p className="mb-2 text-sm uppercase text-slate-500 dark:text-zink-200">
                      جزییات پرداخت
                    </p>
                    <p className="mb-1 text-slate-500 dark:text-zink-200">
                      روش پرداخت: 
                    </p>
                    <p className="mb-1 text-slate-500 dark:text-zink-200">
                      پرداخت کننده: 
                    </p>
                    <p className="mb-1 text-slate-500 dark:text-zink-200">
                      تاریخ پرداخت: 
                    </p>
                    <p className="mb-0 text-slate-500 dark:text-zink-200">
                      جمع کل: <b>{data.finished}</b>
                    </p>
                  </div>

                  <div className="flex gap-1 px-4 py-3 text-sm border rounded-md md:items-center border-sky-200 text-sky-500 bg-sky-50 dark:bg-sky-400/20 dark:border-sky-500/50">
                    <p>
                      <span className="font-bold">یادداشت:</span> توضیحات مورد نیاز به کاربر
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
};

export default Overview;
