import React from "react";

const Contacts = () => {
    return (
        <React.Fragment>
            <div className="mx-auto mb-16 text-center xl:w-2/5">
                <h4 className="mb-2">Contact Us</h4>
                <p className="text-slate-500 dark:text-zink-200">Fill out the form and a member from our sales team will get back to you within 2 working days, or scroll down for more ways to get in touch.</p>
            </div>

            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4">
                
            </div>
        </React.Fragment>
    );
}

export default Contacts;