import React, { useCallback, useState } from "react";
import useFetchData from "./FetchData";
import { useNavigate } from "react-router-dom";
import {
  GET_TECHNICALDEPUTY_APPROVEPROJECT,
  GET_TECHNICALDEPUTY_FINISHPROJECT,
  POST_TECHNICALDEPUTY_ADDTEAMTOPROJECT,
} from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";
import { ToastContainer, toast } from "react-toastify";
import Modal from "Common/Components/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import useFetchTeams from "./useFetchTeams";
//شمسی
import moment from "moment-jalaali";

const api = new APIClient();

interface Options {
  label: string;
  value?: string;
  isDisabled?: boolean;
  options?: Options[];
}

const TableProjects = () => {
  let x = 1;
  let { data, loading } = useFetchData();
  console.log(data);

  const [show, setShow] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<number>(0);
  const DefaultOptions: Options[] = [];

  let { teams, loadingTeams } = useFetchTeams();

  const [filterByName, setFilterByName] = useState("");

  if (!loadingTeams)
    teams.teams.map((team: any) => {
      const newOption: Options = {
        label: team.fullName,
        value: team.id,
      };
      DefaultOptions.push(newOption);
    });

  // validation
  const validation: any = useFormik({
    initialValues: {
      teamId: 0,
    },
    validationSchema: Yup.object({
      teamId: Yup.string().required("Please Enter Team"),
    }),

    onSubmit: async (values) => {
      try {
        const postData = {
          teamId: values.teamId,
          projectId: projectId,
        };
        console.log(postData);
        await api.create(POST_TECHNICALDEPUTY_ADDTEAMTOPROJECT, postData);
        toast.info("Added to Server");
        window.location.reload();
      } catch (error) {
        toast.error("Error");
      }
      toggle(0);
    },
  });

  const toggle = useCallback(
    (id: any) => {
      setProjectId(id);
      if (show) {
        setShow(false);
      } else {
        setShow(true);

        validation.resetForm();
      }
    },
    [show, validation]
  );
  const history = useNavigate();

  const handleNavigationReqWork = (id: any) => {
    history(`/TechnicalDeputy/project/requestwork?projectId=${id}`);
  };

  const handleNavigationDesignContract = (id: any) => {
    history(`/TechnicalDeputy/designContract/Detail?projectId=${id}`);
  };

  const handleNavigationImplementContract = (id: any) => {
    history(`/TechnicalDeputy/impcontract/detail?projectId=${id}`);
  };

  const handleNavigationEsitmate = (id: any) => {
    history(`/TechnicalDeputy/project/estimate?projectId=${id}`);
  };

  const handleNavigationProduct = (id: any) => {
    history(`/TechnicalDeputy/project/products?projectId=${id}`);
  };

  const handleNavigationApproveProject = (id: any) => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_TECHNICALDEPUTY_APPROVEPROJECT, { projectId: id })
          .then(() => {
            window.location.reload();
          });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const handleNavigationFinishProject = (id: any) => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_TECHNICALDEPUTY_FINISHPROJECT, { projectId: id })
          .then(() => {
            window.location.reload();
          });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const handleSelectChange = (value: any, action: any) => {
    validation.setFieldValue(action.name, value.value);
  };

  if (loading) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-12 text-15">لیست پروژه‌ها</h6>
          {/* <input
            type="text"
            placeholder="Search by Employee Name"
            value={filterByName}
            onChange={(e) => setFilterByName(e.target.value)}
          /> */}
          <div className="overflow-x-auto">
            <table className="w-full bg-custom-50 dark:bg-custom-500/10">
              <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                <tr>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    ردیف
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    تاریخ
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    تیم
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    آدرس
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    نام کارفرما
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    وضعیت
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    وضعیت تایید
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    عملیات
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  data.projects.map((project: any) => (
                    <tr key={project.id}>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <a
                          href="#!" // Use downloadLink if available, otherwise fallback to "#"
                          className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                        >
                          {x++}
                        </a>
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project && project.createdDate
                          ? moment(project.createdDate, "YYYY-MM-DD").format(
                              "jYYYY/jMM/jDD"
                            )
                          : "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.teamName || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.address || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.employeName || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.approvedByTechnicalDeputy ? (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-custom-500 border-custom-500 text-custom-50">
                            Approved
                          </span>
                        ) : (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-red-500 border-red-500 text-red-50">
                            Not Approved
                          </span>
                        )}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.isFinished ? (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-custom-500 border-custom-500 text-custom-50">
                            Finished
                          </span>
                        ) : (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-red-500 border-red-500 text-red-50">
                            Not Finished
                          </span>
                        )}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "blue",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                            marginRight: "3px",
                            marginTop: "3px",
                          }}
                          onClick={() => handleNavigationReqWork(project.id)}
                          className="btn btn-primary"
                        >
                          جبهه های کاری
                        </button>
                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "blue",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                            marginRight: "3px",
                            marginTop: "3px",
                          }}
                          onClick={() => handleNavigationProduct(project.id)}
                          className="btn btn-primary"
                        >
                          محصولات
                        </button>
                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "blue",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                            marginRight: "3px",
                            marginTop: "3px",
                          }}
                          onClick={() => handleNavigationEsitmate(project.id)}
                          className="btn btn-primary"
                        >
                          برآورد کارهای پروژه
                        </button>
                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "blue",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                            marginRight: "3px",
                            marginTop: "3px",
                          }}
                          onClick={() =>
                            handleNavigationImplementContract(project.id)
                          }
                          className="btn btn-primary"
                        >
                          قرارداد اجرا
                        </button>

                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "blue",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                            marginRight: "3px",
                            marginTop: "3px",
                          }}
                          onClick={() =>
                            handleNavigationDesignContract(project.id)
                          }
                          className="btn btn-primary"
                        >
                          قرارداد طراحی
                        </button>

                        {!project.approvedByTechnicalDeputy ? (
                          <button
                            style={{
                              display: "inline-block",
                              backgroundColor: "green",
                              color: "white",
                              padding: "10px 20px 10px",
                              border: "none",
                              marginRight: "3px",
                              marginTop: "3px",
                            }}
                            onClick={() =>
                              handleNavigationApproveProject(project.id)
                            }
                            className="btn btn-primary"
                          >
                            تایید پروژه
                          </button>
                        ) : (
                          ""
                        )}

                        {!project.isFinished ? (
                          <button
                            style={{
                              display: "inline-block",
                              backgroundColor: "red",
                              color: "white",
                              padding: "10px 20px 10px",
                              border: "none",
                              marginRight: "3px",
                              marginTop: "3px",
                            }}
                            onClick={() =>
                              handleNavigationFinishProject(project.id)
                            }
                            className="btn btn-primary"
                          >
                            پایان پروژه
                          </button>
                        ) : (
                          ""
                        )}

                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "gray",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                            marginRight: "3px",
                            marginTop: "3px",
                          }}
                          onClick={() => toggle(project.id)}
                          className="btn btn-primary"
                        >
                          محول به تیم
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* User Modal */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
        >
          <Modal.Title className="text-16">انتخاب تیم</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              data-choices
              name="teamId"
              options={DefaultOptions}
              value={DefaultOptions.find(
                (option) => option.value === validation.values.teamId
              )}
              onChange={handleSelectChange}
              onBlur={() => validation.setFieldTouched("teamId", true)}
            />
            {validation.touched.teamId && validation.errors.teamId ? (
              <div className="text-red-500">{validation.errors.teamId}</div>
            ) : null}

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer theme="colored" />
    </React.Fragment>
  );
};

export default TableProjects;
