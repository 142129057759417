import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { userService } from "../helpers/user";

interface AuthProtectedProps {
  children: ReactNode;
  permission: any;
}

const hasAuthUserAccessToThisRoute = (permissions: {
  deniedRoles: any;
  allowedRoles: any;
}) => {
  if (!userService.isAuthUserLoggedIn()) {
    return false;
  }

  if (
    Array.isArray(permissions.deniedRoles) &&
    Array.isArray(permissions.allowedRoles)
  ) {
    throw new Error(
      "Don't set both 'deniedRoles' and 'allowedRoles' in route data."
    );
  }

  if (Array.isArray(permissions.allowedRoles)) {
    const isInRole = userService.isAuthUserInRoles(permissions.allowedRoles);
    if (isInRole) {
      return true;
    }
  }

  return false;
};

const AuthProtected: React.FC<AuthProtectedProps> = ({
  permission,
  children,
}) => {
  if (!hasAuthUserAccessToThisRoute(permission)) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthProtected;
