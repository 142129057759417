// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_PROJECTWORK_TEAMHEAD } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

export interface ReqWork {
  id: number;
  name: string;
  unit: string;
  description: string;
  height: number;
  width: number;
  length: number;
  count: number;
  isDone: boolean;
}
const api = new APIClient();

interface ReqWorks {
  worksOfReqWorks: ReqWork[];
}

let val: ReqWorks;

const useFetchData = (id: any) => {
  const [reqWorks, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_PROJECTWORK_TEAMHEAD, { reqWorkId: id })
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    reqWorks,
    loading,
  };
};

export default useFetchData;
