// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_IMPCONTRACTDETAIL_TECHINICALDEPUTY } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface DesignContract {
  contractNumber: string;
  date: string;
  customerName: string;
  price: number;
  projectId: number;
  approvedByTechincalDeputy: boolean;
}

let val: DesignContract;

const useFetchData = (projectId: any, impContractId: any) => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_IMPCONTRACTDETAIL_TECHINICALDEPUTY, {
            projectId: projectId,
            impContractId: impContractId,
          })
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
