import {
  Award,
  CalendarDays,
  CircuitBoard,
  Codesandbox,
  FileText,
  LifeBuoy,
  LocateFixed,
  Mail,
  Map,
  MessageSquare,
  MonitorDot,
  PackagePlus,
  PictureInPicture2,
  PieChart,
  RadioTower,
  ScrollText,
  Share2,
  ShoppingBag,
  Table,
  Trophy,
  UserRound,
} from "lucide-react";

const menuDataAdmin: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "dashboard",
    label: "Dashboards",
    link: "/admin/dashboard",
    icon: <MonitorDot />,
    subItems: [
      {
        id: "analyticsdashboard",
        label: "Analytics",
        link: "/dashboards-analytics",
        parentId: "dashboard",
      },
      // {
      //   id: "ecommercedashboard",
      //   label: "Ecommerce",
      //   link: "/dashboard",
      //   parentId: "dashboard",
      // },
      // {
      //   id: "emaildashboard",
      //   label: "Email",
      //   link: "/dashboards-email",
      //   parentId: "dashboard",
      // },
    ],
  },

  //فرم ثبت پروژه
  {
    id: "Form",
    label: "Create Project",
    // link: "/forms-createprojectform",
    link: "/admin/project/order",
    icon: <PictureInPicture2 />,
    // subItems: [
    //   {
    //     id: "form",
    //     label: "Create Project",
    //     link: "/forms-createprojectform",
    //     parentId: "Form",
    //   },
    // ],
  },

  {
    id: "Form",
    label: "List Projects",
    // link: "/forms-createprojectform",
    link: "/admin/project/projects-list",
    icon: <PictureInPicture2 />,
    // subItems: [
    //   {
    //     id: "form",
    //     label: "Create Project",
    //     link: "/forms-createprojectform",
    //     parentId: "Form",
    //   },
    // ],
  },

  // {
  //   label: "Apps",
  //   isTitle: true,
  // },
  // {
  //   id: "chat",
  //   label: "Chat",
  //   icon: <MessageSquare />,
  //   link: "/apps-chat",
  //   parentId: 2,
  // },
  {
    id: "tickets",
    label: "Tickets",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "submitTicket",
        label: "Submit Ticket",
        link: "/admin/tickets/submitticket",
        parentId: "landing",
      },
      {
        id: "ticketList",
        label: "Ticket List",
        link: "/admin/tickets/ticketlist",
        parentId: "landing",
      },
    ],
  },
  // {
  //   id: "email",
  //   label: "Email",
  //   icon: <Mail />,
  //   link: "/apps-mailbox",
  //   parentId: 2,
  // },
  // {
  //   id: "calendar",
  //   label: "Calendar",
  //   link: "/#",
  //   icon: <CalendarDays />,
  //   subItems: [
  //     {
  //       id: "default",
  //       label: "Default",
  //       link: "/apps-calendar",
  //       parentId: "calendar",
  //     },
  //     {
  //       id: "monthgrid",
  //       label: "Month Grid",
  //       link: "/apps-calendar-month-grid",
  //       parentId: "calendar",
  //     },
  //     {
  //       id: "multimonth",
  //       label: "Multi Month Stack",
  //       link: "/apps-calendar-multi-month-stack",
  //       parentId: "calendar",
  //     },
  //   ],
  // },
  // {
  //   id: "ecommerce",
  //   label: "Ecommerce",
  //   link: "/#",
  //   icon: <ShoppingBag />,
  //   subItems: [
  //     {
  //       id: "product",
  //       label: "Products",
  //       parentId: "ecommerce",
  //       subItems: [
  //         {
  //           id: "listview",
  //           label: "List View",
  //           link: "/apps-ecommerce-product-list",
  //           parentId: "product",
  //         },
  //         {
  //           id: "gridview",
  //           label: "Grid View",
  //           link: "/apps-ecommerce-product-grid",
  //           parentId: "product",
  //         },
  //         {
  //           id: "overview",
  //           label: "Overview",
  //           link: "/apps-ecommerce-product-overview",
  //           parentId: "product",
  //         },
  //         {
  //           id: "addnew",
  //           label: "Add New",
  //           link: "/apps-ecommerce-product-create",
  //           parentId: "product",
  //         },
  //       ],
  //     },
  //     {
  //       id: "shoppingcart",
  //       label: "Shopping Cart",
  //       link: "/apps-ecommerce-cart",
  //       parentId: "ecommerce",
  //     },
  //     {
  //       id: "checkout",
  //       label: "Checkout",
  //       link: "/apps-ecommerce-checkout",
  //       parentId: "ecommerce",
  //     },
  //     {
  //       id: "order",
  //       label: "Orders",
  //       link: "/apps-ecommerce-orders",
  //       parentId: "ecommerce",
  //     },
  //     {
  //       id: "orderoverview",
  //       label: "Order Overview",
  //       link: "/apps-ecommerce-order-overview",
  //       parentId: "ecommerce",
  //     },
  //     {
  //       id: "sellers",
  //       label: "Sellers",
  //       link: "/apps-ecommerce-sellers",
  //       parentId: "ecommerce",
  //     },
  //   ],
  // },
  {
    id: "hr-management",
    label: "HR Management",
    icon: <CircuitBoard />,
    parentId: "hrmanagement",
    link: "/#",
    subItems: [
      {
        id: "employeelist",
        label: "Employee List",
        link: "/apps-hr-employee",
        parentId: "hrmanagement",
      },
      {
        id: "Customer List",
        label: "Customer List",
        link: "/apps-hr-holidays",
        parentId: "hrmanagement",
      },
      // {
      //   id: "leavesmanage",
      //   label: "Leaves Manage",
      //   parentId: "hrmanagement",
      //   subItems: [
      //     {
      //       id: "byemployee",
      //       label: "By Employee",
      //       link: "/apps-hr-leave-employee",
      //       parentId: "leavesmanage",
      //     },
      //     {
      //       id: "addleaveemployee",
      //       label: "Add Leave (Employee)",
      //       link: "/apps-hr-create-leave-employee",
      //       parentId: "leavesmanage",
      //     },
      //     {
      //       id: "byhr",
      //       label: "By HR",
      //       link: "/apps-hr-leave",
      //       parentId: "leavesmanage",
      //     },
      //     {
      //       id: "addleavehr",
      //       label: "Add Leave (HR)",
      //       link: "/apps-hr-create-leave",
      //       parentId: "leavesmanage",
      //     },
      //   ],
      // },
      // {
      //   id: "attendance",
      //   label: "Attendance",
      //   parentId: "hrmanagement",
      //   subItems: [
      //     {
      //       id: "attendancehr",
      //       label: "Attendance (HR)",
      //       link: "/apps-hr-attendance",
      //       parentId: "attendance",
      //     },
      //     {
      //       id: "mainattendance",
      //       label: "Main Attendance",
      //       link: "/apps-hr-attendance-main",
      //       parentId: "attendance",
      //     },
      //   ],
      // },
      // {
      //   id: "department",
      //   label: "Department",
      //   link: "/apps-hr-department",
      //   parentId: "hrmanagement",
      // },
      {
        id: "sale",
        label: "Sales",
        parentId: "hrmanagement",
        subItems: [
          {
            id: "estimates",
            label: "Estimates",
            link: "/apps-hr-sales-estimates",
            parentId: "sale",
          },
          {
            id: "payments",
            label: "Payments",
            link: "/apps-hr-sales-payments",
            parentId: "sale",
          },
          {
            id: "expenses",
            label: "Expenses",
            link: "/apps-hr-sales-expenses",
            parentId: "sale",
          },
        ],
      },
      // {
      //   id: "payroll",
      //   label: "Payroll",
      //   parentId: "hrmanagement",
      //   subItems: [
      //     {
      //       id: "employeesalary",
      //       label: "Employee Salary",
      //       link: "/apps-hr-payroll-employee-salary",
      //       parentId: "payroll",
      //     },
      //     {
      //       id: "payslip",
      //       label: "Payslip",
      //       link: "/apps-hr-payroll-payslip",
      //       parentId: "payroll",
      //     },
      //     {
      //       id: "createpayslip",
      //       label: "Create Payslip",
      //       link: "/apps-hr-payroll-create-payslip",
      //       parentId: "payroll",
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   id: "notes",
  //   label: "Notes",
  //   icon: <ScrollText />,
  //   link: "/apps-notes",
  //   parentId: 2,
  // },
  // {
  //   id: "social",
  //   label: "Social",
  //   icon: <RadioTower />,
  //   subItems: [
  //     {
  //       id: "friends",
  //       label: "Friends",
  //       link: "/apps-social-friends",
  //       parentId: "social",
  //     },
  //     {
  //       id: "event",
  //       label: "Event",
  //       link: "/apps-social-event",
  //       parentId: "social",
  //     },
  //     {
  //       id: "watchvideo",
  //       label: "Watch Video",
  //       link: "/apps-social-video",
  //       parentId: "social",
  //     },
  //     {
  //       id: "marketplace",
  //       label: "Marketplace",
  //       link: "/apps-social-marketplace",
  //       parentId: "social",
  //     },
  //   ],
  // },
  {
    id: "invoice",
    label: "Invoices",
    icon: <FileText />,
    parentId: 2,
    subItems: [
      {
        id: "invoicelistview",
        label: "Listview",
        link: "/apps-invoice-list",
        parentId: "invoice",
      },
      {
        id: "invoiceaddnew",
        label: "Add New",
        link: "/apps-invoice-add-new",
        parentId: "invoice",
      },
      {
        id: "invoiceoverview",
        label: "Overview",
        link: "/apps-invoice-overview",
        parentId: "invoice",
      },
    ],
  },
  {
    id: "users",
    label:"تنظیمات اکانت",
    icon: <UserRound />,
    parentId: 2,
    subItems: [
      {
        id: "تنظیمات اکانت",
        label: "اکانت",
        link: "/user-profile",
        parentId: "users",
      },
      // {
      //   id: "usergridview",
      //   label: "Grid View",
      //   link: "/apps-users-grid",
      //   parentId: "users",
      // },
    ],
  },
  {
    label: "Pages",
    isTitle: true,
  },
  {
    id: "Users",
    label: "Users",
    icon: <Codesandbox />,
    parentId: 2,
    subItems: [
      {
        id: "UserList",
        label: "UserList",
        link: "/admin/users/list",
        parentId: "pages",
      },
    ],
  },
  {
    label: "Pages",
    isTitle: true,
  },
  {
    id: "pages",
    label: "Pages",
    icon: <Codesandbox />,
    parentId: 2,
    subItems: [
      {
        id: "account",
        label: "Account",
        link: "/pages-account",
        parentId: "pages",
      },
      {
        id: "setting",
        label: "Settings",
        link: "/pages-account-settings",
        parentId: "pages",
      },
      {
        id: "faq",
        label: "FAQs",
        link: "/pages-faqs",
        parentId: "pages",
      },
      {
        id: "contactus",
        label: "Contact US",
        link: "/pages-contact-us",
        parentId: "pages",
      },
    ],
  },
];

export { menuDataAdmin };
