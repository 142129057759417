import React from "react";
import BreadCrumb from "Common/BreadCrumb";
import { BarChartHorizontalBig, Percent, Rocket, ShoppingCart, Store } from "lucide-react";
import useFetchDataProcess from "./useFetchDataProcess";
import { withTranslation } from "react-i18next";
import { number } from "yup";
//شمسی
import moment from "moment-jalaali";
const CustomerProjectProcess = (props:any) => {
  function getParameterByName(name: any, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  var userMobile = getParameterByName("id");
  //console.log("Project ID:", userMobile); // Check the extracted ID

  let x = 1;
  let { data, loading } = useFetchDataProcess(userMobile);

if (!data) return <p>No data available.</p>;
console.log(data);
if (data)
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          {data?.projectProcesses?.map((projectProcess) => (
            <div key={projectProcess.projectId} className="relative">
              {" "}
              {/* {x++} */}
              <div className="before:absolute ltr:before:border-l-2 rtl:before:border-r-2 ltr:before:left-3.5 rtl:before:right-3.5 before:top-1.5 before:-bottom-1.5 pb-4 dark:before:border-zink-500">
                <div className="relative flex gap-2">
                  <div className="size-8 p-0.5 bg-white text-green-500 flex items-center justify-center border rounded-full shrink-0 border-slate-200 dark:border-zink-500 dark:bg-zink-700">
                    <Rocket className="size-4"></Rocket>
                  </div>
                  <div>
                    <h6 className="mb-1">{projectProcess.note}</h6>
                    <p className="mb-2 text-slate-500 dark:text-zink-200">
                      {projectProcess.teamName}
                    </p>
                    <p className="text-sm text-slate-500 dark:text-zink-200">
                      {projectProcess.isDone}
                    </p>
                    <p className="text-sm text-slate-500 dark:text-zink-200">
                      {projectProcess.createDate
                        ? moment(
                            projectProcess.createDate,
                            "YYYY-MM-DD"
                          ).format("jYYYY/jMM/jDD")
                        : "-"}
                    </p>
                    <p className="text-sm text-slate-500 dark:text-zink-200">
                      {projectProcess.endDate
                        ? moment(
                            projectProcess.endDate,
                            "YYYY-MM-DD"
                          ).format("jYYYY/jMM/jDD")
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(CustomerProjectProcess);
