// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_REQUESTWORKWORKERS_TEAMHEAD } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

export interface ReqWork {
  id: number;
  fullName: string;
  role: string;
  level: number;
  ProjectCode :string;
  hour:number;
  memberId : number;
}
const api = new APIClient();

interface ReqWorkersList {
  membersOfReqWorks: ReqWork[];
}

let val: ReqWorkersList;

const useFetchDataWorkersList = (id:any) => {
  const [reqWorkersList, setData] = useState(val);
  const [loading1, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_REQUESTWORKWORKERS_TEAMHEAD, {reqWorkId:id})
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    reqWorkersList,
    loading1,
  };
};

export default useFetchDataWorkersList;
