import {
  Award,
  CalendarDays,
  CircuitBoard,
  Codesandbox,
  FileText,
  LifeBuoy,
  LocateFixed,
  Mail,
  Map,
  MessageSquare,
  MonitorDot,
  PackagePlus,
  PictureInPicture2,
  PieChart,
  RadioTower,
  ScrollText,
  Share2,
  ShoppingBag,
  Table,
  Trophy,
  UserRound,
} from "lucide-react";

const menuDataDesigner: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "dashboard",
    label: "Dashboards",
    link: "/Designer/dashboard",
    icon: <MonitorDot />,
  },
  {
    id: "Projects",
    label: "project Page",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "orderProject",
        label: "Order Project",
        link: "/Designer/orderproject",
        parentId: "landing",
      },
      {
        id: "projectList",
        label: "Product",
        link: "/Designer/projectlist",
        parentId: "landing",
      },
    ],
  },
  {
    id: "tickets",
    label: "Tickets",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "submitTicket",
        label: "Submit Ticket",
        link: "/Designer/tickets/submitticket",
        parentId: "landing",
      },
      {
        id: "ticketList",
        label: "Ticket List",
        link: "/Designer/tickets/ticketlist",
        parentId: "landing",
      },
    ],
  },
  {
    id: "Accounting",
    label: "Accounting",
    link: "/Designer/accounting",
    icon: <PictureInPicture2 />,
  },
  {
    label: "Account",
    isTitle: true,
  },
  {
    id: "users",
    label:"تنظیمات اکانت",
    icon: <UserRound />,
    parentId: 2,
    subItems: [
      {
        id: "تنظیمات اکانت",
        label: "اکانت",
        link: "/user-profile",
        parentId: "users",
      },
      // {
      //   id: "usergridview",
      //   label: "Grid View",
      //   link: "/apps-users-grid",
      //   parentId: "users",
      // },
    ],
  },
  {
    label: "Pages",
    isTitle: true,
  },

  {
    id: "pages",
    label: "Pages",
    icon: <Codesandbox />,
    parentId: 2,
    subItems: [
      {
        id: "faq",
        label: "FAQs",
        link: "/pages-faqs",
        parentId: "pages",
      },
      {
        id: "contactus",
        label: "Contact US",
        link: "/pages-contact-us",
        parentId: "pages",
      },
    ],
  },
];

export { menuDataDesigner };
