// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_CUSOMER_NAME_TEAMHEAD } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface Customer {
  id: string;
  fullName: number;
  mobile:number;
  city:string;
}

interface Customers {
  members: Customer[];
}

let val: Customers;

const useFetchDataCustomer = () => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api.get(GET_CUSOMER_NAME_TEAMHEAD, null).then((response) => {
          setData(JSON.parse(JSON.stringify(response)));
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchDataCustomer;
