// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_WORKER_INVOICES } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface WokerInvoice {
  projectAddress: string;
  teamName: string;
  date: string;
  managerName: string;
  amount: number;
  hourlyPrice: number;
  wholeDayAmount: number;
  description: string;
  approved: boolean;
  id: number;
}

interface WokerInvoices {
  wokerInvoices: WokerInvoice[];
}

let val: WokerInvoices;

const useFetchData = () => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api.get(GET_WORKER_INVOICES, null).then((response) => {
          setData(JSON.parse(JSON.stringify(response)));
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
