import React, { useCallback, useState } from "react";

import { APIClient } from "helpers/api_helper";
import {
  GET_DELETEPRODUCTFROMPROJECT_TECHINICALDEPUTY,
  POST_ADDPRODUCT_TECHINICALDEPUTY,
  POST_TEAMHEADٍ_ADDPRODUCT,
  POST_TEAMHEADٍ_DELETEPRODUCTFROMPROJECT,
  getParameterByName,
} from "helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import Modal from "Common/Components/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import useFetchData from "./useFetchData";
import "react-toastify/dist/ReactToastify.css";
import { Plus } from "lucide-react";

import useFetchProduct from "./useFetchProduct";
const api = new APIClient();

interface Options {
  label: string;
  value?: string;
  isDisabled?: boolean;
  options?: Options[];
}

const TableProductProject = () => {
  let x = 1;
  var projectId = getParameterByName("projectId");
  // let { members, loading } = useFetchData(projectId);

  const DefaultOptions: Options[] = [];

  let { projectProducts, loadingProduct } = useFetchProduct(projectId);
  let { products, loading } = useFetchData();

  if (!loading)
    products.products.map((product: any) => {
      const newOption: Options = {
        label: product.name,
        value: product.id,
      };
      DefaultOptions.push(newOption);
    });

  const [show, setShow] = useState<boolean>(false);
  // validation
  const validation: any = useFormik({
    initialValues: {
      customerId: "",
      date: "",
      country: "",
    },
    validationSchema: Yup.object({
      customerId: Yup.string().required("محصول را وارد کنید"),
      country: Yup.string().required("تعداد را وارد کنید"),
    }),

    onSubmit: async (values) => {
      try {
        const postDate = {
          productId: values.customerId,
          projectId: projectId,
          quantity: values.country,
        };
        console.log(postDate);
        await api.create(POST_ADDPRODUCT_TECHINICALDEPUTY, postDate);
        toast.info("Added to Server");
        window.location.reload();
      } catch (error) {
        toast.error("Error");
      }
      toggle();
    },
  });
  const handleDelete = async (productId: any) => {
    try {
      const postDate = {
        productId: productId,
        projectId: Number(projectId),
      };
      console.log(postDate);
      await api.create(GET_DELETEPRODUCTFROMPROJECT_TECHINICALDEPUTY, postDate);
      toast.info("Added to Server");
      // Reload the page to reflect changes
      window.location.reload();
    } catch (error) {
      toast.error("Error");
    }
  };

  const handleSelectChange = (value: any, action: any) => {
    validation.setFieldValue(action.name, value.value);
  };

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);

      validation.resetForm();
    }
  }, [show, validation]);

  if (loadingProduct) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-12 text-15">اضافه کردن محصولات</h6>
          <button
            type="button"
            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
            onClick={toggle}
          >
            <Plus className="inline-block size-3" />{" "}
            <span className="align-middle">اضافه کردن اقلام </span>
          </button>
          <div className="overflow-x-auto">
            <table className="w-full bg-custom-50 dark:bg-custom-500/10">
              <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                <tr>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    ردیف
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    محصولات
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    تعداد
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    قیمت
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    عملیات
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr> */}
                {!loadingProduct &&
                  projectProducts.projectProducts.map((product, index) => (
                    <tr key={product.productId + index}>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <a
                          href="#!" // Use downloadLink if available, otherwise fallback to "#"
                          className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                        >
                          {x++}
                        </a>
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {product.name || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {product.count || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {product.wholePrice || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <button
                          onClick={() => handleDelete(product.productId)}
                          style={{
                            display: "inline-block",
                            backgroundColor: "red",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                          }}
                          className="btn btn-primary"
                        >
                          حذف
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* User Modal */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
        >
          <Modal.Title className="text-16">اضافه کردن محصولات</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              data-choices
              name="customerId"
              options={DefaultOptions}
              value={DefaultOptions.find(
                (option) => option.value === validation.values.customerId
              )}
              onChange={handleSelectChange}
              onBlur={() => validation.setFieldTouched("customerId", true)}
            />
            {validation.touched.customerId && validation.errors.customerId ? (
              <div className="text-red-500">{validation.errors.customerId}</div>
            ) : null}

            <label
              htmlFor="countryInput"
              className="inline-block mb-2 text-base font-medium"
            >
              تعداد یا متراژ
            </label>
            <input
              type="text"
              name="country"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder=" تعداد یا متراژ"
              value={validation.values.country}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.country && validation.errors.country ? (
              <div className="text-red-500">{validation.errors.country}</div>
            ) : null}

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer theme="colored" />
    </React.Fragment>
  );
};

export default TableProductProject;
