// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_TEAMHEAD_PROJECTLIST } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface Projects {
  address: string;
  areaSize: number;
  buildingType: string;
  city?: string;
  country?: string;
  createdDate: string;
  employeName:string;
  approvedByTechnicalDeputy:boolean;
  id: number;
  numberOfFloor?: number;
  projectCode: string;
  spaceType?: string;
  teamName: string;
  doneByTeamHead:boolean,
  isApprovedByTeamHead:boolean,
}

interface MyData {
  projects: Projects[];
}

let val: MyData;

const useFetchData = () => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api.get(GET_TEAMHEAD_PROJECTLIST, null).then((response) => {
          setData(JSON.parse(JSON.stringify(response)));
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
