import React from "react";
import BreadCrumb from "Common/BreadCrumb";

import { Nav } from "Common/Components/Tab/Nav";
import Tab from "Common/Components/Tab/Tab";
import PersonalTabs from "./PersonalTabs";

import { withTranslation } from "react-i18next";

const CustomerSettings = (props: any) => {
  return (
    <React.Fragment>
      <BreadCrumb title="کارها" pageTitle="ایجاد" />
      <Tab.Container defaultActiveKey="personalTabs">
        <Tab.Content>
          <Tab.Pane eventKey="personalTabs">
            <PersonalTabs />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </React.Fragment>
  );
};

export default withTranslation()(CustomerSettings);
