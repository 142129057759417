import React from "react";
import BreadCrumb from "Common/BreadCrumb";
import WelcomeWidget from "./WelcomeWidget";
import Widgets from "./Widgets";

import { withTranslation } from "react-i18next";

const WorkerDashboard = (props: any) => {
  return (
    <React.Fragment>
      <BreadCrumb
        title={props.t("Worker Dashboard")}
        pageTitle={props.t("Worker Dashboard")}
      />
      <div className="grid grid-cols-12 gap-x-5">
        <WelcomeWidget />
      </div>
      <Widgets />
    </React.Fragment>
  );
};

export default withTranslation()(WorkerDashboard);
