// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_WORKER_WORKSDETAIL } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface WorkDetail {
  id: number;
  date: string;
  managerName: string;
  managerMobile: string;
  teamName: string;
  city: string;
  address: string;
  note: string;
  finished: string;
  projectType: string;
  cargoElevator: boolean;
  works: Works[];

}

interface Works {
  name: string;
  quantity: number;
  wholePrice: number;
}

let val: WorkDetail;

const useFetchData = (id: any) => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_WORKER_WORKSDETAIL, { requestWorkId: id })
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
