// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_PROJECTLISTWORK_TEAMHEAD } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface workActivity {
  id: number;
  name: string;
}

interface workActivities {
  workActivities: workActivity[];
}

let val: workActivities;

const useFetchWorkActivities = () => {
  const [dataworks, setData] = useState(val);
  const [loadingworks, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_PROJECTLISTWORK_TEAMHEAD,null)
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  // console.log(data);
  return {
    dataworks,
    loadingworks,
  };
};

export default useFetchWorkActivities;
