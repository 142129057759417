// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_MANAGER_TEAMHEAD } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

export interface Manager {
  id: number;
  name: string;
}
const api = new APIClient();

interface Managers {
  managers: Managers[];
}

let val: Managers;

const useFetchDataManager = () => {
  const [managers, setData] = useState(val);

  const [loading1, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_MANAGER_TEAMHEAD, null)
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    managers,
    loading1,
  };
};

export default useFetchDataManager;
