import React from "react";
import BreadCrumb from "Common/BreadCrumb";

// Icons
import { Save } from "lucide-react";

// Images
import logoSm from "assets/images/logo-sm.png";
import { useNavigate } from "react-router-dom";
import FetchData from "./FetchData";
import moment from "moment-jalaali";
import { getParameterByName } from "helpers/url_helper";

const Overview = () => {
  var projectId = getParameterByName("projectId");
  var designContractId = getParameterByName("designContractId");

  let { data, loading } = FetchData(projectId, designContractId);
  const history = useNavigate();

  if (!loading && data === undefined) {
    history(`/TechnicalDeputy/designcontract/notfound`);
  }

  const handleNavigationDesignContractFiles = (id: any) => {
    history(`/TechnicalDeputy/designcontract/files?designContractId=${id}`);
  };

  const handleNavigationDesignContractInvoices = (id: any) => {
    history(`/TechnicalDeputy/designcontract/invoices?designContractId=${id}`);
  };
  if (loading) return <p>loading ...</p>;

  if (!loading && data !== undefined)
    return (
      <React.Fragment>
        <BreadCrumb title="جزییات" pageTitle="قرارداد" />
        <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
          <div className="xl:col-span-12">
            <div className="card print:shadow-none print:border-none">
              <div className="card-body print:hidden">
                <div className="flex flex-col gap-5 md:items-center md:flex-row">
                  <div className="grow">
                    <h6 className="mb-1 text-16">#{data.potNumber}</h6>
                    <ul className="flex items-center gap-3">
                      <li className="text-slate-500">
                        تاریخ:{" "}
                        {moment(data.date, "YYYY-MM-DD").format(
                          "jYYYY/jMM/jDD"
                        )}
                      </li>
                      <li className="text-slate-500">
                        شماره قرارداد: {data.potNumber}
                      </li>
                    </ul>
                  </div>
                  <div className="flex items-center gap-2 shrink-0">
                    <button
                      type="button"
                      onClick={() =>
                        handleNavigationDesignContractInvoices(data.id)
                      }
                      className="text-white bg-purple-500 border-purple-500 btn hover:text-white hover:bg-purple-600 hover:border-purple-600 focus:text-white focus:bg-purple-600 focus:border-purple-600 focus:ring focus:ring-purple-100 active:text-white active:bg-purple-600 active:border-purple-600 active:ring active:ring-purple-100 dark:ring-purple-400/10"
                    >
                      فاکتورها
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        handleNavigationDesignContractFiles(data.id)
                      }
                      className="text-white bg-purple-500 border-purple-500 btn hover:text-white hover:bg-purple-600 hover:border-purple-600 focus:text-white focus:bg-purple-600 focus:border-purple-600 focus:ring focus:ring-purple-100 active:text-white active:bg-purple-600 active:border-purple-600 active:ring active:ring-purple-100 dark:ring-purple-400/10"
                    >
                      فایل‌ها
                    </button>
                    <button
                      onClick={() => window.print()}
                      type="button"
                      className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    >
                      <Save className="inline-block size-4 ltr:mr-1 rtl:ml-1" />{" "}
                      <span className="align-middle">Save & Print</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="!pt-0 card-body">
                <div className="p-5 border rounded-md md:p-8 border-slate-200 dark:border-zink-500 print:p-0">
                  <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                    <div className="text-center xl:col-span-2 ltr:xl:text-left rtl:xl:text-right">
                      <div className="flex items-center justify-center size-16 mx-auto rounded-md bg-slate-100 dark:bg-zink-600 xl:mx-0">
                        <img src={logoSm} alt="" className="h-8" />
                      </div>
                      <h5 className="mt-4 mb-1">چکادبام</h5>
                      <p className="text-slate-500 dark:text-zink-200">
                        طراحی و ساخت روف گاردن
                      </p>
                    </div>
                    <div className="ltr:xl:text-right rtl:xl:text-left xl:col-start-10 xl:col-span-3">
                      <p className="mb-1 text-slate-500 dark:text-zink-200">
                        Pot number:{" "}
                        <span className="font-semibold">{data.potNumber}</span>
                      </p>
                      <p className="mb-1 truncate text-slate-500 dark:text-zink-200">
                        Con Number:{" "}
                        <span className="font-semibold">{data.conNumber}</span>
                      </p>
                      <p className="mb-1 text-slate-500 dark:text-zink-200">
                        Project code:{" "}
                        <span className="font-semibold">
                          {data.projectCode}
                        </span>
                      </p>
                      <p className="text-slate-500 dark:text-zink-200">
                        date:{" "}
                        <span className="font-semibold">
                          {moment(data.date, "YYYY-MM-DD").format(
                            "jYYYY/jMM/jDD"
                          )}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 mt-6 text-center divide-y md:divide-y-0 md:divide-x rtl:divide-x-reverse divide-dashed md:grid-cols-4 divide-slate-200 dark:divide-zink-500">
                    <div className="p-3">
                      <h6 className="mb-1">{data.city}</h6>
                      <p className="text-slate-500 dark:text-zink-200">شهر</p>
                    </div>
                    <div className="p-3">
                      <h6 className="mb-1">{data.state}</h6>
                      <p className="text-slate-500 dark:text-zink-200">استان</p>
                    </div>
                    <div className="p-3">
                      <h6 className="mb-1">
                        {data.isApprovedByTechniacalDeputy ? (
                          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
                            تایید شده
                          </span>
                        ) : (
                          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent">
                            تایید نشده
                          </span>
                        )}
                      </h6>
                      <p className="text-slate-500 dark:text-zink-200">وضعیت</p>
                    </div>
                    <div className="p-3">
                      <h6 className="mb-1">{data.price}</h6>
                      <p className="text-slate-500 dark:text-zink-200">
                        هزینه کل
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-5 mt-8 md:grid-cols-2">
                    <div>
                      <p className="mb-2 text-sm uppercase text-slate-500 dark:text-zink-200">
                        آدرس پروژه
                      </p>
                      <h6 className="mb-1 text-15">{data.customerName}</h6>
                      <p className="mb-1 text-slate-500 dark:text-zink-200">
                        {data.projectAddress}
                      </p>
                      <p className="mb-1 text-slate-500 dark:text-zink-200">
                        {data.customerPhone}
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-1 px-4 py-3 text-sm border rounded-md md:items-center border-sky-200 text-sky-500 bg-sky-50 dark:bg-sky-400/20 dark:border-sky-500/50">
                    <p>
                      <span className="font-bold">یادداشت: </span>
                      {data.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
};

export default Overview;
