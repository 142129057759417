// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_ADMIN_MEMBERLIST } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface Member {
  fullName: string;
  id: number;
  isActive: boolean;
  role: string;
  mobile: string;
  city: string;
}

interface Members {
  members: Member[];
}

let val: Members;

const useFetchData = () => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api.get(GET_ADMIN_MEMBERLIST, null).then((response) => {
          setData(JSON.parse(JSON.stringify(response)));
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
