import React, { useCallback, useState } from "react";
import useFetchData from "./useFetchData";
import useFetchDataWorkList from "./useFetchDataWorkList";
import { Link, useNavigate } from "react-router-dom";
import { APIClient } from "helpers/api_helper";

import { DatePicker } from "zaman";
import styles from "./mystyle.module.css";
import { ToastContainer, toast } from "react-toastify";
import Modal from "Common/Components/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import { Plus } from "lucide-react";
import { right } from "@popperjs/core";
import Select from "react-select";
import {
  POST_DELETEWORK_TEAMHEAD,
  POST_PROJECTADDWORK_TEAMHEAD,
  POST_PROJECTADDCUSTUMWORK_TEAMHEAD,
} from "helpers/url_helper";

const api = new APIClient();

function getParameterByName(name: any, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const ColorsTable = () => {
  let x = 1;
  var reqWorkId = getParameterByName("reqWorkId");
  let { reqWorks, loading } = useFetchData(reqWorkId);
  let { reqWorksList, loading1 } = useFetchDataWorkList();
  // console.log(reqWorksList);
  const [show, setShow] = useState<boolean>(false);
  const [show2, setShow2] = useState<boolean>(false);

  //handleSelectChange
  const handleSelectChange = (value: any, action: any) => {
    validation.setFieldValue(action.name, value.value);
  };
  //select box modal
  interface Options {
    label: string;
    value?: string;
    isDisabled?: boolean;
    options?: Options[];
  }
  const DefaultOptions: Options[] = [];

  if (!loading1)
    reqWorksList.workActivities.map((work: any) => {
      const newOption: Options = {
        label: work.name,
        value: work.id,
      };
      DefaultOptions.push(newOption);
    });
  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      note: "",
      country: "",
    },
    validationSchema: Yup.object({
      note: Yup.string().required("کار را وارد کنید"),
      country: Yup.string().required("تعداد را وارد کنید"),
   
    }),

    onSubmit: async (values) => {
      try {
        const postDate = {
          workerActivitiesId: Number(values.note),
          reaquestWorksId: Number(reqWorkId),
          count: Number(values.country),
        };
        console.log(postDate);
        await api.create(POST_PROJECTADDWORK_TEAMHEAD, postDate);
        toast.info("Added to Server");
        // Reload the page to reflect changes
        window.location.reload();
      } catch (error) {
        toast.error("Error");
      }
      toggle();
    },
  });
  const validation2: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      price: 0,
      unit: "",
      requestWorkId: reqWorkId,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("شرح کار را وارد کنید"),
      price: Yup.string().required("قیمت را وارد کنید"),
    }),

    onSubmit: async (values) => {
      try {
        const postDate = {
          name: values.name,
          unit: "",
          requestWorkId: reqWorkId,
          price: Number(values.price),
        };
        console.log(postDate);
        await api.create(POST_PROJECTADDCUSTUMWORK_TEAMHEAD, postDate);
        toast.info("Added to Server");
        // Reload the page to reflect changes
        window.location.reload();
      } catch (error) {
        toast.error("Error");
      }
      toggle();
    },
  });
  const handleDelete = async (projectId: any) => {
    try {
      const postDate = {
        workerActivitiesId: projectId,
        reaquestWorksId: Number(reqWorkId),
      };
      console.log(postDate);
      await api.create(POST_DELETEWORK_TEAMHEAD, postDate);
      toast.info("Added to Server");
      // Reload the page to reflect changes
      window.location.reload();
    } catch (error) {
      toast.error("Error");
    }
  };
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);

      validation.resetForm();
    }
  }, [show, validation]);
  const toggle2 = useCallback(() => {
    if (show2) {
      setShow2(false);
    } else {
      setShow2(true);

      validation2.resetForm();
    }
  }, [show2, validation2]);

  const history = useNavigate();

  const handleNavigation = (id: any) => {
    history(`/TeamHead/ReqWork/Worklist?reqWorkId=${id}`);
  };

  if (loading) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <div className="overflow-x-auto">
            <h6 className="mb-12 text-15">اضافه کردن فعالیت به جبهه کاری</h6>
            <button
              type="button"
              className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              onClick={toggle}
            >
              <Plus className="inline-block size-3" />{" "}
              <span className="align-middle">اضافه کردن فعالیت</span>
            </button>
            <button
              type="button"
              className="m-3 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              onClick={toggle2}
            >
              <Plus className="inline-block size-3" />{" "}
              <span className="align-middle">
                اضافه کردن فعالیت خارج از لیست
              </span>
            </button>
          </div>{" "}
        </div>
        <div className="">
          <div className="overflow-x-auto">
            <table className="w-full bg-custom-50 dark:bg-custom-500/10">
              <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                <tr>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    ردیف
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    نام محصول
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    متراژ یا تعداد
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    توضیحات
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    واحد
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    عملیات
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr> */}
                {!loading &&
                  reqWorks.worksOfReqWorks.map((project, index) => (
                    <tr key={project.id + index}>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <a
                          href="#!" // Use downloadLink if available, otherwise fallback to "#"
                          className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                        >
                          {x++}
                        </a>
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.name || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.count || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.description || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.unit || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "red",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                          }}
                          onClick={() => handleDelete(project.id)}
                          className="btn btn-primary"
                        >
                          حذف
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* work Modal */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
        >
          <Modal.Title className="text-16"> اضافه کردن کار</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <Select
                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                data-choices
                name="note"
                id="note"
                options={DefaultOptions}
                value={
                  DefaultOptions.find(
                    (option) => option.value === validation.values.note
                  ) || null
                } // Provide a default value if find() returns undefined
                onChange={handleSelectChange}
                onBlur={() => validation.setFieldTouched("note", true)}
                // Additional props required by the Select component
              />
              {validation.touched.note && validation.errors.note ? (
                <p className="text-red-400">{validation.errors.note}</p>
              ) : null}
            </div>
            <div>
              <label
                htmlFor="countryInput"
                className="inline-block mb-2 text-base font-medium"
              >
                تعداد یا متراژ
              </label>
              <input
                type="text"
                name="country"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder=" تعداد یا متراژ"
                value={validation.values.country}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              {validation.touched.country && validation.errors.country ? (
                <div className="text-red-500">{validation.errors.country}</div>
              ) : null}
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/*  CUSTUM work Modal */}
      <Modal
        show={show2}
        onHide={toggle2}
        id="defaultModa2"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {" "}
            اضافه کردن فعالیت متفرقه
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation2.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <label
                htmlFor="name"
                className="inline-block mb-2 text-base font-medium"
              >
                شرح فعالیت
              </label>
              <input
                type="text"
                name="name"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder=" شرح فعالیت  "
                value={validation2.values.name}
                onChange={validation2.handleChange}
                onBlur={validation2.handleBlur}
                required
              />
              {validation.touched.name && validation.errors.name ? (
                <div className="text-red-500">{validation2.errors.name}</div>
              ) : null}
            </div>
            <div>
              <label
                htmlFor="countryInput"
                className="inline-block mb-2 text-base font-medium"
              >
                قیمت
              </label>
              <input
                type="text"
                name="price"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="  قیمت تومان "
                value={validation2.values.price}
                onChange={validation2.handleChange}
                onBlur={validation2.handleBlur}
              />
              {validation2.touched.price && validation2.errors.price ? (
                <div className="text-red-500">{validation2.errors.price}</div>
              ) : null}
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle2}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer theme="colored" />
    </React.Fragment>
  );
};

export default ColorsTable;
