import {
  CalendarDays,
  Codesandbox,
  MonitorDot,
  PictureInPicture2,
  UserRound,
} from "lucide-react";

const menuDataWorker: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "dashboard",
    label: "Dashboards",
    link: "/worker/dashboard",
    icon: <MonitorDot />,
  },
  {
    id: "Accounting",
    label: "Accounting",
    icon: <UserRound />,
    parentId: 2,
    subItems: [
      {
        id: "Accounting",
        label: "فاکتورها",
        link: "/worker/accounting",
        parentId: "users",
      },
    ],
  },
  {
    id: "Works",
    label: "کارها",
    icon: <UserRound />,
    parentId: 2,
    subItems: [
      {
        id: "جبهه کاری",
        label: "جبهه کاری",
        link: "/worker/works",
        parentId: "Works",
      },
    ],
  },
  {
    label: "Account",
    isTitle: true,
  },
  {
    id: "users",
    label: "تنظیمات اکانت",
    icon: <UserRound />,
    parentId: 2,
    subItems: [
      {
        id: "users",
        label: "اکانت",
        link: "/worker/user-profile",
        parentId: "users",
      },
      // {
      //   id: "users",
      //   label: "تغییر پسورد",
      //   link: "/worker/user-profile",
      //   parentId: "users",
      // },
    ],
  },
  {
    id: "tickets",
    label: "Tickets",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "submitTicket",
        label: "Submit Ticket",
        link: "/worker/UnderConstruction",
        parentId: "landing",
      },
      {
        id: "ticketList",
        label: "Ticket List",
        link: "/worker/UnderConstruction",
        parentId: "landing",
      },
    ],
  },
  {
    label: "Pages",
    isTitle: true,
  },

  {
    id: "pages",
    label: "Pages",
    icon: <Codesandbox />,
    parentId: 2,
    subItems: [
      {
        id: "faq",
        label: "FAQs",
        link: "/worker/pages-faqs",
        parentId: "pages",
      },
      {
        id: "contactus",
        label: "Contact US",
        link: "/worker/pages-contact-us",
        parentId: "pages",
      },
    ],
  },
];

export { menuDataWorker };
