// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_INVOICEDETAILWORKER_ACCOUNTANT } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface WorkerInvoice {
  projectAddress: string;
  teamName: string;
  date: string;
  managerName: string;
  amount: number;
  hourlyPrice: number;
  description: string;
  fullName: string;
  mobile: string;
  id: number;
  paidStatus: number;
  approved: boolean;
  works: Works[];
}

interface Works {
  name: string;
  quantity: number;
  wholePrice: number;
}

let val: WorkerInvoice;

const useFetchData = (id: any) => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_INVOICEDETAILWORKER_ACCOUNTANT, { invoiceId: id })
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
