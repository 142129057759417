// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_TEAM_NAME_TECHNICALDEPUTY } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface Team {
  id: string;
  fullName: number;
}

interface Teams {
  teams: Team[];
}

let val: Teams;

const useFetchTeams = () => {
  const [teams, setData] = useState(val);
  const [loadingTeams, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api.get(GET_TEAM_NAME_TECHNICALDEPUTY, null).then((response) => {
          setData(JSON.parse(JSON.stringify(response)));
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return {
    teams,
    loadingTeams,
  };
};

export default useFetchTeams;
