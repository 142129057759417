// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_ESTIMATEOFPROJECT_TECHINICALDEPUTY } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface Estimate {
  id: number;
  name: string;
  quantity: number;
  wholePrice: number;
}

interface Estimates {
  estimates: Estimate[];
}

let val: Estimates;

const useFetchData = (id: any) => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_ESTIMATEOFPROJECT_TECHINICALDEPUTY, { projectId: id })
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
