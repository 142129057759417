import React from "react";
import useFetchData from "./FetchData";
import { withTranslation } from "react-i18next";
//شمسی
import moment from "moment-jalaali";
import { Link } from "react-router-dom";

const ColorsTable = (props:any) => {
  let x = 1;
  let { data, loading } = useFetchData();
  console.log(data);
  if (loading) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-12 text-15">{props.t("Pojects Table")}</h6>

          <div className="overflow-x-auto">
            <table className="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap">
              <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                <tr>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    {props.t("row")}
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    {props.t("Project Type")}
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    {props.t("Team Name")}
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    {props.t("Project size")}
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    {props.t("Address")}
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    {props.t("Project code")}
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    {props.t("Create Date")}
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    {props.t("Action")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr> */}
                {!loading &&
                  data.projects.map((project) => (
                    <tr key={project.id}>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <a
                          href="#!" // Use downloadLink if available, otherwise fallback to "#"
                          className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                        >
                          {x++}
                        </a>
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.buildingType || "-"}{" "}
                        {/* Display "-" if shop is missing */}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.teamName || "-"}{" "}
                        {/* Display "-" if shop is missing */}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.areaSize || "-"}{" "}
                        {/* Display "-" if customer is missing */}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.address || "-"}{" "}
                        {/* Display "-" if price is missing */}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.projectCode || "-"}{" "}
                        {/* Display "-" if price is missing */}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.createdDate
                          ? moment(project.createdDate, "YYYY-MM-DD").format(
                              "jYYYY/jMM/jDD"
                            )
                          : "-"}{" "}
                        {/* {  project.createdDate ? moment(project.createdDate).format('jYYYY/jMM/jDD') : "-"} */}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <Link
                          to={`/customers/project/projectprocess?id=${project.id}`}
                          className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                        >
                          <i className="ri-download-2-line"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation() (ColorsTable);
