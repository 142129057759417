// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_WORKER_WORKS } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface WorkerWork {
  address: string;
  invoiceId: number;
  teamName: string;
  date: string;
  managerName: string;
  id: number;
}

interface WorkerWorks {
  requestWork: WorkerWork[];
}

let val: WorkerWorks;

const useFetchData = () => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api.get(GET_WORKER_WORKS, null).then((response) => {
          setData(JSON.parse(JSON.stringify(response)));
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
