import React from "react";
import useFetchData from "./FetchData";
import moment from "moment-jalaali";
import { APIClient } from "helpers/api_helper";
import { GET_WORKER_APPROVEPINVOICE } from "helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const api = new APIClient();

const TableWorkerInvoice = () => {
  let x = 1;
  let { data, loading } = useFetchData();

  const handleَApproveInvoice = (id: any) => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_WORKER_APPROVEPINVOICE, { invoiceId: id })
          .then(() => {
            window.location.reload();
          });
      } catch (error) {
        console.error(error);
        toast.error("Error!");
      }
    };
    fetchData();
  };
  const history = useNavigate();

  const handleَNavigateInvoiceDetail = (id: any) => {
    history(`/worker/accounting/detail?invoiceId=${id}`);
  };

  if (loading) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-12 text-15">لیست فاکتورها</h6>

          <div className="overflow-x-auto">
            <table className="w-full bg-custom-50 dark:bg-custom-500/10">
              <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                <tr>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    ردیف
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    تاریخ
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    نام تیم
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    نام مدیر اجرا
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    مبلغ
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    وضعیت
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    جمع قیمت ساعتی ریال
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    عملیات
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr> */}
                {!loading &&
                  data.wokerInvoices.map((workerInvoice) => (
                    <tr key={workerInvoice.id}>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <a
                          href="#!" // Use downloadLink if available, otherwise fallback to "#"
                          className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                        >
                          {x++}
                        </a>
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {workerInvoice && workerInvoice.date
                          ? moment(workerInvoice.date, "YYYY-MM-DD").format(
                              "jYYYY/jMM/jDD"
                            )
                          : "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {workerInvoice.teamName || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {workerInvoice.managerName || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {workerInvoice.amount.toFixed(1) || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {workerInvoice.approved ? (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-custom-500 border-custom-500 text-custom-50">
                            تایید شده
                          </span>
                        ) : (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-red-500 border-red-500 text-red-50">
                            تایید نشده
                          </span>
                        )}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {workerInvoice.hourlyPrice || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {!workerInvoice.approved ? (
                          <button
                            style={{
                              display: "inline-block",
                              backgroundColor: "green",
                              color: "white",
                              padding: "10px 20px 10px",
                              border: "none",
                              marginRight: "3px",
                              marginTop: "3px",
                            }}
                            onClick={() =>
                              handleَApproveInvoice(workerInvoice.id)
                            }
                            className="btn btn-primary"
                          >
                            تایید
                          </button>
                        ) : (
                          ""
                        )}
                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "blue",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                            marginRight: "3px",
                            marginTop: "3px",
                          }}
                          onClick={() =>
                            handleَNavigateInvoiceDetail(workerInvoice.id)
                          }
                          className="btn btn-primary"
                        >
                          جزییات
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" />
    </React.Fragment>
  );
};

export default TableWorkerInvoice;
