import React from "react";
import BreadCrumb from "Common/BreadCrumb";
import TableMemberRequestWork from "./TableMemberRequestWork";

const BasicTable = () => {
  return (
    <React.Fragment>
      <div className="container-fluid group-data-[content=boxed]:max-w-boxed mx-auto">
        <BreadCrumb title="Basic Table" pageTitle="Tables" />

        <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-1">
          <TableMemberRequestWork />
        </div>
      </div>
    </React.Fragment>
  );
};

export default BasicTable;
