// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_WORKOFREQUESTWORK_MANAGER } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface WorksOfReqWork {
  name: string;
  unit: string;
  description: string;
  height: number;
  width: number;
  length: number;
  count: number;
  isDone: boolean;
  id: number;
}

interface WorksOfReqWorks {
  worksOfReqWorks: WorksOfReqWork[];
}

let val: WorksOfReqWorks;

const useFetchData = (id: any) => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_WORKOFREQUESTWORK_MANAGER, { reqWorkId: id })
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
