import React, { useEffect, useState } from "react";
import { Package, PackageX, Truck, Wallet2 } from "lucide-react";
import CountUp from "react-countup";
import { GET_ACCOUNTANTDASHBOARD } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";
import ClockLoader from "react-spinners/ClipLoader";

const api = new APIClient();

const Widgets = () => {
  const [data, setData] = useState({
    projectNo: 0,
    notificationNo: 0,
    testicketNo: 0,
    invoiceNo: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(GET_ACCOUNTANTDASHBOARD, null);
        setData(JSON.parse(JSON.stringify(response)));
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  if (!isLoading)
    return (
      <React.Fragment>
        <div className="col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-3">
          <div className="text-center card-body">
            <div className="flex items-center justify-center mx-auto rounded-full size-14 bg-custom-100 text-custom-500 dark:bg-custom-500/20">
              <Wallet2 />
            </div>
            <h5 className="mt-4 mb-2">
              <CountUp end={data.notificationNo} className="counter-value" />
            </h5>
            <p className="text-slate-500 dark:text-zink-200">اعلان ها</p>
          </div>
        </div>
        <div className="col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-3">
          <div className="text-center card-body">
            <div className="flex items-center justify-center mx-auto text-purple-500 bg-purple-100 rounded-full size-14 dark:bg-purple-500/20">
              <Package />
            </div>
            <h5 className="mt-4 mb-2">
              <CountUp end={data.invoiceNo} className="counter-value" />
            </h5>
            <p className="text-slate-500 dark:text-zink-200">فاکتورها</p>
          </div>
        </div>
        <div className="col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-3">
          <div className="text-center card-body">
            <div className="flex items-center justify-center mx-auto text-green-500 bg-green-100 rounded-full size-14 dark:bg-green-500/20">
              <Truck />
            </div>
            <h5 className="mt-4 mb-2">
              <CountUp end={data.projectNo} className="counter-value" />
            </h5>
            <p className="text-slate-500 dark:text-zink-200">پروژه ها</p>
          </div>
        </div>
        <div className="col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-3">
          <div className="text-center card-body">
            <div className="flex items-center justify-center mx-auto text-red-500 bg-red-100 rounded-full size-14 dark:bg-red-500/20">
              <PackageX />
            </div>
            <h5 className="mt-4 mb-2">
              <CountUp end={data.testicketNo} className="counter-value" />
            </h5>
            <p className="text-slate-500 dark:text-zink-200">تیکت ها</p>
          </div>
        </div>
      </React.Fragment>
    );
  else return <ClockLoader color="#36d7b7" loading={isLoading} size={150} />;
};

export default Widgets;
