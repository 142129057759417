import React, { useCallback, useEffect, useState } from "react";
import { MoveRight, Gem, Smartphone, Globe } from "lucide-react";
import { Field, useFormik,useField, useFormikContext, FormikHelpers } from "formik";
import Select from "react-select";
import { APIClient } from "helpers/api_helper";
import styles from "./mystyle.module.css";
import * as Yup from "yup";
import useFetchData from "./FetchData";
import { POST_PROJECT_TEAMHEAD } from "helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "zaman";
//شمسی
import moment from "moment-jalaali";
import Dropzone from "react-dropzone";
import { UploadCloud } from "lucide-react";
import axios from "axios";

const api = new APIClient();

interface Options {
  label: string;
  value?: string;
  isDisabled?: boolean;
  options?: Options[];
}

const FormOrder = () => {
  const DefaultOptions: Options[] = [];
  const DefaultOptions1: Options[] = [
    { value: "در حال ساخت", label: "در حال ساخت " },
    { value: "اتمام پروژه", label: "اتمام پروژه" },
  ];

  let { data, loading } = useFetchData();

  if (!loading)
    data.members.map((customer: any) => {
      const newOption: Options = {
        label: customer.fullName,
        value: customer.id,
      };
      DefaultOptions.push(newOption);
    });

  const validation: any = useFormik({
        initialValues: {
          CustomerId: null,
          City: "",
          State: "",
          Address: "",
          NumberOfFloor: 0,
          ProjectType: "روف گاردن",
          AreaSize: 0,
          BuildingType: "تجاری",
          CallTime: "",
          FirstVisit: "",
          ConstructionStage: "",
          CargoElevator: false,
          EmployerPosition: "",
          Representative: 0,
          Files: [],
        },
        validationSchema: Yup.object().shape({
          CustomerId: Yup.number().required("First Name is required"),
          City: Yup.string().required("City is required."),
          State: Yup.string().required("State  is required."),
          Address: Yup.string().required("Address is required."),
          NumberOfFloor: Yup.number().required("Number of floor is required."),
          ProjectType: Yup.string().required("project Type is required."),
          // Representative: Yup.number().required("Representive is required."),
          AreaSize: Yup.number().required("Area Size is required."),
          BuildingType: Yup.string().required("Building Type is required."),
          // EmployerPosition: Yup.string().required("EmployerPosition is required."),
          CallTime: Yup.date().required("تاریخ تماس الزامی است"),

          // FirstVisit: Yup.date().required("تاریخ بازدید الزامی است"),
          // Files:  Yup.array().of(
          //   Yup.mixed().test("fileType", "Unsupported file format", (value:any) =>
          //     ["image/jpeg","image/jpg", "image/png", "image/gif"].includes(value?.type)
          //   )
          // )
        }),
    
    // onSubmit: async (values: any) => {
    //   try {
    //     console.log(values.Files);
    //     await api.create(POST_PROJECT_TEAMHEAD, values);
    //     toast.info("Added to Server");
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //     toast.error("Error");
    //   }
    // },
    onSubmit: async (values: any) => {
      try {
            const body = new FormData();

            body.append("CustomerId", values.CustomerId);
            body.append("City", values.City);
            body.append("State", values.State);
            body.append("Address", values.Address);
            body.append("NumberOfFloor", values.NumberOfFloor);
            body.append("ProjectType", values.ProjectType);
            body.append("AreaSize", values.AreaSize);
            body.append("BuildingType", values.BuildingType);
            body.append("CallTime", values.CallTime);
            body.append("FirstVisit", values.FirstVisit);
            body.append("ConstructionStage", values.ConstructionStage);
            body.append("CargoElevator", values.CargoElevator.toString());
            body.append("EmployerPosition", values.EmployerPosition);
            body.append("Representative", values.Representative);     
            // body.append("Files", values.Files);     

            for (const file of values.Files) {
              body.append(`Files`, file);
            }
            // values.Files.forEach((file: File, index: number) => {
            //   body.append(`Files[${index}]`, file);
            // });   
        
                console.log(body);
                await axios.post(POST_PROJECT_TEAMHEAD, body, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                      "Accept": "application/json",
                       
                  },
                });
                toast.info("Added to Server");
      } 
      catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error");
      }
    },
  });

  const handleSelectChange = (value: any, action: any) => {
    validation.setFieldValue(action.name, value.value);
  };
  
  //file upload
 
  // const [selectedBorderFiles, setSelectedBorderFiles] = React.useState<any>([]);

  // const handleAcceptedBorderFiles = (Files: any) => {
  //   Files.map((file: any) =>
  //     Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //       formattedSize: formatBytes(file.size),
  //     })
  //   );
  //   setSelectedBorderFiles(Files);
  // };

  // const handleAcceptedBorderFiles = (acceptedFiles: any) => {
  //   const mappedFiles = acceptedFiles.map((file: any) =>
  //     Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //       formattedSize: formatBytes(file.size),
  //     })
  //   );

  //   // setSelectedBorderFiles([...selectedBorderFiles, ...mappedFiles]);

  //   const filesValue = validation.values.Files || []; // ensure filesValue is an array
  //   validation.setFieldValue("Files", [...filesValue, ...mappedFiles]);
  // };

  /**
   * Formats the size
   */
  // const formatBytes = (bytes: any, decimals = 2) => {
  //   if (bytes === 0) return "0 Bytes";
  //   const k = 1024;
  //   const dm = decimals < 0 ? 0 : decimals;
  //   const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  //   const i = Math.floor(Math.log(bytes) / Math.log(k));
  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  // };

  return (
    <React.Fragment>
      <h5 className="mb-3">ایجاد پروژه</h5>
      <form
        action="#!"
        onSubmit={(event: any) => {
          event.preventDefault();
          validation.handleSubmit({
            
            
          });
           
        }}
      >
        <label
          htmlFor="ProjectType"
          className="inline-block mb-3 text-base font-medium"
        >
          انتخاب نوع پروژه <span className="text-red-500">*</span>
        </label>
        <div
          id="ProjectTypegroup"
          className="grid grid-cols-1 gap-3 mb-4 md:grid-cols-3 lg:grid-cols-3"
        >
          <div>
            <input
              id="brandingRadio"
              name="ProjectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              value="روف گاردن"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              defaultChecked
            />
            <label
              htmlFor="brandingRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Gem className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">روف گاردن</span>
            </label>
          </div>
          <div>
            <input
              id="mobileDesignRadio2"
              name="ProjectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="دیوار سبز"
            />
            <label
              htmlFor="mobileDesignRadio2"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Smartphone className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">دیوار سبز</span>
            </label>
          </div>

          <div>
            <input
              id="webDesignRadio3"
              name="ProjectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="تراس سبز "
            />
            <label
              htmlFor="webDesignRadio3"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">تراس سبز</span>
            </label>
          </div>
          <div>
            <input
              id="webDesignRadio4"
              name="ProjectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="محوطه سازی"
            />
            <label
              htmlFor="webDesignRadio4"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">محوطه سازی </span>
            </label>
          </div>
          <div>
            <input
              id="webDesignRadio5"
              name="ProjectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
             value="فروش اقلام "
            />
            <label
              htmlFor="webDesignRadio5"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">فروش اقلام </span>
            </label>
          </div>
          <div>
            <input
              id="webDesignRadio6"
              name="ProjectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="کاشت گل و گیاه "
            />
            <label
              htmlFor="webDesignRadio6"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">کاشت گل و گیاه </span>
            </label>
          </div>
        </div>

        <label
          htmlFor="BuildingType"
          className="inline-block mb-3 text-base font-medium"
        >
          نوع کاربری پروژه <span className="text-red-500">*</span>
        </label>
        <div
          id="buildingTypegroup"
          className="grid grid-cols-1 gap-3 mb-4 md:grid-cols-3 lg:grid-cols-3"
        >
          <div>
            <input
              id="businessRadio"
              name="BuildingType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              value="تجاری"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              defaultChecked
            />
            <label
              htmlFor="businessRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Gem className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">تجاری</span>
            </label>
          </div>

          <div>
            <input
              id="officeRadio"
              name="BuildingType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="اداری"
            />
            <label
              htmlFor="officeRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Smartphone className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">اداری</span>
            </label>
          </div>

          <div>
            <input
              id="residentialRadio"
              name="BuildingType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="مسکونی"
            />
            <label
              htmlFor="residentialRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">مسکونی</span>
            </label>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="nameInput"
              className="inline-block mb-2 text-base font-medium"
            >
              نام مشتری <span className="text-red-500">*</span>
            </label>
            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              data-choices
              name="CustomerId"
              options={DefaultOptions}
              value={DefaultOptions.find(
                (option) => option.value === validation.values.customerId
              )}
              onChange={handleSelectChange}
              onBlur={() => validation.setFieldTouched("CustomerId", true)}
            />
            {validation.touched.CustomerId && validation.errors.CustomerId ? (
              <div className="text-red-500">{validation.errors.CustomerId}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="City"
              className="inline-block mb-2 text-base font-medium"
            >
              شهر <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="City"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="شهر"
              value={validation.values.City}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.City && validation.errors.City ? (
              <div id="City" className="text-red-500">
                {validation.errors.City}
              </div>
            ) : null}
          </div>{" "}
          <div>
            <label
              htmlFor="State"
              className="inline-block mb-2 text-base font-medium"
            >
              استان <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="State"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="استان"
              value={validation.values.State}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.State && validation.errors.State ? (
              <div className="text-red-500">{validation.errors.State}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="phoneNumberInput"
              className="inline-block mb-2 text-base font-medium"
            >
              آدرس <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="Address"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="آدرس "
              value={validation.values.Address}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.Address && validation.errors.Address ? (
              <div className="text-red-500">{validation.errors.Address}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="numberOfFloorInput"
              className="inline-block mb-2 text-base font-medium"
            >
              شماره طبقه
            </label>
            <input
              type="number"
              name="NumberOfFloor"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder=" شماره طبقه"
              value={validation.values.NumberOfFloor}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.NumberOfFloor &&
            validation.errors.NumberOfFloor ? (
              <div className="text-red-500">
                {validation.errors.NumberOfFloor}
              </div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="areaSizeInput"
              className="inline-block mb-2 text-base font-medium"
            >
              متراژ پروژه
            </label>
            <input
              type="number"
              name="AreaSize"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder=" متراژ پروژه"
              value={validation.values.AreaSize}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.AreaSize && validation.errors.AreaSize ? (
              <div className="text-red-500">{validation.errors.AreaSize}</div>
            ) : null}
          </div>
          <div className="mb-6 col-md-4">
            <label
              htmlFor="CallTime"
              className="inline-block mb-2 text-base font-medium"
            >
              تاریخ تماس
            </label>

            <DatePicker
              className={styles.bigblue}
              round="x4"
              position="center"
              onChange={(newDate) => {
                // console.log(newDate);
                // Ensure the date is always formatted as a string before updating the state
                let dateString = "";
                if (newDate && newDate.value) {
                  // Assuming newDate.value is a date string or a timestamp
                  dateString = new Date(newDate.value)
                    .toISOString()
                    .split("T")[0];
                }
                validation.setFieldValue("CallTime", dateString);
              }}
            />
            {validation.touched.CallTime && validation.errors.CallTime ? (
              <div className="text-red-500">{validation.errors.CallTime}</div>
            ) : null}
          </div>
          <div className="mb-6 col-md-4">
            <label
              htmlFor="FirstVisit"
              className="inline-block mb-2 text-base font-medium"
            >
              بازدید اولیه
            </label>
            <DatePicker
              className={styles.bigblue}
              round="x4"
              position="center"
              onChange={(newDate) => {
                // console.log(newDate);
                // Ensure the date is always formatted as a string before updating the state
                let dateString = "";
                if (newDate && newDate.value) {
                  // Assuming newDate.value is a date string or a timestamp
                  dateString = new Date(newDate.value)
                    .toISOString()
                    .split("T")[0];
                }
                validation.setFieldValue("FirstVisit", dateString);
              }}
            />{" "}
            {validation.touched.FirstVisit && validation.errors.FirstVisit ? (
              <div className="text-red-500">{validation.errors.FirstVisit}</div>
            ) : null}
          </div>
          <div className="grid grid-cols-1 gap-3 mb-4 md:grid-cols-3 lg:grid-cols-3">
            <div className="mb-6 col-md-4">
              <label
                htmlFor="dateInput"
                className="inline-block mb-2 text-base font-medium"
              >
                مرحله ساخت
              </label>
              <Select
                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                data-choices
                name="ConstructionStage"
                id="ConstructionStage"
                options={DefaultOptions1}
                value={
                  DefaultOptions1.find(
                    (option) =>
                      option.value === validation.values.ConstructionStage
                  ) || null
                } // Provide a default value if find() returns undefined
                onChange={handleSelectChange}
                onBlur={() =>
                  validation.setFieldTouched("ConstructionStage", true)
                }
                // Additional props required by the Select component
              />
              {validation.touched.ConstructionStage &&
              validation.errors.ConstructionStage ? (
                <p className="text-red-400">
                  {validation.errors.ConstructionStage}
                </p>
              ) : null}
            </div>
            <div>
              <label
                htmlFor="phoneNumberInput"
                className="inline-block mb-2 text-base font-medium"
              >
                سمت کارفرما <span className="text-red-500"></span>
              </label>
              <input
                type="text"
                name="EmployerPosition"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="سمت کارفرما "
                value={validation.values.EmployerPosition}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              {validation.touched.EmployerPosition &&
              validation.errors.EmployerPosition ? (
                <div className="text-red-500">
                  {validation.errors.EmployerPosition}
                </div>
              ) : null}
            </div>
            <div>
              <label
                htmlFor="CargoElevator"
                className="inline-block mb-2 text-base font-medium"
              >
                آسانسور باربری<span className="text-red-500"></span>
              </label>
              <input
                type="checkbox"
                name="CargoElevator"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="آسانسور باربری"
                value={validation.values.CargoElevator}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              {validation.touched.CargoElevator &&
              validation.errors.CargoElevator ? (
                <div className="text-red-500">
                  {validation.errors.CargoElevator}
                </div>
              ) : null}
            </div>
            <div className="mb-6 col-md-4">
              <label
                htmlFor="Representative"
                className="inline-block mb-2 text-base font-medium"
              >
                معرف<span className="text-red-500"></span>
              </label>
              <Select
                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                data-choices
                name="Representative"
                options={DefaultOptions}
                value={DefaultOptions.find(
                  (option) => option.value === validation.values.customerId
                )}
                onChange={handleSelectChange}
                onBlur={() =>
                  validation.setFieldTouched("Representative", true)
                }
              />
              {validation.touched.Representative &&
              validation.errors.Representative ? (
                <div className="text-red-500">
                  {validation.errors.Representative}
                </div>
              ) : null}
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h6 className="mb-4 text-15">آپلود عکس های پروژه</h6>
              <div className="flex items-center justify-center bg-white border border-dashed rounded-md cursor-pointer dropzone border-slate-300 dropzone2 dark:bg-zink-700 dark:border-zink-500">
                {/* <Dropzone
                  onDrop={(acceptedFiles: any) => {
                    handleAcceptedBorderFiles(acceptedFiles);
                    // handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }: any) => (
                    <div
                      className="w-full py-5 text-lg text-center dz-message needsclick"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500"></UploadCloud>
                      </div>

                      <h5 className="mb-0 font-normal text-slate-500 text-15">
                        Drag and drop your files or <a href="#!">browse</a> your
                        files
                      </h5>
                    </div>
                  )}
                </Dropzone>
                {validation.touched.Files && validation.errors.Files ? (
                  <p className="text-red-400">{validation.errors.Files}</p>
                ) : null}
              </div>

              <ul
                className="flex flex-wrap mb-0 gap-x-5"
                id="dropzone-preview2"
              >
                {(selectedBorderFiles || [])?.map((f: any, i: any) => {
                  return (
                    <li
                      className="mt-2"
                      id="dropzone-preview-list"
                      key={i + "-file"}
                    >
                      <div className="border rounded border-slate-200 dark:border-zink-500">
                        <div className="p-2 text-center">
                          <div>
                            <div className="p-2 mx-auto rounded-md size-14 bg-slate-100 dark:bg-zink-600">
                              <img
                                data-dz-thumbnail
                                className="block w-full h-full rounded-md"
                                src={f.preview}
                                alt={f.name}
                              />
                            </div>
                          </div>
                          <div className="pt-3">
                            <h5 className="mb-1 text-15" data-dz-name>
                              {f.name}
                            </h5>
                            <p
                              className="mb-0 text-slate-500 dark:text-zink-200"
                              data-dz-size
                            >
                              {f.formattedSize}
                            </p>
                          </div>
                          <div className="mt-2">
                            <button
                              data-dz-remove
                              className="px-2 py-1.5 text-xs text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20"
                              onClick={() => {
                                const newImages = [...selectedBorderFiles];
                                newImages.splice(i, 1);
                                setSelectedBorderFiles(newImages);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul> */} 
              
           
            <input
              type="file"
              id="Files"
              name="Files"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              multiple
              accept='image/*'
             
              onChange={(event) => {
                validation.setFieldValue('Files', event.currentTarget.files);
                }}
              onBlur={validation.handleBlur}
            />
            
            {validation.touched.Files && validation.errors.Files ? (
              <div id="Files" className="text-red-500">
                {validation.errors.Files}
              </div>
            ) : null}
            </div></div>
          </div>
        </div>

        <div className="flex justify-between gap-2 mt-5">
          <button
            type="submit"
            data-action="next"
            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
          >
            <span className="align-middle">ثبت پروژه</span>{" "}
            <MoveRight className="inline-block h-4 ml-1 rtl:rotate-180" />
          </button>
        </div>
      </form>
      <ToastContainer theme="colored" />
    </React.Fragment>
  );
};

export default FormOrder;
