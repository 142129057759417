// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_REQUESTOFPROJECT_TECHINICALDEPUTY } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface ReqWork {
  projectManagerName: string;
  projectCode: string;
  date: string;
  approvedByTechnicalDeputy: Boolean;
  approvedByTeamHead: Boolean;
  wholeAmount: number;
  finished: Boolean;
  customerName: string;
  note: string;
  id: number;
}

interface ReqWorkS {
  reqWorks: ReqWork[];
}

let val: ReqWorkS;

const useFetchData = (id: any) => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_REQUESTOFPROJECT_TECHINICALDEPUTY, { projectId: id })
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
