// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_MANAGER_PROJECTLIST } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface Projects {
  address: string;
  city: string;
  createdDate: string;
  id: number;
  projectCode: string;
  customerName: string;
  isApproved: boolean;
  isFinished: boolean;
}

interface MyData {
  projects: Projects[];
}

let val: MyData;

const useFetchData = () => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api.get(GET_MANAGER_PROJECTLIST, null).then((response) => {
          setData(JSON.parse(JSON.stringify(response)));
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
