import {
  CalendarDays,
  Codesandbox,
  MonitorDot,
  PictureInPicture2,
  UserRound,
} from "lucide-react";

const menuDataManger: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "dashboard",
    label: "Dashboards",
    link: "/Manager/dashboard",
    icon: <MonitorDot />,
  },
  {
    id: "dashboard",
    label: "پروژه ",
    link: "/Manager/project/list",
    icon: <MonitorDot />,
  },
  {
    id: "dashboard",
    label: "درخواست های کار",
    link: "/Manager/reqwork/list",
    icon: <MonitorDot />,
  },
  {
    id: "tickets",
    label: "Tickets",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "submitTicket",
        label: "Submit Ticket",
        link: "/Manager/tickets/submitticket",
        parentId: "landing",
      },
      {
        id: "ticketList",
        label: "Ticket List",
        link: "/Manager/tickets/ticketlist",
        parentId: "landing",
      },
    ],
  },
  {
    label: "Account",
    isTitle: true,
  },
  {
    id: "users",
    label:"تنظیمات اکانت",
    icon: <UserRound />,
    parentId: 2,
    subItems: [
      {
        id: "تنظیمات اکانت",
        label: "اکانت",
        link: "/user-profile",
        parentId: "users",
      },
      // {
      //   id: "usergridview",
      //   label: "Grid View",
      //   link: "/apps-users-grid",
      //   parentId: "users",
      // },
    ],
  },
  {
    label: "Pages",
    isTitle: true,
  },

  {
    id: "pages",
    label: "Pages",
    icon: <Codesandbox />,
    parentId: 2,
    subItems: [
      {
        id: "faq",
        label: "FAQs",
        link: "/pages-faqs",
        parentId: "pages",
      },
      {
        id: "contactus",
        label: "Contact US",
        link: "/pages-contact-us",
        parentId: "pages",
      },
    ],
  },
];

export { menuDataManger };
