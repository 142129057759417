import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { APIClient } from "helpers/api_helper";
import {
  POST_UPDATEWORKACTIVITY_TECHNICALDEPUTY,
  getParameterByName,
} from "helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FetchData from "./FetchData";

const api = new APIClient();

const PersonalTabs = (props: any) => {
  var workActivityId = getParameterByName("workActivityId");

  let { data, loading } = FetchData(workActivityId);

  const [initialValues, setInitialValues] = useState({
    name: "",
    unit: "",
    price: 0,
    description: "",
  });

  useEffect(() => {
    if (data) {
      setInitialValues({
        name: data.name || "",
        unit: data.unit || "",
        price: data.price || 0,
        description: data.description || "",
      });
    }
  }, [data]);

  const validation: any = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      unit: Yup.string().required("Unit is required"),
      price: Yup.number().required("Price is required"),
      description: Yup.string().required("Description is required"),
    }),

    onSubmit: async (values: any) => {
      try {
        const postData = {
          name: values.name,
          price: values.price,
          description: values.description,
          unit: values.unit,
          id: Number(workActivityId),
        };
        console.log(postData);
        await api.create(POST_UPDATEWORKACTIVITY_TECHNICALDEPUTY, postData);
        toast.info("Added to Server");
        window.location.reload();
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error");
      }
    },
  });

  if (loading) return <p>loading ...</p>;

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-1 text-15">اطلاعات کار</h6>
          <p className="mb-4 text-slate-500 dark:text-zink-200"></p>
          <form
            action="#!"
            onSubmit={(event: any) => {
              event.preventDefault();
              validation.handleSubmit();
            }}
          >
            <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
              <div className="xl:col-span-6">
                <label
                  htmlFor="name"
                  className="inline-block mb-2 text-base font-medium"
                >
                  نام
                </label>
                <input
                  type="text"
                  id="name"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="نام"
                  defaultValue={data.name}
                  onChange={validation.handleChange}
                />
                {validation.touched.name && validation.errors.name ? (
                  <div className="text-red-500">
                    {validation.touched.name && validation.errors.name}
                  </div>
                ) : null}
              </div>
              <div className="xl:col-span-6">
                <label
                  htmlFor="unit"
                  className="inline-block mb-2 text-base font-medium"
                >
                  واحد
                </label>
                <input
                  type="text"
                  id="unit"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="عدد"
                  defaultValue={data.unit}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />{" "}
                {validation.touched.unit && validation.errors.unit ? (
                  <div className="text-red-500">{validation.errors.unit}</div>
                ) : null}
              </div>
              <div className="xl:col-span-6">
                <label
                  htmlFor="price"
                  className="inline-block mb-2 text-base font-medium"
                >
                  قیمت
                </label>
                <input
                  type="number"
                  id="price"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="10000"
                  defaultValue={data.price}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />{" "}
                {validation.touched.price && validation.errors.price ? (
                  <div className="text-red-500">{validation.errors.price}</div>
                ) : null}
              </div>
              <div className="xl:col-span-6">
                <label
                  htmlFor="description"
                  className="inline-block mb-2 text-base font-medium"
                >
                  توضیحات
                </label>
                <input
                  type="text"
                  id="description"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter your description"
                  defaultValue={data.description}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />{" "}
                {validation.touched.description &&
                validation.errors.description ? (
                  <div className="text-red-500">
                    {validation.errors.description}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex justify-end mt-6 gap-x-4">
              <button
                type="submit"
                data-action="next"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                <span className="align-middle">{props.t("Update")}</span>{" "}
              </button>
            </div>
          </form>{" "}
          <ToastContainer theme="colored" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(PersonalTabs);
