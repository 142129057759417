import React, { useCallback, useState } from "react";
import useFetchProduct from "./useFetchProduct";

function getParameterByName(name: any, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const TableProductProject = () => {
  let x = 1;
  var projectId = getParameterByName("projectId");
  let { products, loadingProduct } = useFetchProduct(projectId);

  if (loadingProduct) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-12 text-15">محصولات</h6>
          <div className="overflow-x-auto">
            <table className="w-full bg-custom-50 dark:bg-custom-500/10">
              <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                <tr>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    ردیف
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    نام
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    تعداد
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr> */}
                {!loadingProduct &&
                  products.products.map((product, index) => (
                    <tr key={product.id + index}>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <a
                          href="#!" // Use downloadLink if available, otherwise fallback to "#"
                          className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                        >
                          {x++}
                        </a>
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {product.name || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {product.quantity || "-"}{" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TableProductProject;
