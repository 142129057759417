// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_PROJECTFILESLIST_TEAMHEAD } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";
import { number } from "yup";

const api = new APIClient();

interface File {
 
  files: [];
  result:Number;
  messages:[];
}




// interface MyData {
//   projects: File[];
// }


let val: File;


const useFetchDataFile = (id:any) => {
  const [data3, setData] = useState(val);
  const [loading3, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api.get(GET_PROJECTFILESLIST_TEAMHEAD, { projectId: id }).then((response) => {
          setData(JSON.parse(JSON.stringify(response)));
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    data3,
    loading3,
  };
};

export default useFetchDataFile;
