// use-fetch-data.js
import { useEffect, useState } from "react";
import {
  GET_TEAMHEAD_REQWORKS,
 
} from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

export interface ReqWork {
  projectManagerName: string;
  projectCode: number;
  note: string;
  finished: boolean;
  approved: boolean;
  wholeAmount: number;
  id: number;
  date: string;
}


const api = new APIClient();

interface ReqWorks {
  reqWorkOfProjects: ReqWork[];

}



let val: ReqWorks;


const useFetchData = (id: any) => {
  const [reqWorks, setData] = useState(val);
 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_TEAMHEAD_REQWORKS, { projectId: id })
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  return {
    reqWorks,
    loading,
  };
};

export default useFetchData;
