import React from "react";
import AuthIcon from "pages/Authentication/AuthIcon";
import { useDispatch, useSelector } from "react-redux";
import { registerUser, resetRegisterFlag } from "slices/thunk";
import { createSelector } from "reselect";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Formik validation
import * as Yup from "yup";
import { useFormik as useFormic } from "formik";

// Image
import logoLight from "assets/images/logo-light.png";
import logoDark from "assets/images/logo-dark.png";
import { RootState } from "slices";

const Register = () => {
  document.title = "ثبت نام | Chekadbam";

  const dispatch = useDispatch<any>();
  const navigation = useNavigate(); // Use the useNavigate hook

  const selectRegister = createSelector(
    (state: RootState) => state.Register,
    (register) => ({
      success: register.success,
    })
  );

  const { success } = useSelector(selectRegister);

  const validation: any = useFormic({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      mobile: "09182891531",
      password: "aa123465aa" || "",
      confirmPassword: "aa123456aa" || "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string().required("Please Enter Your Mobile"),
      password: Yup.string().required("Please Enter Your Password"),
      confirmPassword: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values: any) => {
      dispatch(registerUser(values, navigation));
    },
  });

  React.useEffect(() => {
    setTimeout(() => {
      dispatch(resetRegisterFlag());
    }, 3000);
  }, [dispatch, success, navigation]);

  React.useEffect(() => {
    const bodyElement = document.body;

    bodyElement.classList.add(
      "flex",
      "items-center",
      "justify-center",
      "min-h-screen",
      "py-16",
      "lg:py-10",
      "bg-slate-50",
      "dark:bg-zink-800",
      "dark:text-zink-100",
      "font-public"
    );

    return () => {
      bodyElement.classList.remove(
        "flex",
        "items-center",
        "justify-center",
        "min-h-screen",
        "py-16",
        "lg:py-10",
        "bg-slate-50",
        "dark:bg-zink-800",
        "dark:text-zink-100",
        "font-public"
      );
    };
  }, []);

  return (
    <React.Fragment>
      <div className="relative">
        <AuthIcon />

        <div className="mb-0 w-screen lg:w-[500px] card shadow-lg border-none shadow-slate-100 relative">
          <div className="!px-10 !py-12 card-body">
            <Link to="/">
              <img
                src={logoLight}
                alt=""
                className="hidden h-6 mx-auto dark:block"
              />
              <img
                src={logoDark}
                alt=""
                className="block h-6 mx-auto dark:hidden"
              />
            </Link>

            <div className="mt-8 text-center">
              <h4 className="mb-1 text-custom-500 dark:text-custom-500">
                ساخت حساب کاربری
              </h4>
              <p className="text-slate-500 dark:text-zink-200"></p>
            </div>

            <form
              action="/"
              className="mt-10"
              id="registerForm"
              onSubmit={(event: any) => {
                event.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="mb-3">
                <label
                  htmlFor="mobile-field"
                  className="inline-block mb-2 text-base font-medium"
                >
                  موبایل
                </label>
                <input
                  type="text"
                  id="mobile-field"
                  name="mobile"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="09123456789"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.mobile || ""}
                />
                {validation.touched.mobile && validation.errors.mobile ? (
                  <div id="mobile-error" className="mt-1 text-sm text-red-500">
                    {validation.errors.mobile}
                  </div>
                ) : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="password-field"
                  className="inline-block mb-2 text-base font-medium"
                >
                  پسورد
                </label>
                <input
                  type="password"
                  id="password-field"
                  name="password"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="رمز ورود"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                />
                {validation.touched.password && validation.errors.password ? (
                  <div
                    id="password-error"
                    className="mt-1 text-sm text-red-500"
                  >
                    {validation.errors.password}
                  </div>
                ) : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="inline-block mb-2 text-base font-medium"
                >
                  تایید پسورد
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="تکرار رمز ورود"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.confirmPassword || ""}
                />
                {validation.touched.confirmPassword &&
                validation.errors.confirmPassword ? (
                  <div
                    id="confirmPassword-error"
                    className="mt-1 text-sm text-red-500"
                  >
                    {validation.errors.confirmPassword}
                  </div>
                ) : null}
              </div>
              <p className="italic text-15 text-slate-500 dark:text-zink-200">
                با ثبت نام شما قوانین و مقررات چکادبام را تایید کرده‌اید.{" "}
                <a href="#!" className="underline">
                  شرایط استفاده
                </a>
              </p>
              <div className="mt-10">
                <button
                  type="submit"
                  className="w-full text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                >
                  ثبت نام
                </button>
              </div>

              <div className="mt-10 text-center">
                <p className="mb-0 text-slate-500 dark:text-zink-200">
                  حساب کاربری دارید؟ ?{" "}
                  <Link
                    to="/login"
                    className="font-semibold underline transition-all duration-150 ease-linear text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500"
                  >
                    ورود
                  </Link>{" "}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ToastContainer theme="colored" />
    </React.Fragment>
  );
};

export default Register;
