import React from "react";
import BreadCrumb from "Common/BreadCrumb";
import TableWorks from "./TableWorks";

const BasicTable = () => {
  return (
    <React.Fragment>
      <div className="container-fluid group-data-[content=boxed]:max-w-boxed mx-auto">
        <BreadCrumb title="جبهه کاری" pageTitle="لیست" />

        <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-1">
          <TableWorks />
        </div>
      </div>
    </React.Fragment>
  );
};

export default BasicTable;
