// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_CONTRACTDESIGNDETAIL_TEAMHEAD } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface DesignContract {
  potNumber: string;
  conNumber: string;
  buildingType: string;
  date?: string;
  price?: number;
  customerId: number;
  projectId: number;
  projectCode: string;
  isApprovedByTechnicalDeputy: boolean;
  isApprovedByCustomer?: boolean;
}

let val: DesignContract;

const useFetchData = (id: any) => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_CONTRACTDESIGNDETAIL_TEAMHEAD, { projectId: id })
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
