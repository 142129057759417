import React from "react";
import useFetchData from "./FetchData";

import { Plus } from "lucide-react";
import { getParameterByName } from "helpers/url_helper";

const TableWorkRequestWork = () => {
  let x = 1;
  var reqWorkId = getParameterByName("reqWorkId");
  let { data, loading } = useFetchData(reqWorkId);

  if (loading) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-12 text-15"> کار جبهه کاری</h6>
          <div className="">
            <div className="overflow-x-auto">
              <table className="w-full bg-custom-50 dark:bg-custom-500/10">
                <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                  <tr>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      ردیف
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      نام محصول
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      متراژ یا تعداد
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      قیمت واحد
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      قیمت کل
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      واحد
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      وضعیت
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr> */}
                  {!loading &&
                    data.worksOfReqWorks.map((works) => (
                      <tr key={works.id}>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          <a
                            href="#!" // Use downloadLink if available, otherwise fallback to "#"
                            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                          >
                            {x++}
                          </a>
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {works.name || "-"}{" "}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {works.count || "-"}{" "}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {works.unitPrice || "-"}{" "}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {works.wholePrice || "-"}{" "}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {works.unit || "-"}{" "}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {works.isDone ? (
                            <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-custom-500 border-custom-500 text-custom-50">
                              Done
                            </span>
                          ) : (
                            <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-red-500 border-red-500 text-red-50">
                              Not Done
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TableWorkRequestWork;
