import React from "react";
import BreadCrumb from "Common/BreadCrumb";
import FaqSearch from "./FaqSearch";
const Faqs = () => {
  return (
    <React.Fragment>
      <BreadCrumb title="Under Construction" pageTitle="Pages" />
      <FaqSearch />
    </React.Fragment>
  );
};

export default Faqs;
