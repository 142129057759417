import {
  Award,
  CalendarDays,
  CircuitBoard,
  Codesandbox,
  FileText,
  LifeBuoy,
  LocateFixed,
  Mail,
  Map,
  MessageSquare,
  MonitorDot,
  PackagePlus,
  PictureInPicture2,
  PieChart,
  RadioTower,
  ScrollText,
  Share2,
  ShoppingBag,
  Table,
  Trophy,
  UserRound,
} from "lucide-react";

const menuDataCustomer: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "dashboard",
    label: "Dashboards",
    link: "/customers/dashboard",
    icon: <MonitorDot />,
  },
  {
    id: "Projects",
    label: "project Page",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "orderProject",
        label: "Order Project",
        link: "/customers/project/order",
        parentId: "landing",
      },
      // {
      //   id: "Project process",
      //   label: "Project process",
      //   link: "/customers/project/projectprocess",
      //   parentId: "landing",
      // },
      {
        id: "List Projects",
        label: "List Projects",
        link: "/customers/project/projectlist",
        parentId: "landing",
      },
    ],
  },
  {
    id: "tickets",
    label: "Tickets",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "submitTicket",
        label: "Submit Ticket",
        link: "/customers/tickets/submitticket",
        parentId: "landing",
      },
      {
        id: "ticketList",
        label: "Ticket List",
        link: "/customers/tickets/ticketlist",
        parentId: "landing",
      },
    ],
  },
  {
    id: "invoice",
    label: "Invoices",
    icon: <FileText />,
    parentId: 2,
    subItems: [
      {
        id: "invoicelistview",
        label: "Listview",
        link: "/customers/apps-invoice-list",
        parentId: "invoice",
      },
      // {
      //   id: "invoiceaddnew",
      //   label: "Add New",
      //   link: "/customers/apps-invoice-add-new",
      //   parentId: "invoice",
      // },
      // {
      //   id: "invoiceoverview",
      //   label: "Overview",
      //   link: "/customers/apps-invoice-overview",
      //   parentId: "invoice",
      // },
    ],
  },
  {
    label: "Account",
    isTitle: true,
  },
  {
    id: "account",
    label: "Account",
    link: "/customers/update-profile",
    icon: <CalendarDays />,
    subItems: [
      // {
      //   id: "accountSetting",
      //   label: "Account Setting",
      //   link: "/customers/user-profile",
      //   parentId: "calendar",
      // },
      {
        id: "updateProfile",
        label: "Account Setting",
        link: "/customers/user-profile",
        parentId: "calendar",
      },
    ],
  },
  {
    label: "Pages",
    isTitle: true,
  },

  {
    id: "pages",
    label: "Pages",
    icon: <Codesandbox />,
    parentId: 2,
    subItems: [
      {
        id: "faq",
        label: "FAQs",
        link: "/pages-faqs",
        parentId: "pages",
      },
      {
        id: "contactus",
        label: "Contact US",
        link: "/pages-contact-us",
        parentId: "pages",
      },
    ],
  },
];

export { menuDataCustomer };
