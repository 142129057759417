import React from "react";
import BreadCrumb from "Common/BreadCrumb";
import ColorsTable from "./ColorsTable";
import { withTranslation } from "react-i18next";

const BasicTable = (props:any) => {
  return (
    <React.Fragment>
      <div className="container-fluid group-data-[content=boxed]:max-w-boxed mx-auto">
        <BreadCrumb title={props.t("Projects List")} pageTitle="Customer Projects" />

        <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-1">
          <ColorsTable />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(BasicTable);
