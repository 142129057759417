// dashboard
import CustomerDashboard from "pages/Customers/Dashboard";
import AccountantDashboard from "pages/Accountant/Dashboard";
import WarehouseDashboard from "pages/Warehouse/Dashboard";
import AdminDashboard from "pages/Admin/Dashboard";
import WorkerDashboard from "pages/Worker/Dashboard";
import MangerDashboard from "pages/Manager/Dashboard";
import TechnicalDeputyDashboard from "pages/TechnicalDeputy/Dashboard";
import DesignerDashboard from "pages/Designer/Dashboard";
import TeamHeadDashboard from "pages/TeamHead/Dashboard";
import AdminOrderForm from "pages/Admin/OrderForm/Order/AdminOrderForm";
import MemberListAdmin from "pages/Admin/Customers/List/TableMember";

//teamhead
import CreateProjectTeamHead from "pages/TeamHead/Projects/Order/FormWizard";
import ProjectListTeamHead from "pages/TeamHead/Projects/List/index";
import ListMemberInRequest from "pages/TeamHead/ReqWork/ListMemberInRequest/index";
import TeamHeadDesignContracList from "pages/TeamHead/Contracts/DesignContract/List/index";
import TeamHeadDesignContracCreateList from "pages/TeamHead/Contracts/DesignContract/Create/Form";
import TeamHeadDesignContracDetailist from "pages/TeamHead/Contracts/DesignContract/Detail/Overview";
import TeamHeadExeContracCreateList from "pages/TeamHead/Contracts/ExeContract/Create/Form";
import TeamHeadExeContracDetailList from "pages/TeamHead/Contracts/ExeContract/Detail/Overview";
import TeamHeadExeContracList from "pages/TeamHead/Contracts/ExeContract/List/index";
import TeamHeadReqWorkList from "pages/TeamHead/ReqWork/ListRequestWork/index";
import TeamHeadWorkList from "pages/TeamHead/ReqWork/ListWorksInRequest/index";
import TeamHeadProductList from "pages/TeamHead/Product/index";
import TeamHeadMemberList from "pages/TeamHead/ReqWork/ListMemberInRequest/index";
import TeamHeadEstimateWorkList from "pages/TeamHead/Estimate/index";
import TeamHeadSettings from "pages/TeamHead/Profile/index";
import TeamHeadWorkerList from "pages/TeamHead/ReqWork/ListWorkersInRequest/index";
import TeamHeadCreateCustomer from "pages/TeamHead/Customers/Create/FormWizard";
import TeamHeadProjectDetail from "pages/TeamHead/Projects/Detail/FormWizard";

// Techinical Deputy

// Techinical Deputy
// Techinical Deputy
import CreateProjectTechinicalDeputy from "pages/TechnicalDeputy/Projects/Order/FormWizard";
import ProjectListTechinicalDeputy from "pages/TechnicalDeputy/Projects/List/index";
import DesignContractListTechinicalDeputy from "pages/TechnicalDeputy/Contracts/Design/List/index";
import ImplementContractListTechinicalDeputy from "pages/TechnicalDeputy/Contracts/Implement/List/index";
import RequestWorkListTechinicalDeputy from "pages/TechnicalDeputy/RequestWork/List/index";
import RequestWorkWorkListTechinicalDeputy from "pages/TechnicalDeputy/RequestWork/Works/index";
import RequestWorkMemberListTechinicalDeputy from "pages/TechnicalDeputy/RequestWork/Members/index";
import RequestWorkOfProjectListTechinicalDeputy from "pages/TechnicalDeputy/Projects/RequestWork/index";
import ProjectProductsListTechinicalDeputy from "pages/TechnicalDeputy/Projects/Products/";
import ProjectEstimateListTechinicalDeputy from "pages/TechnicalDeputy/Projects/Estimate/index";
import ImpContractDetailTechinicalDeputy from "pages/TechnicalDeputy/Contracts/Implement/Detail/Overview";
import DesignContractDetailTechinicalDeputy from "pages/TechnicalDeputy/Contracts/Design/Detail/Overview";
import ProfileSettingTechinicalDeputy from "pages/TechnicalDeputy/Account/Profile/index";
import WorkActivityTechinicalDeputy from "pages/TechnicalDeputy/WorkActivity/List/index";
import WorkActivityCreateTechinicalDeputy from "pages/TechnicalDeputy/WorkActivity/Create/index";
import WorkActivityUpdateTechinicalDeputy from "pages/TechnicalDeputy/WorkActivity/Update/index";
//Manager
import ProjectListManager from "pages/Manager/Projects/index";
import RequestWorkListManager from "pages/Manager/RequestWork/list/index";
import RequestWorkWorkListManager from "pages/Manager/RequestWork/Works/index";
import RequestWorkMemberListManager from "pages/Manager/RequestWork/Members/index";
import ProfileSettingManager from "pages/Manager/Account/Profile/index";
import RequestWorkOfProjectListManager from "pages/Manager/RequestWorkOfProject/list/index";
import ProductOfProjectManager from "pages/Manager/ProjectProduct/index";

//worker
import WORKERINVOICELIST from "pages/Worker/Accounting/List/index";
import WORKERINVOICEDETAIL from "pages/Worker/Accounting/Detail/Overview";
import WORKERWORKLIST from "pages/Worker/Works/List/index";
import WORKERWORKDETAIL from "pages/Worker/Works/Detail/Overview";
import WORKERDASHBOARD from "pages/Worker/Dashboard";
import ProfileSettingWorker from "pages/Worker/Profile/index";



//Accountant

//Accountant
import AccountantProjectList from "pages/Accountant/Projects/list/index";
import AccountantProductOfProject from "pages/Accountant/Projects/Product/index";
import AccountantRequestWorkOfProject from "pages/Accountant/Projects/RequestWork/index";
import AccountantMemberRequestWork from "pages/Accountant/RequestWork/Members/index";
import AccountantWorkRequestWork from "pages/Accountant/RequestWork/Works/index";
import AccountantWorkerInvoiceDetail from "pages/Accountant/Invoice/Detail/Overview";
import AccountantWorkersList from "pages/Accountant/Workers/List/index";
import AccountantWorkerInvoicesList from "pages/Accountant/Workers/Invoices/index";
import AccountantRequestWorkInvoices from "pages/Accountant/RequestWork/Invoices/index";
import AccountantSettings from "pages/Accountant/Profile/index";
import AccountantRequestWork from "pages/Accountant/RequestWork/List/index";
//Auth
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import Register from "pages/Authentication/Register";
import UserProfile from "pages/Authentication/Profile/index";

import Faqs from "pages/Pages/Faqs";
import TicketList from "pages/Customers/Tickets/TicketList";
import SubmitTicket from "pages/Customers/Tickets/SubmitTicket";
import CustomerProjectProcess from "pages/Customers/Projects/Process/CustomerProjectProcess";
import CustomerOrderForm from "pages/Customers/Projects/Order/CustomerOrderForm";
import CustomerSettings from "pages/Customers/Profile";
import ProjectList from "pages/Customers/Projects/List/index";
import UnderConstruction from "pages/UnderConstruction";
import ContactUs from "pages/ContactUs";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // pages
  { path: "/pages-faqs", component: Faqs },
];

const publicRoutes = [
  // authentication
  { path: "/", component: Login },
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },
];

const authProtectedRoutesAdmin: Array<RouteObject> = [
  // Dashboard
  { path: "/admin/dashboard", component: AdminDashboard },

  //ticket
  { path: "/admin/tickets/submitticket", component: SubmitTicket },

  { path: "/admin/project/order", component: AdminOrderForm },
  { path: "/admin/tickets/submitticket", component: SubmitTicket },
  { path: "/admin/tickets/ticketlist", component: TicketList },
  { path: "/admin/users/list", component: MemberListAdmin },

  // Users
];
const authProtectedRoutesCustomer: Array<RouteObject> = [
  // Dashboard
  { path: "/Customers/dashboard", component: CustomerDashboard },
  { path: "/Customers/Project/list", component: ProjectList },
  { path: "/customers/project/order", component: CustomerOrderForm },
  {
    path: "/customers/project/projectprocess",
    component: CustomerProjectProcess,
  },
  { path: "/customers/tickets/submitticket", component: SubmitTicket },

  { path: "/customers/tickets/ticketlist", component: TicketList },

  // profile
  { path: "/customers/user-profile", component: UserProfile },
  { path: "/customers/update-profile", component: CustomerSettings },
];
const authProtectedRoutesAccountant: Array<RouteObject> = [
  // Dashboard
  { path: "/accountant/dashboard", component: AccountantDashboard },
  { path: "/accountant/project/list", component: AccountantProjectList },
  {
    path: "/accountant/project/product",
    component: AccountantProductOfProject,
  },
  {
    path: "/accountant/project/requestwork",
    component: AccountantRequestWorkOfProject,
  },
  {
    path: "/accountant/requestwork/listofmember",
    component: AccountantMemberRequestWork,
  },
  {
    path: "/accountant/requestwork/listofwork",
    component: AccountantWorkRequestWork,
  },
  {
    path: "/accountant/requestwork/list",
    component: AccountantRequestWork,
  },

  {
    path: "/accountant/requestwork/invoices",
    component: AccountantRequestWorkInvoices,
  },

  {
    path: "/accountant/invoice/detail",
    component: AccountantWorkerInvoiceDetail,
  },
  {
    path: "/accountant/workers",
    component: AccountantWorkersList,
  },
  {
    path: "/accountant/workers/invoices",
    component: AccountantWorkerInvoicesList,
  },

  { path: "/accountant/tickets/submitticket", component: SubmitTicket },
  { path: "/accountant/tickets/ticketlist", component: TicketList },
  { path: "/accountant/pages-faqs", component: Faqs },
  { path: "/accountant/UnderConstruction", component: UnderConstruction },
  { path: "/accountant/pages-contact-us", component: ContactUs },
  { path: "/accountant/account/setting", component: AccountantSettings },
];
const authProtectedRoutesWarehouse: Array<RouteObject> = [
  // Dashboard
  { path: "/Warehouse/dashboard", component: WarehouseDashboard },
  { path: "/Warehouse/tickets/submitticket", component: SubmitTicket },
  { path: "/Warehouse/tickets/ticketlist", component: TicketList },
];
const authProtectedRoutesWorker: Array<RouteObject> = [
  // Dashboard
  { path: "/Worker/dashboard", component: WorkerDashboard },
  { path: "/Worker/tickets/submitticket", component: SubmitTicket },
  { path: "/Worker/tickets/ticketlist", component: TicketList },
  { path: "/Worker/dashboard", component: WORKERDASHBOARD },
  { path: "/worker/accounting", component: WORKERINVOICELIST },
  { path: "/worker/works", component: WORKERWORKLIST },
  { path: "/worker/accounting/detail", component: WORKERINVOICEDETAIL },
  { path: "/worker/works/detail", component: WORKERWORKDETAIL },
  { path: "/worker/pages-faqs", component: Faqs },
  { path: "/worker/UnderConstruction", component: UnderConstruction },
  { path: "/worker/pages-contact-us", component: ContactUs },
  // { path: "/worker/account", component: ProfileSettingWorker },
  { path: "/worker/user-profile", component: ProfileSettingWorker },
];

const authProtectedRoutesManger: Array<RouteObject> = [
  // DashboardManger

  { path: "/Manager/dashboard", component: MangerDashboard },
  { path: "/Manager/Project/list", component: ProjectListManager },
  { path: "/Manager/tickets/submitticket", component: SubmitTicket },
  { path: "/Manager/tickets/ticketlist", component: TicketList },
  { path: "/Manager/reqwork/list", component: RequestWorkListManager },
  { path: "/Manager/reqwork/member", component: RequestWorkMemberListManager },
  { path: "/Manager/reqwork/works", component: RequestWorkWorkListManager },
  { path: "/Manager/account", component: ProfileSettingManager },
  {
    path: "/Manager/reqwork/projectReqWorks",
    component: RequestWorkOfProjectListManager,
  },
  {
    path: "/Manager/project/product",
    component: ProductOfProjectManager,
  },
];

//////////////////////   TechnicalDeputy   /////////////////////////////////
const authProtectedRoutesTechnicalDeputy: Array<RouteObject> = [
  { path: "/TechnicalDeputy/pages-faqs", component: Faqs },
  { path: "/TechnicalDeputy/UnderConstruction", component: UnderConstruction },
  { path: "/TechnicalDeputy/pages-contact-us", component: ContactUs },
  { path: "/TechnicalDeputy/dashboard", component: TechnicalDeputyDashboard },
  { path: "/TechnicalDeputy/tickets/submitticket", component: SubmitTicket },
  { path: "/TechnicalDeputy/tickets/ticketlist", component: TicketList },
  { path: "/TechnicalDeputy/dashboard", component: TechnicalDeputyDashboard },
  {
    path: "/TechnicalDeputy/Project/list",
    component: ProjectListTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/workactivity/Create",
    component: WorkActivityCreateTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/workactivity/update",
    component: WorkActivityUpdateTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/workactivity/list",
    component: WorkActivityTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/Project/Order",
    component: CreateProjectTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/contract/designcontract",
    component: DesignContractListTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/contract/execontract",
    component: ImplementContractListTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/requestwork/list",
    component: RequestWorkListTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/requestwork/listofwork",
    component: RequestWorkWorkListTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/requestwork/listofmember",
    component: RequestWorkMemberListTechinicalDeputy,
  },

  { path: "/TechnicalDeputy/dashboard", component: TechnicalDeputyDashboard },
  { path: "/TechnicalDeputy/tickets/submitticket", component: SubmitTicket },
  { path: "/TechnicalDeputy/tickets/ticketlist", component: TicketList },
  { path: "/TechnicalDeputy/dashboard", component: TechnicalDeputyDashboard },
  {
    path: "/TechnicalDeputy/Project/list",
    component: ProjectListTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/Project/Order",
    component: CreateProjectTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/contract/designcontract",
    component: DesignContractListTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/contract/execontract",
    component: ImplementContractListTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/requestwork/list",
    component: RequestWorkListTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/requestwork/listofwork",
    component: RequestWorkWorkListTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/requestwork/listofmember",
    component: RequestWorkMemberListTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/project/requestwork",
    component: RequestWorkOfProjectListTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/project/products",
    component: ProjectProductsListTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/project/estimate",
    component: ProjectEstimateListTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/impcontract/detail",
    component: ImpContractDetailTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/designcontract/detail",
    component: DesignContractDetailTechinicalDeputy,
  },
  {
    path: "/TechnicalDeputy/account/setting",
    component: ProfileSettingTechinicalDeputy,
  },
];

const authProtectedRoutesDesigner: Array<RouteObject> = [
  // DashboardManger
  { path: "/designer/dashboard", component: DesignerDashboard },
  { path: "/Designer/tickets/submitticket", component: SubmitTicket },
  { path: "/Designer/tickets/ticketlist", component: TicketList },
];

const authProtectedRoutesTeamHead: Array<RouteObject> = [
  // TeamHead
  { path: "/TeamHead/dashboard", component: TeamHeadDashboard },
  { path: "/TeamHead/Project/list", component: ProjectListTeamHead },
  { path: "/TeamHead/Project/create", component: CreateProjectTeamHead },
  {
    path: "/TeamHead/project/RequestMemberList",
    component: ListMemberInRequest,
  },
  {
    path: "/TeamHead/designContract",
    component: TeamHeadDesignContracList,
  },
  {
    path: "/TeamHead/designContract/Create",
    component: TeamHeadDesignContracCreateList,
  },
  {
    path: "/TeamHead/exeContract",
    component: TeamHeadExeContracList,
  },
  {
    path: "/TeamHead/exeContract/Detail",
    component: TeamHeadExeContracDetailList,
  },
  {
    path: "/TeamHead/exeContract/Create",
    component: TeamHeadExeContracCreateList,
  },
  {
    path: "/TeamHead/createCustomer",
    component: TeamHeadCreateCustomer,
  },
  { path: "/TeamHead/ReqWork/Memberlist", component: TeamHeadMemberList },
  { path: "/TeamHead/ReqWork/Requestlist", component: TeamHeadReqWorkList },
  { path: "/TeamHead/ReqWork/Worklist", component: TeamHeadWorkList },
  //estimate TEAMHEAD

  {
    path: "/TeamHead/Estimate/estimateWorkOfProject",
    component: TeamHeadEstimateWorkList,
  },
  {
    path: "/TeamHead/Product/productList",
    component: TeamHeadProductList,
  },
  {
    path: "/TeamHead/Project/detail",
    component: TeamHeadProjectDetail,
  },

  { path: "/TeamHead/user-profile", component: TeamHeadSettings },

  { path: "/TeamHead/ReqWork/Workerlist", component: TeamHeadWorkerList },
];
export {
  authProtectedRoutes,
  publicRoutes,
  authProtectedRoutesAdmin,
  authProtectedRoutesCustomer,
  authProtectedRoutesAccountant,
  authProtectedRoutesWarehouse,
  authProtectedRoutesWorker,
  authProtectedRoutesManger,
  authProtectedRoutesTechnicalDeputy,
  authProtectedRoutesDesigner,
  authProtectedRoutesTeamHead,
};
