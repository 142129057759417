import React from "react";
import BreadCrumb from "Common/BreadCrumb";
import DefaultWithout from "./DefaultWithout";

const TicketList = () => {
  return (
    <React.Fragment>
      <div className="container-fluid group-data-[content=boxed]:max-w-boxed mx-auto">
        <BreadCrumb title="TicketList Table" pageTitle="TicketList" />
        <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-2">
          <DefaultWithout />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TicketList;
