// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_PRODUCTSTOPROJECT_MANAGER } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface Product {
  id: number;
  name: string;
  quantity: number;
}

interface Products {
  products: Product[];
}

let val: Products;

const useFetchProduct = (id: any) => {
  const [products, setData] = useState(val);
  const [loadingProduct, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_PRODUCTSTOPROJECT_MANAGER, { projectId: id })
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  // console.log(data);
  return {
    products,
    loadingProduct,
  };
};

export default useFetchProduct;
