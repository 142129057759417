import React from "react";
import BreadCrumb from "Common/BreadCrumb";
import WelcomeWidget from "./WelcomeWidget";
import Widgets from "./Widgets";
import { withTranslation } from "react-i18next";
import TrafficResources from "./TrafficResources";
import SalesRevenue from "./SalesRevenue";

const TechnicalDeputyDashboard = (props: any) => {
  return (
    <React.Fragment>
      <BreadCrumb
        title={props.t("TechnicalDeputy")}
        pageTitle={props.t("TechnicalDeputy Dashboard")}
      />
      <div className="grid grid-cols-12 gap-x-5">
        <WelcomeWidget />
        <Widgets />
        <SalesRevenue />

        <TrafficResources />
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(TechnicalDeputyDashboard);
