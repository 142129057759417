import React, { useCallback, useEffect, useState } from "react";
import { MoveRight, Gem, Smartphone, Globe } from "lucide-react";
import { useFormik } from "formik";
import Select from "react-select";
import { APIClient } from "helpers/api_helper";
import styles from "./mystyle.module.css";
import * as Yup from "yup";
import useFetchData from "./FetchDataDetail";
import useFetchDataCustomer from "./FetchDataCustomer";
import useFetchDataDeleteFile from "./FetchDataDeleteFile";
import useFetchDataFile from "./FetchDataFile";
import { POST_UPDATEPROJECT_TEAMHEAD, DELETE_PROJECTFILES_TEAMHEAD,ADD_PROJECTFILES_TEAMHEAD} from "helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
// import { DatePicker } from "zaman";
//شمسی

import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import type{Value} from "react-multi-date-picker"

import { UploadCloud } from "lucide-react";
import axios from "axios";
import moment from "moment-jalaali";

const api = new APIClient();

//param
function getParameterByName(name: any, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
var projectId = getParameterByName("projectId");

const handleDeletImg= async (id:any)=>{
  try {
 
  //  console.log(postDate);
  const postDate = {
    id: id,
  };
   await api.get( DELETE_PROJECTFILES_TEAMHEAD, postDate);
   toast.info("عکس با موفقیت حذف شد");
   // Reload the page to reflect changes
   
   window.location.reload();
 } catch (error) {
   console.error("Error");
 }

}
interface Options {
  label: string;
  value?: string;
  isDisabled?: boolean;
  options?: Options[];
}




const FormEdit = () => {
  const DefaultOptions: Options[] = [];
  const DefaultOptions1: Options[] = [
    { value: "در حال ساخت", label: "در حال ساخت " },
    { value: "اتمام پروژه", label: "اتمام پروژه" },
  ];



  

  
  let { data1, loading1 } = useFetchData(projectId);
  let { data3, loading3 } = useFetchDataFile(projectId);
  // let { data2, loading2 } = useFetchDataDeleteFile(id);
 
  let { data, loading } = useFetchDataCustomer();
  if (!loading3)
  console.log(data1);
  // console.log(data);
  // console.log(data3);



  
  if (!loading)
    data.members.map((customer: any) => {
      const newOption: Options = {
        label: customer.fullName,
        value: customer.id,
      };
      DefaultOptions.push(newOption);
    });



//postfiles 
const validation2: any = useFormik({
  initialValues: {
    ProjectId: projectId,
    Files: [],
  },
  validationSchema: Yup.object().shape({
    // FirstVisit: Yup.date().required("تاریخ بازدید الزامی است"),
    // File: Yup.array().of(
    //   Yup.mixed().test("fileType", "Unsupported file format", (value: any) =>
    //     ["image/jpeg", "image/jpg", "image/png", "image/gif"].includes(value?.type)
    //   )
    // ).min(1, "At least one file is required")
  }),


        onSubmit: async (values: any) => {
        try {
         
          // console.log(values.File);
              const body = new FormData();

              body.append("ProjectId",values.ProjectId);
              // body.append("File", values.Files);     

               
        if (values.Files && values.Files.length > 0) {
          values.Files.forEach((file:any) => {
            body.append('Files', file);
          });
        }
               
           
             
          
                  console.log(body);
                  await axios.post(ADD_PROJECTFILES_TEAMHEAD, body, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                        "Accept": "application/json",
                        
                    },
                  });
                  toast.info("Added to Server");
                  window.location.reload();
        } 
        catch (error) {
          console.error("Error fetching data:", error);
          toast.error("Error");
        }
        },
        });





    const [initialValues, setInitialValues] = useState({
      Id: 0,
      City: "",
      State: "",
      Address: "",
      NumberOfFloor: 0,
      ProjectType: "",
      AreaSize: 0,
      BuildingType: "",
      CallTime: null as Date | null,
      FirstVisit: null as Date | null,
      ConstructionStage: "",
      CargoElevator: false,
      EmployerPosition: "",
      CustomerId:0,
      CustomerName:"",
      Representative: 0,
      CustomerNumber:0 ,
      Reperesentive:"",
    });
    
    useEffect(() => {
      if (data1) {
       
        const callTime = data1.callTime ? moment(data1.callTime).format('YYYY-MM-DD') : null;
        
      const jalaliDate = callTime ? moment(callTime, 'YYYY-MM-DD').locale('fa').format('jYYYY/jMM/jDD') : null;
      const firstVisit = data1.firstVisit ? moment(data1.firstVisit).format('YYYY-MM-DD') : null;
      const jalaliDate1 = firstVisit ? moment(firstVisit, 'YYYY-MM-DD').locale('fa').format('jYYYY/jMM/jDD') : null;



        setInitialValues({
          Id: data1?.id,
          CustomerId:data1?.customerId,
          CustomerName:data1?.customerName,
          Address: data1?.address || "",
          City: data1?.city || "",
          State: data1?.state || "",
          BuildingType: data1?.buildingType || "",
          NumberOfFloor: data1?.numberOfFloor,
          ProjectType: data1?.projectType,
          AreaSize: data1?.areaSize,
          CallTime: data1?.callTime || null,
          FirstVisit: data1?.firstVisit || null,
          ConstructionStage: data1?.constructionStage || "",
          CargoElevator: data1?.cargoElevator,
          EmployerPosition: data1?.employerPosition || "",
          Representative: data1?.representative,
          CustomerNumber:data1?.customerNumber ,
          Reperesentive:data1?.reperesentive,
        });

         // Convert the date to Jalali and set it to the DatePicker
      setValue(jalaliDate);
      setValue1(jalaliDate1);
      }
    }, [data1]);
    
    const validation: any = useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema: Yup.object().shape({
        CustomerId: Yup.number().required("First Name is required"),
        City: Yup.string().required("City is required."),
        State: Yup.string().required("State is required."),
        Address: Yup.string().required("Address is required."),
        NumberOfFloor: Yup.number().required("Number of floor is required."),
        ProjectType: Yup.string().required("project Type is required."),
        AreaSize: Yup.number().required("Area Size is required."),
        BuildingType: Yup.string().required("Building Type is required."),
        CallTime: Yup.date().required("تاریخ تماس الزامی است"),
      }),
      onSubmit: async (values: any) => {
        try {
          const body = new FormData();
         

          body.append("Id", values.Id.toString());
          body.append("City", values.City);
          body.append("State", values.State);
          body.append("Address", values.Address);
          body.append("NumberOfFloor", values.NumberOfFloor.toString());
          body.append("ProjectType", values.ProjectType);
          body.append("AreaSize", values.AreaSize.toString());
          body.append("BuildingType", values.BuildingType);
          body.append("CallTime", values.CallTime);
          body.append("FirstVisit", values.FirstVisit) ;
          body.append("ConstructionStage", values.ConstructionStage);
          body.append("CargoElevator", values.CargoElevator.toString());
          body.append("EmployerPosition", values.EmployerPosition);
          body.append("Reperesentive", values.Reperesentive.toString());
    
          // values.Files.forEach((file: File, index: number) => {
          //   body.append(`Files[${index}]`, file);
          // });
    
          console.log(body);
          await axios.post(POST_UPDATEPROJECT_TEAMHEAD, body, {
            headers: {
              'Content-Type': 'multipart/form-data'
              // Add other headers here as needed
            }
          });
          toast.info("Added to Server");
          // window.location.reload();
        } catch (error) {
          console.error("Error fetching data:", error);
          toast.error("Error");
        }
      },
    });
  const handleSelectChange = (value: any, action: any) => {
    validation.setFieldValue(action.name, value.value);
  };

  
  const [value, setValue] = useState<Value>(new Date(validation.values.CallTime));
  const [value1, setValue1] = useState<Value>(new Date(validation.values.FirstVisit));
// ...
//lightbox
const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // ایجاد آرایه images خارج از حلقه map
  const images = data3 && data3.files ? data3.files.map((img:any) => {
    const fileNameWithoutExtension = img.name.split('.').slice(0, -1).join('.');
    return `https://chekadbamapi.ir/Files/Images/ProjectThumb/${fileNameWithoutExtension}.png`;
  }) : [];
  //file upload
  // const ImageCard = ({ data3, onDelete }) => {
  //   return (
  //     <div className="image-card">
  //       <img src={data3} alt={image.realFileName} />
  //       <button onClick={() => onDelete(image.id)}>Delete</button>
  //     </div>
  //   );
  // };
  // const ImageGallery = ({ images, onDelete }) => {
  //   return (
  //     <div className="image-gallery">
  //       {images.map((image) => (
  //         <ImageCard key={image.id} image={image} onDelete={onDelete} />
  //       ))}
  //     </div>
  //   );
  // };
  // const [images, setImages] = useState([]);

  // const handleImageDelete = async (imageId) => {
  //   try {
  //     // Send a DELETE request to your server to remove the image
  //     const response = await axios.delete(`/api/images/${imageId}`); // Replace with your endpoint

  //     if (response.status === 200) {
  //       // Update the images state after successful deletion
  //       setImages(images.filter((image) => image.id !== imageId));
  //       toast.success("Image deleted successfully!");
  //     } else {
  //       toast.error("Failed to delete image.");
  //     }
  //   } catch (error) {
  //     console.error("Error deleting image:", error);
  //     toast.error("Error deleting image.");
  //   }
  // };


  if (!loading1)

  return (
    
    <React.Fragment>
      
      <h5 className="mb-3">ویرایش پروژه</h5>
      <form
       action="#!"
       onSubmit={(e) => {
         e.preventDefault();
         validation.handleSubmit();
         return false;
       }}
      >
        <label
          htmlFor="ProjectType"
          className="inline-block mb-3 text-base font-medium"
        >
          انتخاب نوع پروژه <span className="text-red-500">*</span>
        </label>
        <div
          id="ProjectTypegroup"
          className="grid grid-cols-1 gap-3 mb-4 md:grid-cols-3 lg:grid-cols-3"
        >
          <div>
            <input
              id="brandingRadio"
              name="ProjectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              value="روف گاردن"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
             checked={ validation.values.ProjectType === "روف گاردن" ? true : false} 
             
            />
            <label
              htmlFor="brandingRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Gem className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">روف گاردن</span>
            </label>



          </div>
          <div>
            <input
              id="mobileDesignRadio2"
              name="ProjectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="دیوار سبز"
              checked={ validation.values.ProjectType==="دیوار سبز" ? true : false}
            />
            <label
              htmlFor="mobileDesignRadio2"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Smartphone className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">دیوار سبز</span>
            </label>
          </div>

          <div>
            <input
              id="webDesignRadio3"
              name="ProjectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
               value="تراس سبز "
              checked={validation.values.ProjectType === "تراس سبز " ? true : false}
            />
            <label
              htmlFor="webDesignRadio3"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">تراس سبز</span>
            </label>
          </div>
          <div>
            <input
              id="webDesignRadio4"
              name="ProjectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
               value="محوطه سازی"
              checked={validation.values.ProjectType === "محوطه سازی" ? true : false}

            />
            <label
              htmlFor="webDesignRadio4"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">محوطه سازی </span>
            </label>
          </div>
          <div>
            <input
              id="webDesignRadio5"
              name="ProjectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="فروش اقلام "
              checked={validation.values.ProjectType === "فروش اقلام " ? true : false}

            />
            <label
              htmlFor="webDesignRadio5"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">فروش اقلام </span>
            </label>
          </div>
          <div>
            <input
              id="webDesignRadio6"
              name="ProjectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="کاشت گل و گیاه "
              checked={validation.values.ProjectType === "کاشت گل و گیاه " ? true : false}

            />
            <label
              htmlFor="webDesignRadio6"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">کاشت گل و گیاه </span>
            </label>
          </div>
        </div>

        <label
          htmlFor="BuildingType"
          className="inline-block mb-3 text-base font-medium"
        >
          نوع کاربری پروژه <span className="text-red-500">*</span>
        </label>
        <div
          id="buildingTypegroup"
          className="grid grid-cols-1 gap-3 mb-4 md:grid-cols-3 lg:grid-cols-3"
        >
          <div>
            <input
              id="businessRadio"
              name="BuildingType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              value="تجاری"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              checked={validation.values.BuildingType==="تجاری"}
            />
            <label
              htmlFor="businessRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Gem className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">تجاری</span>
            </label>
          </div>

          <div>
            <input
              id="officeRadio"
              name="BuildingType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="اداری"
              checked={validation.values.BuildingType==="اداری"}
            />
            <label
              htmlFor="officeRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Smartphone className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">اداری</span>
            </label>
          </div>

          <div>
            <input
              id="residentialRadio"
              name="BuildingType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="مسکونی"
              checked={validation.values.BuildingType==="مسکونی"}
            />
            <label
              htmlFor="residentialRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">مسکونی</span>
            </label>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="nameInput"
              className="inline-block mb-2 text-base font-medium"
            >
              نام مشتری <span className="text-red-500">*</span>
            </label>
            {/* <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              data-choices
              name="CustomerId" 
              
              // defaultValue={DefaultOptions.find(
              //   (option ) => option.value === validation.values.CustomerId
              // )}
              options ={DefaultOptions}
              // Initial value is set in 'initialValues'
              value={DefaultOptions.find(
                (option) => option.value === validation.values.CustomerId
              )}
              
              onChange={handleSelectChange}
              onBlur={() => validation.setFieldTouched("CustomerId", true)}
            /> */}
            <input
              type="text"
              id="CustomerName"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              disabled
              value={validation.values.CustomerName}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.customerName && validation.errors.customerName ? (
              <div className="text-red-500">{validation.errors.customerName}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="CustomerNumber"
              className="inline-block mb-2 text-base font-medium"
            >
              شماره تماس <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="CustomerNumber"
              disabled
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="شهر"
              value={validation.values.CustomerNumber}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.City && validation.errors.CustomerNumber ? (
              <div id="City" className="text-red-500">
                {validation.errors.CustomerNumber}
              </div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="City"
              className="inline-block mb-2 text-base font-medium"
            >
              شهر <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="City"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="شهر"
              value={validation.values.City}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.City && validation.errors.City ? (
              <div id="City" className="text-red-500">
                {validation.errors.City}
              </div>
            ) : null}
          </div>{" "}
          <div>
            <label
              htmlFor="State"
              className="inline-block mb-2 text-base font-medium"
            >
              استان <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="State"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="استان"
              value={validation.values.State}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.State && validation.errors.State ? (
              <div className="text-red-500">{validation.errors.State}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="phoneNumberInput"
              className="inline-block mb-2 text-base font-medium"
            >
              آدرس <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="Address"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="آدرس "
              value={validation.values.Address}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.Address && validation.errors.Address ? (
              <div className="text-red-500">{validation.errors.Address}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="numberOfFloorInput"
              className="inline-block mb-2 text-base font-medium"
            >
              شماره طبقه
            </label>
            <input
              type="number"
              name="NumberOfFloor"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder=" شماره طبقه"
              value={validation.values.NumberOfFloor}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.NumberOfFloor &&
            validation.errors.NumberOfFloor ? (
              <div className="text-red-500">
                {validation.errors.NumberOfFloor}
              </div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="areaSizeInput"
              className="inline-block mb-2 text-base font-medium"
            >
              متراژ پروژه
            </label>
            <input
              type="number"
              name="AreaSize"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder=" متراژ پروژه"
              value={validation.values.AreaSize}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.AreaSize && validation.errors.AreaSize ? (
              <div className="text-red-500">{validation.errors.AreaSize}</div>
            ) : null}
          </div>
          </div>
          <div className="flex gap-10 p-10">
  <div className="w-1/2">
    <label
      htmlFor="CallTime"
      className="block mb-2 text-base font-medium"
    >
      تاریخ تماس
    </label>
    <DatePicker
      className="w-full"
      calendar={persian}
      locale={persian_fa}
      calendarPosition="bottom-right"
      value={value}
      onChange={(newDate) => {
        let dateString = "";
    
        if (newDate && newDate.valueOf()) {
          // dateString = moment(newDate.valueOf()).format('YYYY-MM-DD');
          dateString = moment(newDate.valueOf()).utc().format('YYYY-MM-DD'); // Store in UTC

          const jalaliDate = moment(dateString, 'YYYY-MM-DD').locale('fa').format('jYYYY/jMM/jDD');
          setValue(jalaliDate);
        } else {
          setValue(null);
        }
    
        validation.setFieldValue('CallTime', dateString);
      }}
      name="CallTime"
    />
    {validation.touched.CallTime && validation.errors.CallTime ? (
      <div className="text-red-500">{validation.errors.CallTime}</div>
    ) : null}
  </div>
  <div className="w-1/2">
    <label
      htmlFor="FirstVisit"
      className="block mb-2 text-base font-medium"
    >
      بازدید اولیه
    </label>
    <DatePicker
      className="w-full"
      calendar={persian}
      locale={persian_fa}
      calendarPosition="bottom-right"
      value={value1}
      onChange={(newDate) => {
        let dateString = "";
    
        if (newDate && newDate.valueOf()) {
          dateString = moment(newDate.valueOf()).format('YYYY-MM-DD');
          const jalaliDate = moment(dateString, 'YYYY-MM-DD').locale('fa').format('jYYYY/jMM/jDD');
          setValue1(jalaliDate);
        } else {
          setValue1(null);
        }
    
        validation.setFieldValue('FirstVisit', dateString);
      }}
      name="FirstVisit"
    />
    {validation.touched.FirstVisit && validation.errors.FirstVisit ? (
      <div className="text-red-500">{validation.errors.FirstVisit}</div>
    ) : null}
  </div>
</div>
         
          <div className="grid grid-cols-1 gap-3 mb-4 md:grid-cols-3 lg:grid-cols-3">
            <div className="mb-6 col-md-4">
              <label
                htmlFor="dateInput"
                className="inline-block mb-2 text-base font-medium"
              >
                مرحله ساخت
              </label>
              <Select
                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                data-choices
                name="ConstructionStage"
                id="ConstructionStage"
                options={DefaultOptions1}
                value={
                  DefaultOptions1.find(
                    (option) =>
                      option.value === validation.values.ConstructionStage
                  ) || null
                } // Provide a default value if find() returns undefined
                onChange={handleSelectChange}
                onBlur={() =>
                  validation.setFieldTouched("ConstructionStage", true)
                }
                // Additional props required by the Select component
              />
              {validation.touched.ConstructionStage &&
              validation.errors.ConstructionStage ? (
                <p className="text-red-400">
                  {validation.errors.ConstructionStage}
                </p>
              ) : null}
            </div>
            <div>
              <label
                htmlFor="phoneNumberInput"
                className="inline-block mb-2 text-base font-medium"
              >
                سمت کارفرما <span className="text-red-500"></span>
              </label>
              <input
                type="text"
                name="EmployerPosition"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="سمت کارفرما "
                value={validation.values.EmployerPosition}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              {validation.touched.EmployerPosition &&
              validation.errors.EmployerPosition ? (
                <div className="text-red-500">
                  {validation.errors.EmployerPosition}
                </div>
              ) : null}
            </div>
            <div>
              <label
                htmlFor="CargoElevator"
                className="inline-block mb-2 text-base font-medium"
              >
                آسانسور باربری<span className="text-red-500"></span>
              </label>
              <input
                type="checkbox"
                name="CargoElevator"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="آسانسور باربری"
                value={validation.values.CargoElevator}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                checked={validation.values.CargoElevator}
              />
              {validation.touched.CargoElevator &&
              validation.errors.CargoElevator ? (
                <div className="text-red-500">
                  {validation.errors.CargoElevator}
                </div>
              ) : null}
            </div>
            <div className="mb-6 col-md-4">
              <label
                htmlFor="Representative"
                className="inline-block mb-2 text-base font-medium"
              >
                معرف<span className="text-red-500"></span>
              </label>
              <input
              type="text"
              id="Reperesentive"
              name="Reperesentive"

              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              disabled
              value={validation.values.Reperesentive}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
              
             
            </div>
          </div>
          
          <div className="flex justify-between gap-2 mt-5">
          <button
            type="submit"
            data-action="next"
            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
          >
            <span className="align-middle">ویرایش پروژه</span>{" "}
            <MoveRight className="inline-block h-4 ml-1 rtl:rotate-180" />
          </button>
        </div>
        </form>
         
        <form
        action="#!"
        onSubmit={(e) => {
          e.preventDefault();
          validation2.handleSubmit();
          return false;
        }}
      >
        <div className="card">
        
            <div className="card-body">
              <h6 className="mb-4 text-15">آپلود عکس های پروژه</h6>
              <div className="flex items-center justify-center bg-white border border-dashed rounded-md cursor-pointer dropzone border-slate-300 dropzone2 dark:bg-zink-700 dark:border-zink-500">
              <input
              type="file"
              id="Files"
              name="Files"
              className="form-input border-slate-200 input-field dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              multiple
              accept='image/*'
             
               onChange = {(event) => {
                const files = event.target.files;
                const updatedFiles = files ? Array.from(files) : [];
                validation2.setFieldValue('Files', updatedFiles);
              }}

              onBlur={validation2.handleBlur}
            />
           
        
            {validation2.touched.Files && validation2.errors.Files ? (
              <div id="Files" className="text-red-500">
                {validation2.errors.Files}
              </div>
            ) : null}
              </div>
              <div>
   
    </div>
    <button
            type="submit"
            data-action="next"
            
            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
          >
            <span className="align-middle">اضافه کردن عکس پروژه </span>
           
          </button>
            </div>

            <nav className="navbar navbar-dark bg-dark">
            <h6 className="mb-4 text-15"> عکس های پروژه</h6>
      </nav>
      
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {!loading3 &&
          data3.files.map((img:any, index) => {

            // استخراج نام فایل بدون پسوند
              const fileNameWithoutExtension = img.name.split('.').slice(0, -1).join('.');
              // تبدیل نام فایل به فرمت png
              const pngFileName = `${fileNameWithoutExtension}.png`;

              
        // ایجاد آرایه images
        const images = data3.files.map((img:any) => {
          const fileNameWithoutExtension = img.name.split('.').slice(0, -1).join('.');
          return `https://chekadbamapi.ir/Files/Images/ProjectThumb/${fileNameWithoutExtension}.png`;
        });

                    return (
                      <div key={img.id} className="col-lg-4 col-md-4 col-sm-12 p-2 project-item">
                    <div className={styles.projectitem} >
                        <img 
                          src={`https://chekadbamapi.ir/Files/Images/ProjectThumb/${pngFileName}`}
                          alt={`https://chekadbamapi.ir/Files/Images/ProjectThumb/${pngFileName}`}
                          height="200"
                          width="350"
                          className="h-auto max-w-full rounded-lg"
                          onClick={() => {
                    setIsOpen(true);
                    setPhotoIndex(index);
                  }}
                      />
                      <button className={styles.deleteButton}   onClick={() => handleDeletImg(img.id)} >
                        <i className="fas fa-trash"></i>
                      </button>
                  </div>
                </div>

              
            );
          })}
              
 
              {isOpen && images.length > 0 && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
     


          </div>


          



          </div>
       
          </form>
      <ToastContainer theme="colored" />
    </React.Fragment>
  );
};

export default FormEdit;
