import {
  CalendarDays,
  Codesandbox,
  MonitorDot,
  PictureInPicture2,
  UserRound,
} from "lucide-react";

const menuDataAccountant: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "dashboard",
    label: "Dashboards",
    link: "/Accountant/dashboard",
    icon: <MonitorDot />,
  },
  {
    id: "Projects",
    label: "project Page",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "projectList",
        label: "لیست پروژه‌ها",
        link: "/accountant/project/list",
        parentId: "Projects",
      },
    ],
  },
  {
    id: "Contracts",
    label: "قراردادها",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "projectList",
        label: "قراداد اجرا",
        link: "/accountant/UnderConstruction",
        parentId: "Contracts",
      },
      {
        id: "projectList",
        label: "قراداد طراحی",
        link: "/accountant/UnderConstruction",
        parentId: "Contracts",
      },
    ],
  },
  {
    id: "requestWork",
    label: "جبهه های کاری",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "requestWork",
        label: "لیست",
        link: "/accountant/requestwork/list",
        parentId: "requestWork",
      },
    ],
  },
  {
    id: "Projects",
    label: "پرسنل اجرا",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "projectList",
        label: "لیست پرسنل",
        link: "/accountant/workers",
        parentId: "Projects",
      },
    ],
  },
  {
    label: "حساب",
    isTitle: true,
  },

  {
    id: "tickets",
    label: "Tickets",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "submitTicket",
        label: "Submit Ticket",
        link: "/accountant/UnderConstruction",
        parentId: "landing",
      },
      {
        id: "ticketList",
        label: "Ticket List",
        link: "/accountant/UnderConstruction",
        parentId: "landing",
      },
    ],
  },
  {
    id: "account",
    label: "Account",
    link: "#",
    icon: <CalendarDays />,
    subItems: [
      {
        id: "accountSetting",
        label: "Account Setting",
        link: "/accountant/account/setting",
        parentId: "account",
      },
      {
        id: "accountSetting",
        label: "تغییر پسورد",
        link: "/accountant/UnderConstruction",
        parentId: "account",
      },
    ],
  },
  {
    label: "Pages",
    isTitle: true,
  },

  {
    id: "pages",
    label: "Pages",
    icon: <Codesandbox />,
    parentId: 2,
    subItems: [
      {
        id: "faq",
        label: "FAQs",
        link: "/accountant/pages-faqs",
        parentId: "pages",
      },
      {
        id: "contactus",
        label: "Contact US",
        link: "/accountant/pages-contact-us",
        parentId: "pages",
      },
    ],
  },
];

export { menuDataAccountant };
