// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_DETAILEPROJECT_TEAMHEAD } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";
import { number } from "yup";

const api = new APIClient();

interface Project {
 
  id: number;

  address: string;
  city: string;
  state: string;
  buildingType: string;
  numberOfFloor: number;
  projectType: string;
  callTime: Date;
  firstVisit: Date;
  constructionStage: string;
  employerPosition: string;
  representative: number;
  areaSize:number;
  customerName:string;
  customerId:number;
  cargoElevator:boolean;
  customerNumber:number,
  reperesentive : string;
}


let val: Project;

const useFetchData = (id:any) => {
  const [data1, setData] = useState(val);
  const [loading1, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api.get(GET_DETAILEPROJECT_TEAMHEAD, { projectId: id }).then((response) => {
          setData(JSON.parse(JSON.stringify(response)));
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  return {
    data1,
    loading1,
  };
};

export default useFetchData;
