// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_ADMIN_ROLELIST } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface Role {
  id: number;
  name: string;
}

interface Roles {
  roles: Role[];
}

let val: Roles;

const useFetchRoles = () => {
  const [roles, setRoles] = useState(val);
  const [loadingRoles, setLoadingRoles] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api.get(GET_ADMIN_ROLELIST, null).then((response) => {
          setRoles(JSON.parse(JSON.stringify(response)));
          setLoadingRoles(false);
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return {
    roles,
    loadingRoles,
  };
};

export default useFetchRoles;
