import { POST_LOGIN } from "helpers/url_helper";
import { loginError, loginSuccess, logoutSuccess } from "./reducer";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import { RootState } from "slices";
import axios from "axios";
import { userService } from "../../../helpers/user";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface User {
  phone: string;
  password: string;
  rememberM: boolean;
}

export const loginUser =
  (user: User): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch: Dispatch) => {
    try {
      var responseJson: any;
      await axios
        .post(POST_LOGIN, user)
        .then((response) => {
          let jsonResultString = JSON.stringify(response);
          responseJson = JSON.parse(jsonResultString);
          localStorage.setItem("accessToken", responseJson.accessToken);
          localStorage.setItem("refreshToken", responseJson.accessToken);
        })
        .catch(function (error) {
          console.log(error);
          toast.error("Login Faild!");
        });

      if (responseJson) {
        dispatch(loginSuccess(responseJson.accessToken));

        if (userService.isAuthUserInRoles(["administrators"])) {
          window.location.replace("/admin/dashboard");
        } else if (userService.isAuthUserInRoles(["designer"])) {
          window.location.replace("/designer/dashboard");
        } else if (userService.isAuthUserInRoles(["teamHead"])) {
          window.location.replace("/teamHead/dashboard");
        } else if (userService.isAuthUserInRoles(["accountant"])) {
          window.location.replace("/accountant/dashboard");
        } else if (userService.isAuthUserInRoles(["warehouse"])) {
          window.location.replace("/warehouse/dashboard");
        } else if (userService.isAuthUserInRoles(["worker"])) {
          window.location.replace("/worker/dashboard");
        } else if (userService.isAuthUserInRoles(["manager"])) {
          window.location.replace("/manager/dashboard");
        } else if (userService.isAuthUserInRoles(["technicalDeputy"])) {
          window.location.replace("/technicalDeputy/dashboard");
        } else if (userService.isAuthUserInRoles(["customer"])) {
          window.location.replace("/Customers/dashboard");
        }
      }
    } catch (error) {
      dispatch(loginError(error));
    }
  };

export const logoutUser = () => async (dispatch: Dispatch) => {
  try {
    localStorage.removeItem("accessToken");
    dispatch(logoutSuccess(true));
  } catch (error) {
    dispatch(loginError(error));
  }
};

export const socialLogin =
  (type: any, history: any) => async (dispatch: any) => {
    try {
      let response: any;
      const socialData = await response;

      if (socialData) {
        sessionStorage.setItem("accessToken", JSON.stringify(socialData));
        dispatch(loginSuccess(socialData));
        history("dashboard");
      }
    } catch (error) {
      dispatch(loginError(error));
    }
  };
