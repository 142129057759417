// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_PROJECT_PROCESS } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface ProjectProcesses {
  note: string;
  isDone: boolean;
  projectId: number;
  createDate: Date;
  endDate: Date;
  memberId: number;
  teamName: string;
}

interface MyData {
  projectProcesses: ProjectProcesses[];
}

let val: MyData;

const useFetchDataProcess = (projectId: any) => {
  const [data, setData] = useState<MyData | null>(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await api
          .get(GET_PROJECT_PROCESS,   { id: projectId } )
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
         console.error("Error fetching data:", error);
        
        setLoading(false);
      }
    };

    fetchData();
  }, [projectId]);

  return {
    data,
    loading,
  };
};

export default useFetchDataProcess;
