import React from "react";
import { Search } from "lucide-react";

// Image
import faq from "assets/images/faq.png";

const FaqSearch = () => {
  return (
    <React.Fragment>
      <div className="relative overflow-hidden card">
        <div className="p-8">
          <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
            <div className="xl:col-span-7">
              <h4 className="mb-1">در حال ساخت</h4>
            </div>
            <div className="hidden xl:col-span-3 xl:col-start-10 xl:block">
              <img
                src={faq}
                alt=""
                className="absolute h-[500px] -rotate-45 -top-28 ltr:right-8 rtl:left-8"
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FaqSearch;
