// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_CUSTOMER_PROFILE } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface Profiles {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  address: string;
  state: string;
  city: string;
  country: string;
  result: number;
  messages: [];
}



let val: (Profiles);

const useFetchData = () => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
         await api.get(GET_CUSTOMER_PROFILE, null).then((response) => {
          setData(JSON.parse(JSON.stringify(response)));
          setLoading(false);
          // console.log(setData);
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
