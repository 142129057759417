import {
  CalendarDays,
  Codesandbox,
  MonitorDot,
  PictureInPicture2,
  UserRound,
} from "lucide-react";

const menuDataTeamHead: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "dashboard",
    label: "Dashboards",
    link: "/TeamHead/dashboard",
    icon: <MonitorDot />,
  },
  {
    id: "Projects",
    label: "project Page",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "orderProject",
        label: "Order Project",
        link: "/TeamHead/Project/create",
        parentId: "landing",
      },
      {
        id: "projectList",
        label: "لیست پروژه ها",
        link: "/TeamHead/Project/list",
        parentId: "landing",
      },
    ],
  },
  {
    id: "contracts",
    label: "قراردادها",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "exeContract",
        label: "Exe Contract",
        link: "/TeamHead/exeContract",
        parentId: "landing",
      },
      {
        id: "designContract",
        label: "Design Contract",
        link: "/TeamHead/designContract",
        parentId: "landing",
      },
    ],
  },
  {
    id: "tickets",
    label: "Tickets",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "submitTicket",
        label: "Submit Ticket",
        link: "/TeamHead/tickets/submitticket",
        parentId: "landing",
      },
      {
        id: "ticketList",
        label: "Ticket List",
        link: "/TeamHead/tickets/ticketlist",
        parentId: "landing",
      },
    ],
  },
  {
    id: "Accounting",
    label: "Accounting",
    link: "/TeamHead/accounting",
    icon: <PictureInPicture2 />,
  },
  {
    id: "users",
    label: "تنظیمات اکانت",
    icon: <UserRound />,
    parentId: 2,
    subItems: [
      {
        id: "تنظیمات اکانت",
        label: "اکانت",
        link: "/TeamHead/user-profile",
        parentId: "users",
      },
    ],
  },

  {
    id: "customers",
    label: "ثبت مشتری",
    icon: <UserRound />,
    parentId: 2,
    subItems: [
      {
        id: "create customer",
        label: "ساخت حساب مشتری",
        link: "/TeamHead/createCustomer",
        parentId: "users",
      },
    ],
  },

  {
    id: "pages",
    label: "Pages",
    icon: <Codesandbox />,
    parentId: 2,
    subItems: [
      {
        id: "faq",
        label: "FAQs",
        link: "/pages-faqs",
        parentId: "pages",
      },
      {
        id: "contactus",
        label: "Contact US",
        link: "/pages-contact-us",
        parentId: "pages",
      },
    ],
  },
];

export { menuDataTeamHead };
