import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  authProtectedRoutesAdmin,
  publicRoutes,
  authProtectedRoutes,
  authProtectedRoutesCustomer,
  authProtectedRoutesTechnicalDeputy,
  authProtectedRoutesManger,
  authProtectedRoutesTeamHead,
  authProtectedRoutesDesigner,
  authProtectedRoutesWorker,
  authProtectedRoutesWarehouse,
  authProtectedRoutesAccountant,
} from "./allRoutes";
import Layout from "Layout";
import NonAuthLayout from "Layout/NonLayout";
import AuthProtected from "./AuthProtected";

const RouteIndex = () => {
  return (
    <React.Fragment>
      <Routes>
        {authProtectedRoutesAdmin.map((route: any, idx: number) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthProtected permission={{ allowedRoles: ["administrators"] }}>
                <Layout>
                  <route.component />
                </Layout>
              </AuthProtected>
            }
          />
        ))}
        {authProtectedRoutesAccountant.map((route: any, idx: number) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthProtected permission={{ allowedRoles: ["accountant"] }}>
                <Layout>
                  <route.component />
                </Layout>
              </AuthProtected>
            }
          />
        ))}
        {authProtectedRoutesWarehouse.map((route: any, idx: number) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthProtected permission={{ allowedRoles: ["warehouse"] }}>
                <Layout>
                  <route.component />
                </Layout>
              </AuthProtected>
            }
          />
        ))}
        {authProtectedRoutesWorker.map((route: any, idx: number) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthProtected permission={{ allowedRoles: ["worker"] }}>
                <Layout>
                  <route.component />
                </Layout>
              </AuthProtected>
            }
          />
        ))}
        {authProtectedRoutesManger.map((route: any, idx: number) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthProtected permission={{ allowedRoles: ["manager"] }}>
                <Layout>
                  <route.component />
                </Layout>
              </AuthProtected>
            }
          />
        ))}
        {authProtectedRoutesTechnicalDeputy.map((route: any, idx: number) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthProtected permission={{ allowedRoles: ["technicalDeputy"] }}>
                <Layout>
                  <route.component />
                </Layout>
              </AuthProtected>
            }
          />
        ))}
        {authProtectedRoutesDesigner.map((route: any, idx: number) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthProtected permission={{ allowedRoles: ["designer"] }}>
                <Layout>
                  <route.component />
                </Layout>
              </AuthProtected>
            }
          />
        ))}
        {authProtectedRoutesTeamHead.map((route: any, idx: number) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthProtected permission={{ allowedRoles: ["teamHead"] }}>
                <Layout>
                  <route.component />
                </Layout>
              </AuthProtected>
            }
          />
        ))}{" "}
        {authProtectedRoutesCustomer.map((route: any, idx: number) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthProtected permission={{ allowedRoles: ["customer"] }}>
                <Layout>
                  <route.component />
                </Layout>
              </AuthProtected>
            }
          />
        ))}
        {authProtectedRoutes.map((route: any, idx: number) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthProtected permission={{ allowedRoles: ["administrators"] }}>
                <Layout>
                  <route.component />
                </Layout>
              </AuthProtected>
            }
          />
        ))}
        {publicRoutes.map((route: any, idx: number) => (
          <Route
            path={route.path}
            key={idx}
            element={
              <NonAuthLayout>
                <route.component />
              </NonAuthLayout>
            }
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

export default RouteIndex;
