import React from "react";
import useFetchData from "./FetchData";
import { useNavigate } from "react-router-dom";
import moment from "moment-jalaali";

const TableRequestWork = () => {
  let x = 1;
  let { data, loading } = useFetchData();

  const history = useNavigate();

  const handleNavigationToWorkOfRequestWork = (id: any) => {
    history(`/accountant/requestwork/listofwork?reqWorkId=${id}`);
  };

  const handleNavigationToMemberOfRequestWork = (id: any) => {
    history(`/accountant/requestwork/listofmember?reqWorkId=${id}`);
  };

  const handleNavigationToRequestWorkInvoices = (id: any) => {
    history(`/accountant/requestwork/invoices?reqWorkId=${id}`);
  };

  if (loading) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-12 text-15">
            جبهه‌های کاری پروژه کد شماره{" "}
            {data.reqWorks[0].projectCode !== undefined
              ? data.reqWorks[0].projectCode
              : ""}
          </h6>

          <div className="overflow-x-auto">
            <table className="w-full bg-custom-50 dark:bg-custom-500/10">
              <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                <tr>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    ردیف
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    تاریخ
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    کل مبلغ
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    توضیحات
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    مدیر اجرا
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    وضعیت
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    وضعیت اجرا
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    عملیات
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr> */}
                {!loading &&
                  data.reqWorks.map((reqWork) => (
                    <tr key={reqWork.id}>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <a
                          href="#!" // Use downloadLink if available, otherwise fallback to "#"
                          className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                        >
                          {x++}
                        </a>
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {reqWork && reqWork.date
                          ? moment(reqWork.date, "YYYY-MM-DD").format(
                              "jYYYY/jMM/jDD"
                            )
                          : "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {new Intl.NumberFormat("en-US").format(
                          reqWork.wholeAmount
                        ) || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {reqWork.note || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {reqWork.projectManagerName || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {reqWork.approved ? (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-custom-500 border-custom-500 text-custom-50">
                            Approved
                          </span>
                        ) : (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-red-500 border-red-500 text-red-50">
                            Not Approved
                          </span>
                        )}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {reqWork.finished ? (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-custom-500 border-custom-500 text-custom-50">
                            Finished
                          </span>
                        ) : (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-red-500 border-red-500 text-red-50">
                            Not Finished
                          </span>
                        )}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "grey",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                            marginRight: "3px",
                            marginTop: "3px",
                          }}
                          onClick={() =>
                            handleNavigationToMemberOfRequestWork(reqWork.id)
                          }
                          className="btn btn-primary"
                        >
                          اعضا
                        </button>
                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "grey",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                            marginRight: "3px",
                            marginTop: "3px",
                          }}
                          onClick={() =>
                            handleNavigationToWorkOfRequestWork(reqWork.id)
                          }
                          className="btn btn-primary"
                        >
                          کارها
                        </button>
                        {reqWork.finished ? (
                          <button
                            style={{
                              display: "inline-block",
                              backgroundColor: "blue",
                              color: "white",
                              padding: "10px 20px 10px",
                              border: "none",
                              marginRight: "3px",
                              marginTop: "3px",
                            }}
                            onClick={() =>
                              handleNavigationToRequestWorkInvoices(reqWork.id)
                            }
                            className="btn btn-primary"
                          >
                            فاکتورها
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TableRequestWork;
