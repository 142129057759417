import React from "react";
import { MoveRight } from "lucide-react";
import { useFormik } from "formik";
import { APIClient } from "helpers/api_helper";

import * as Yup from "yup";
import { POST_REGISTERCUSTOMER_TEAMHEAD } from "helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const api = new APIClient();

const Form = () => {
  const validation: any = useFormik({
    initialValues: {
      mobile: "",
      password: "",
      name: "",
      family: "",
    },
    validationSchema: Yup.object().shape({
      mobile: Yup.number().required("mobile Name is required"),
      password: Yup.string().required("password is required."),
      name: Yup.string().required("name is required."),
      family: Yup.string().required("family is required."),
    }),
    onSubmit: async (values: any) => {
      try {
        console.log(values);
        await api.create(POST_REGISTERCUSTOMER_TEAMHEAD, values);
        toast.info("Added to Server");
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error");
      }
    },
  });

  return (
    <React.Fragment>
      <h5 className="mb-3">ایجاد حساب مشتری</h5>
      <form
        action="#!"
        onSubmit={(event: any) => {
          event.preventDefault();
          validation.handleSubmit();
        }}
      >
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="mobile"
              className="inline-block mb-2 text-base font-medium"
            >
              شماره تلفن <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="mobile"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="Enter mobile"
              value={validation.values.mobile}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.mobile && validation.errors.mobile ? (
              <div id="mobile" className="text-red-500">
                {validation.errors.mobile}
              </div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="password"
              className="inline-block mb-2 text-base font-medium"
            >
              رمز <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="password"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="password"
              value={validation.values.password}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.password && validation.errors.password ? (
              <div className="text-red-500">{validation.errors.password}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="name"
              className="inline-block mb-2 text-base font-medium"
            >
              نام
            </label>
            <input
              type="text"
              name="name"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="name"
              value={validation.values.name}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.name && validation.errors.name ? (
              <div className="text-red-500">{validation.errors.name}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="family"
              className="inline-block mb-2 text-base font-medium"
            >
              نام خانوادگی
            </label>
            <input
              type="text"
              name="family"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="family"
              value={validation.values.family}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.family && validation.errors.family ? (
              <div className="text-red-500">{validation.errors.family}</div>
            ) : null}
          </div>
        </div>

        <div className="flex justify-between gap-2 mt-5">
          <button
            type="submit"
            data-action="next"
            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
          >
            <span className="align-middle">Submit</span>{" "}
            <MoveRight className="inline-block h-4 ml-1 rtl:rotate-180" />
          </button>
        </div>
      </form>
      <ToastContainer theme="colored" />
    </React.Fragment>
  );
};

export default Form;
