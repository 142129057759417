import React from "react";
import { MoveLeft, MoveRight, Gem, Smartphone, Globe } from "lucide-react";
import { useFormik } from "formik";
import Select from "react-select";
import { APIClient } from "helpers/api_helper";
// import IranCitiesProvincesSelect  form "react-iran-provinces-cities-select";
import * as Yup from "yup";
import useFetchData from "./FetchData";
import { withTranslation } from "react-i18next";
import {POST_CUSTOMER_PROJECT_ORDER} from "helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const api = new APIClient();

interface Options {
  label: string;
  value?: string;
  isDisabled?: boolean;
  options?: Options[];
}

const AccountInfo = (props: any) => {
  const DefaultOptions: Options[] = [];

  let { data, loading } = useFetchData();

  if (!loading)
    data.customers.map((customer: any) => {
      const newOption: Options = {
        label: customer.fullName,
        value: customer.id,
      };
      DefaultOptions.push(newOption);
    });

  const validation: any = useFormik({
    initialValues: {
      Address : "",
      City : "",
      Country : "",
      BuildingType : "مسکونی",
      NumberOfFloor :"",
      ProjectType  :"روف گاردن",
      AreaSize  :"",
      CustomerId   :"",
    },
    validationSchema: Yup.object().shape({
      Address: Yup.string().required("Address is required"),
      Country: Yup.string().required("Country is required."),
      BuildingType: Yup.string().required("BuildingType is required."),
      city: Yup.string().required("city is required."),
      NumberOfFloor: Yup.string().required("NumberOfFloor is required."),
      ProjectType: Yup.string().required("ProjectType is required."),
      AreaSize: Yup.string().required("AreaSize is required."),
      
    }),
    onSubmit: async (values: any) => {
      try {
        console.log(values);
        await api.create(POST_CUSTOMER_PROJECT_ORDER, values);
        toast.info("Added to Server");
      }
       catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error");
      }
    },
  });



  return (
    <React.Fragment>
      <h5 className="mb-3">ایجاد پروژه</h5>
      <form
        action="#!"
        onSubmit={(event: any) => {
          event.preventDefault();
          validation.handleSubmit();
        }}
      >
        <label
          htmlFor="projectType"
          className="inline-block mb-3 text-base font-medium"
        >
          انتخاب نوع پروژه" <span className="text-red-500">*</span>
        </label>
        <div
          id="projectTypegroup"
          className="grid grid-cols-1 gap-3 mb-4 md:grid-cols-3 lg:grid-cols-3"
        >
          <div>
            <input
              id="brandingRadio"
              name="projectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              value="roof garden"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              defaultChecked
            />
            <label
              htmlFor="brandingRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Gem className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">روف گاردن</span>
            </label>
          </div>
          <div>
            <input
              id="mobileDesignRadio"
              name="projectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="green wall"
            />
            <label
              htmlFor="mobileDesignRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Smartphone className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">دیوار سبز</span>
            </label>
          </div>

          <div>
            <input
              id="webDesignRadio"
              name="projectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="green trace"
            />
            <label
              htmlFor="webDesignRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">تراس سبز</span>
            </label>
          </div>
        </div>

        <label
          htmlFor="buildingType"
          className="inline-block mb-3 text-base font-medium"
        >
          کاربری <span className="text-red-500">*</span>
        </label>
        <div
          id="buildingTypegroup"
          className="grid grid-cols-1 gap-3 mb-4 md:grid-cols-3 lg:grid-cols-3"
        >
          <div>
            <input
              id="buildingType"
              name="buildingType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              value="roof garden"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              defaultChecked
            />
            <label
              htmlFor="businessRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Gem className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">تجاری</span>
            </label>
          </div>

          <div>
            <input
              id="officeRadio"
              name="buildingType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="green wall"
            />
            <label
              htmlFor="officeRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Smartphone className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">اداری</span>
            </label>
          </div>

          <div>
            <input
              id="residentialRadio"
              name="buildingType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="green trace"
            />
            <label
              htmlFor="residentialRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">مسکونی</span>
            </label>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
    
          <div>
            <label
              htmlFor="cityInput"
              className="inline-block mb-2 text-base font-medium"
            >
              شهر <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="city"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="Enter city"
              value={validation.values.city}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.city && validation.errors.city ? (
              <div id="city" className="text-red-500">
                {validation.errors.city}
              </div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="address"
              className="inline-block mb-2 text-base font-medium"
            >
              آدرس <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              
              id="Address"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="Adreess"
              value={validation.values.Address}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.Address && validation.errors.Address ? (
              <div id="city" className="text-red-500">
                {validation.errors.Address}
              </div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="countryInput"
              className="inline-block mb-2 text-base font-medium"
            >
              کشور
            </label>
            <input
              type="text"
              id="Country"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="Country"
              value={validation.values.Country}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.Country && validation.errors.Country ? (
              <div className="text-red-500">{validation.errors.Country}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="numberOfFloorInput"
              className="inline-block mb-2 text-base font-medium"
            >
              شماره طبقه
            </label>
            <input
              type="number"
              name="NumberOfFloor"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="Number Of Floor"
              value={validation.values.NumberOfFloor}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.NumberOfFloor &&
            validation.errors.NumberOfFloor ? (
              <div className="text-red-500">
                {validation.errors.NumberOfFloor}
              </div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="areaSizeInput"
              className="inline-block mb-2 text-base font-medium"
            >
              متراژ
            </label>
            <input
              type="number"
              name="AreaSize"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="Area Size"
              value={validation.values.areaSize}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.AreaSize && validation.errors.AreaSize ? (
              <div className="text-red-500">{validation.errors.AreaSize}</div>
            ) : null}
          </div>
        </div>

        <div className="flex justify-between gap-2 mt-5">
          <button
            type="submit"
            data-action="next"
            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
          >
            <span className="align-middle">ارسال</span>{" "}
            <MoveRight className="inline-block h-4 ml-1 rtl:rotate-180" />
            
          </button>
          
        </div>
      </form>
      <ToastContainer theme="colored" />
    </React.Fragment>
  );
};

export default withTranslation()(AccountInfo);
