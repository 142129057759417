import React from "react";
import { MoveRight } from "lucide-react";
import { useFormik } from "formik";
import { APIClient } from "helpers/api_helper";

import * as Yup from "yup";
import { POST_TEAMHEADٍCREATEIMPLIMENTCONTRACT_PROJECT } from "helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "zaman";
import styles from "./mystyle.module.css";

const api = new APIClient();

function getParameterByName(name: any, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const Form = () => {
  var projectId = getParameterByName("projectId");

  const validation: any = useFormik({
    initialValues: {
      //potNumber: "",
      contractNumber: "",
      date: "",
      //price: 0,
      // description: "",
    },
    validationSchema: Yup.object().shape({
      // potNumber: Yup.string().required("potNumber Name is required"),
      contractNumber: Yup.string().required("شماره قرارداد اجباری است."),
      // price: Yup.number().required("قیمت را وارد کنید"),
      //description: Yup.string().required("توضیحات را وارد کنید"),
      date: Yup.string().required("تاریخ را وارد کنید"),
    }),
    onSubmit: async (values: any) => {
      try {
        // console.log(values);
        const data = { projectId: Number(projectId), ...values };
        console.log(data);
        await api.create(POST_TEAMHEADٍCREATEIMPLIMENTCONTRACT_PROJECT, data);
        toast.info("Added to Server");
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error");
      }
    },
  });

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h5 className="mb-3">ایجاد قرارداد اجرا </h5>
          <form
            action="#!"
            onSubmit={(event: any) => {
              event.preventDefault();
              validation.handleSubmit();
            }}
          >
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              {/* <div>
                <label
                  htmlFor="potNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  potNumber <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="potNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter potNumber"
                  value={validation.values.potNumber}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.potNumber && validation.errors.potNumber ? (
                  <div id="potNumber" className="text-red-500">
                    {validation.errors.potNumber}
                  </div>
                ) : null}
              </div> */}
              <div>
                <label
                  htmlFor="contractNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  شماره قرارداد <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="contractNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder=" شماره قرارداد "
                  value={validation.values.contractNumber}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.contractNumber &&
                validation.errors.contractNumber ? (
                  <div className="text-red-500">
                    {validation.errors.contractNumber}
                  </div>
                ) : null}
              </div>
              {/* <div>
                <label
                  htmlFor="priceInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  قیمت
                </label>
                <input
                  type="number"
                  name="price"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="قیمت"
                  value={validation.values.price}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.price && validation.errors.price ? (
                  <div className="text-red-500">{validation.errors.price}</div>
                ) : null}
              </div> */}
              <div>
                <label
                  htmlFor="numberOfFloorInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  تاریخ
                </label>
                <DatePicker
                  className={styles.bigblue}
                  round="x4"
                  position="center"
                  onChange={(newDate) => {
                    // console.log(newDate);
                    // Ensure the date is always formatted as a string before updating the state
                    let dateString = "";
                    if (newDate && newDate.value) {
                      // Assuming newDate.value is a date string or a timestamp
                      dateString = new Date(newDate.value).toISOString();
                    }
                    validation.setFieldValue("date", dateString);
                  }}
                />
                {validation.touched.date && validation.errors.date ? (
                  <div className="text-red-500">{validation.errors.date}</div>
                ) : null}
              </div>
              {/* <div>
                <label
                  htmlFor="descriptionInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  توضیحات
                </label>
                <textarea
                  name="description"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="توضیحات"
                  value={validation.values.description}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <div className="text-red-500">
                    {validation.errors.description}
                  </div>
                ) : null}
              </div> */}
            </div>

            <div className="flex justify-between gap-2 mt-5">
              <button
                type="submit"
                data-action="next"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                <span className="align-middle">ارسال</span>{" "}
                <MoveRight className="inline-block h-4 ml-1 rtl:rotate-180" />
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer theme="colored" />
    </React.Fragment>
  );
};

export default Form;
