import useFetchData from "./FetchData";
import { useNavigate } from "react-router-dom";

//شمسی
import moment from "moment-jalaali";
import React from "react";

const TableProjects = () => {
  let x = 1;
  let { data, loading } = useFetchData();

  const history = useNavigate();

  const handleNavigationReqWork = (id: any) => {
    history(`/accountant/project/requestwork?projectId=${id}`);
  };

  const handleNavigationProduct = (id: any) => {
    history(`/accountant/project/product?projectId=${id}`);
  };

  if (loading) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-12 text-15">لیست پروژه‌ها</h6>

          <div className="overflow-x-auto">
            <table className="w-full bg-custom-50 dark:bg-custom-500/10">
              <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                <tr>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    row
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    تاریخ
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    نام تیم
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    نام کارفرما
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    شهر
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    معاون فنی
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    وضعیت
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr> */}
                {!loading &&
                  data.projects.map((project) => (
                    <tr key={project.id}>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <a
                          href="#!" // Use downloadLink if available, otherwise fallback to "#"
                          className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                        >
                          {x++}
                        </a>
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project && project.createdDate
                          ? moment(project.createdDate, "YYYY-MM-DD").format(
                              "jYYYY/jMM/jDD"
                            )
                          : "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.teamName || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.customerName || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.city || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.approvedByTechnicalDeputy ? (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-custom-500 border-custom-500 text-custom-50">
                            تایید شده
                          </span>
                        ) : (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-red-500 border-red-500 text-red-50">
                            عدم تایید
                          </span>
                        )}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {project.finished ? (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-green-500 border-custom-500 text-custom-50">
                            پایان یافته
                          </span>
                        ) : (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-orange-500 border-red-500 text-red-50">
                            در حال پردازش
                          </span>
                        )}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "blue",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                            marginRight: "3px",
                            marginTop: "3px",
                          }}
                          onClick={() => handleNavigationReqWork(project.id)}
                          className="btn btn-primary"
                        >
                          جبهه های کاری
                        </button>
                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "blue",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                            marginRight: "3px",
                            marginTop: "3px",
                          }}
                          onClick={() => handleNavigationProduct(project.id)}
                          className="btn btn-primary"
                        >
                          محصولات
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TableProjects;
