import React from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Rocket } from "lucide-react";
import Tab from "Common/Components/Tab/Tab";
import { Nav } from "Common/Components/Tab/Nav";
import Form from "./Form";

const FormWizard = () => {
  return (
    <React.Fragment>
      <div className="container-fluid group-data-[content=boxed]:max-w-boxed mx-auto">
        <BreadCrumb title="Create Project" pageTitle="Project" />

        <div className="grid grid-cols-1 2xl:grid-cols-12">
          <div className="col-span-12 2xl:col-start-2 2xl:col-span-10">
            <div className="card">
              <div className="card-body">
                <Tab.Container defaultActiveKey="accountInfo">
                  <Nav className="grid grid-cols-1 gap-3 lg:grid-cols-1 nav-tabs form-wizard">
                    <Nav.Item eventKey="accountInfo" className="group/item">
                      <a
                        href="#!"
                        data-target="accountInfo"
                        data-tab-id="1"
                        className="block px-4 py-5 text-center rounded-md text-slate-500 bg-slate-50 dark:text-zink-200 dark:bg-zink-600 group-[.active]/item:bg-custom-500 group-[.active]/item:text-custom-50"
                      >
                        <Rocket className="block h-6 mx-auto fill-slate-200 dark:fill-zink-400 group-[.active]/item:fill-slate-200 dark:group-[.active]/item:fill-custom-500" />
                        <span className="block mt-3 font-medium text-15">
                          Create Project
                        </span>
                      </a>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="mt-5 tab-content">
                    <Tab.Pane eventKey="accountInfo">
                      <Form />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FormWizard;
