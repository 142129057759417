import { useFormik } from "formik";
import { APIClient } from "helpers/api_helper";
import { CHANGE_PASSWORD } from "helpers/url_helper";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";

const api = new APIClient();

const ChangePasswordTabs = () => {
  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldpasswordInput2: "",
      NewpasswordInput: "",
    },
    validationSchema: Yup.object({
      oldpasswordInput2: Yup.string()
        .required(" رمز فعلی را واردکنید  ")
        .min(8, "Your password is too short."),
      NewpasswordInput: Yup.string()
        .required("رمز جدید را وارد کنید")
        .min(8, "حداقل 8 کاراکتر وارد کنید")
        .matches(/[a-z]/, "at least one lowercase char"),
      ConfirmpasswordInput: Yup.string()
        .required("رمز جدید را وارد کنید")
        .min(8, "حداقل 8 کاراکتر وارد کنید")
        .oneOf([Yup.ref("NewpasswordInput")], "پسورد یکسان وارد کنید"),
    }),

    onSubmit: async (values) => {
      try {
        const postData = {
          oldPassword: values.oldpasswordInput2,
          newPassword: values.NewpasswordInput,
        };
        // console.log(postData);
        await api.create(CHANGE_PASSWORD, postData);
        toast.info("پسورد با موفقیت تغییر کرد");
        // Reload the page to reflect changes
        // window.location.reload();
      } catch (error: any) {
        // Log the entire error object for debugging
        
        // Check if error response exists and contains data
        const errorResponse = error.response ? error.response.data : null;
        console.error("Error occurred:", error.response);
        if (errorResponse) {
          const errorCode = errorResponse.errorCode || "unknown";
          console.error("Error code:", errorResponse);
          
          switch (errorCode) {
            case "account.changepassword.errors.oldpassworddoesntmatch":
              toast.error("رمز عبور فعلی اشتباه است");
              break;
            case "account.changepassword.errors.passwordmatcheswithprevious":
              toast.error("رمز عبور جدید نباید با رمز عبور فعلی یکسان باشد");
              break;
            // default:
            //   toast.error("خطایی رخ داده است");
          }
        } else {
          toast.error("خطایی رخ داده است");
        }
      }
    },
  });

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-4 text-15">تغییر پسورد</h6>
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
              <div className="xl:col-span-4">
                <label
                  htmlFor="oldpasswordInput2"
                  className="inline-block mb-2 text-base font-medium"
                >
                  رمز فعلی *
                </label>
                <div className="relative">
                  <input
                    type="password"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    id="oldpasswordInput2"
                    name="oldpasswordInput2"
                    placeholder="رمز فعلی"
                    value={validation.values.oldpasswordInput2}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.oldpasswordInput2 &&
                  validation.errors.oldpasswordInput2 ? (
                    <div className="text-red-500">
                      {validation.errors.oldpasswordInput2}
                    </div>
                  ) : null}
                  <button
                    className="absolute top-2 ltr:right-4 rtl:left-4 "
                    type="button"
                  >
                    <i className="align-middle ri-eye-fill text-slate-500 dark:text-zink-200"></i>
                  </button>
                </div>
              </div>
              <div className="xl:col-span-4">
                <label
                  htmlFor="NewpasswordInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  {" "}
                  رمز جدید*
                </label>
                <div className="relative">
                  <input
                    type="password"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    id="NewpasswordInput"
                    name="NewpasswordInput"
                    placeholder="رمز جدید"
                    value={validation.values.NewpasswordInput}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.NewpasswordInput &&
                  validation.errors.NewpasswordInput ? (
                    <div className="text-red-500">
                      {validation.errors.NewpasswordInput}
                    </div>
                  ) : null}
                  <button
                    className="absolute top-2 ltr:right-4 rtl:left-4 "
                    type="button"
                  >
                    <i className="align-middle ri-eye-fill text-slate-500 dark:text-zink-200"></i>
                  </button>
                </div>
              </div>
              <div className="xl:col-span-4">
                <label
                  htmlFor="ConfirmpasswordInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  تایید رمز جدید*
                </label>
                <div className="relative">
                  <input
                    type="password"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    id="ConfirmpasswordInput"
                    name="ConfirmpasswordInput"
                    placeholder="تایید رمز جدید"
                    value={validation.values.ConfirmpasswordInput}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.ConfirmpasswordInput &&
                  validation.errors.ConfirmpasswordInput ? (
                    <div className="text-red-500">
                      {validation.errors.ConfirmpasswordInput}
                    </div>
                  ) : null}
                  <button
                    className="absolute top-2 ltr:right-4 rtl:left-4 "
                    type="button"
                  >
                    <i className="align-middle ri-eye-fill text-slate-500 dark:text-zink-200"></i>
                  </button>
                </div>
              </div>
              <div className="flex items-center xl:col-span-6">
                <a href="#!" className="underline text-custom-500 text-13">
                  Forgot Password ?
                </a>
              </div>
              <div className="flex justify-end xl:col-span-6">
                <button
                  type="submit"
                  className="text-white bg-green-500 border-green-500 btn hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100 dark:ring-green-400/10"
                >
                  ارسال
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer theme="colored" />
    </React.Fragment>
  );
};

export default ChangePasswordTabs;
