import {
  CalendarDays,
  Codesandbox,
  MonitorDot,
  PictureInPicture2,
  UserRound,
} from "lucide-react";

const menuDataTechnicalDeputy: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "dashboard",
    label: "Dashboards",
    link: "/TechnicalDeputy/dashboard",
    icon: <MonitorDot />,
  },
  {
    id: "Projects",
    label: "project Page",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "orderProject",
        label: "Order Project",
        link: "/TechnicalDeputy/Project/Order",
        parentId: "landing",
      },
      {
        id: "projectList",
        label: "لیست پروژه‌ها",
        link: "/TechnicalDeputy/Project/list",
        parentId: "Projects",
      },
    ],
  },
  {
    id: "contracts",
    label: "قراردادها",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "contract",
        label: "قراردادهای طراحی",
        link: "/TechnicalDeputy/contract/designcontract",
        parentId: "landing",
      },
      {
        id: "projectList",
        label: "قراردادهای اجرا",
        link: "/TechnicalDeputy/contract/execontract",
        parentId: "contract",
      },
    ],
  },
  {
    id: "requestworks",
    label: "درخواست های کار",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "requestwork",
        label: "لیست درخواست ها",
        link: "/TechnicalDeputy/requestwork/list",
        parentId: "requestworks",
      },
      {
        id: "requestwork",
        label: "لیست کارها",
        link: "/TechnicalDeputy/workactivity/list",
        parentId: "requestwork",
      },
      {
        id: "requestwork",
        label: "ایجاد کار",
        link: "/TechnicalDeputy/workactivity/Create",
        parentId: "requestwork",
      },
    ],
  },
  {
    id: "Accountant",
    label: "حسابداری",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "requestwork",
        label: "لیست فاکتورهای کارگران",
        link: "/TechnicalDeputy/UnderConstruction",
        parentId: "Accountant",
      },
      {
        id: "requestwork",
        label: "لیست تنخواه",
        link: "/TechnicalDeputy/UnderConstruction",
        parentId: "Accountant",
      },
    ],
  },
  {
    id: "Production",
    label: "تولبد",
    icon: <UserRound />,
    subItems: [
      {
        id: "",
        label: "محصولات",
        link: "/TechnicalDeputy/UnderConstruction",
        parentId: "Production",
      },
      {
        id: "",
        label: "درخواست تولید",
        link: "/TechnicalDeputy/UnderConstruction",
        parentId: "Production",
      },
      {
        id: "",
        label: "Production",
        link: "/TechnicalDeputy/UnderConstruction",
        parentId: "Production",
      },
    ],
  },
  {
    label: "حساب",
    isTitle: true,
  },

  {
    id: "tickets",
    label: "Tickets",
    link: "/#",
    icon: <PictureInPicture2 />,
    subItems: [
      {
        id: "submitTicket",
        label: "Submit Ticket",
        link: "/TechnicalDeputy/tickets/submitticket",
        parentId: "landing",
      },
      {
        id: "ticketList",
        label: "Ticket List",
        link: "/TechnicalDeputy/tickets/ticketlist",
        parentId: "landing",
      },
    ],
  },
  {
    id: "account",
    label: "Account",
    link: "#",
    icon: <CalendarDays />,
    subItems: [
      {
        id: "accountSetting",
        label: "Account Setting",
        link: "/TechnicalDeputy/account/setting",
        parentId: "account",
      },
      {
        id: "accountSetting",
        label: "تغییر پسورد",
        link: "/TechnicalDeputy/UnderConstruction",
        parentId: "account",
      },
    ],
  },
  {
    label: "Pages",
    isTitle: true,
  },

  {
    id: "pages",
    label: "Pages",
    icon: <Codesandbox />,
    parentId: 2,
    subItems: [
      {
        id: "faq",
        label: "FAQs",
        link: "/TechnicalDeputy/pages-faqs",
        parentId: "pages",
      },
      {
        id: "contactus",
        label: "Contact US",
        link: "/TechnicalDeputy/pages-contact-us",
        parentId: "pages",
      },
    ],
  },
];

export { menuDataTechnicalDeputy };
