// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_WORKER_NAME_TECHINICALDEPUTY } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

export interface Worker {
  id: number;
  name: string;
  role: string;
  level: number;
}
const api = new APIClient();

interface Workers {
  workers: Worker[];
}

let val: Workers;

const useFetchDataWorkers = () => {
  const [allWorkers, setData] = useState(val);
  const [loadingAllWorker, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_WORKER_NAME_TECHINICALDEPUTY, null)
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    allWorkers,
    loadingAllWorker,
  };
};

export default useFetchDataWorkers;
