import React from "react";
import { MoveRight, Gem, Smartphone, Globe } from "lucide-react";
import { useFormik } from "formik";
import Select from "react-select";
import { APIClient } from "helpers/api_helper";

import * as Yup from "yup";
import useFetchData from "./FetchData";
import { POST_ADMIN_PROJECT } from "helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const api = new APIClient();

interface Options {
  label: string;
  value?: string;
  isDisabled?: boolean;
  options?: Options[];
}

const Form = () => {
  const DefaultOptions: Options[] = [];

  let { data, loading } = useFetchData();

  if (!loading)
    data.members.map((customer: any) => {
      const newOption: Options = {
        label: customer.fullName,
        value: customer.id,
      };
      DefaultOptions.push(newOption);
    });

  const validation: any = useFormik({
    initialValues: {
      customerId: null,
      city: "",
      country: "",
      address: "",
      numberOfFloor: 0,
      projectType: "",
      areaSize: 0,
      buildingType: "",
    },
    validationSchema: Yup.object().shape({
      customerId: Yup.number().required("First Name is required"),
      city: Yup.string().required("Cityis required."),
      country: Yup.string().required("Country is required."),
      address: Yup.string().required("Phone Number is required."),
      numberOfFloor: Yup.number().required("Number of floor is required."),
      projectType: Yup.string().required("project Type is required."),
      areaSize: Yup.number().required("Area Size is required."),
      buildingType: Yup.string().required("Building Type is required."),
    }),
    onSubmit: async (values: any) => {
      try {
        console.log(values);
        await api.create(POST_ADMIN_PROJECT, values);
        toast.info("Added to Server");
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error");
      }
    },
  });

  const handleSelectChange = (value: any, action: any) => {
    validation.setFieldValue(action.name, value.value);
  };

  return (
    <React.Fragment>
      <h5 className="mb-3">Project Form</h5>
      <form
        action="#!"
        onSubmit={(event: any) => {
          event.preventDefault();
          validation.handleSubmit();
        }}
      >
        <label
          htmlFor="projectType"
          className="inline-block mb-3 text-base font-medium"
        >
          Select Project Type <span className="text-red-500">*</span>
        </label>
        <div
          id="projectTypegroup"
          className="grid grid-cols-1 gap-3 mb-4 md:grid-cols-3 lg:grid-cols-3"
        >
          <div>
            <input
              id="brandingRadio"
              name="projectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              value="roof garden"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              defaultChecked
            />
            <label
              htmlFor="brandingRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Gem className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">روف گاردن</span>
            </label>
          </div>
          <div>
            <input
              id="mobileDesignRadio"
              name="projectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="green wall"
            />
            <label
              htmlFor="mobileDesignRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Smartphone className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">دیوار سبز</span>
            </label>
          </div>

          <div>
            <input
              id="webDesignRadio"
              name="projectType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="green trace"
            />
            <label
              htmlFor="webDesignRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">تراس سبز</span>
            </label>
          </div>
        </div>

        <label
          htmlFor="buildingType"
          className="inline-block mb-3 text-base font-medium"
        >
          Select Building Type <span className="text-red-500">*</span>
        </label>
        <div
          id="buildingTypegroup"
          className="grid grid-cols-1 gap-3 mb-4 md:grid-cols-3 lg:grid-cols-3"
        >
          <div>
            <input
              id="businessRadio"
              name="buildingType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              value="roof garden"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              defaultChecked
            />
            <label
              htmlFor="businessRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Gem className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">تجاری</span>
            </label>
          </div>

          <div>
            <input
              id="officeRadio"
              name="buildingType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="green wall"
            />
            <label
              htmlFor="officeRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Smartphone className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">اداری</span>
            </label>
          </div>

          <div>
            <input
              id="residentialRadio"
              name="buildingType"
              className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500"
              type="radio"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value="green trace"
            />
            <label
              htmlFor="residentialRadio"
              className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:border-zink-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500"
            >
              <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
              <span className="block font-medium text-15">مسکونی</span>
            </label>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="nameInput"
              className="inline-block mb-2 text-base font-medium"
            >
              Name <span className="text-red-500">*</span>
            </label>
            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              data-choices
              name="customerId"
              options={DefaultOptions}
              value={DefaultOptions.find(
                (option) => option.value === validation.values.customerId
              )}
              onChange={handleSelectChange}
              onBlur={() => validation.setFieldTouched("customerId", true)}
            />
            {validation.touched.customerId && validation.errors.customerId ? (
              <div className="text-red-500">{validation.errors.customerId}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="cityInput"
              className="inline-block mb-2 text-base font-medium"
            >
              city <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="city"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="Enter city"
              value={validation.values.city}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.city && validation.errors.city ? (
              <div id="city" className="text-red-500">
                {validation.errors.city}
              </div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="phoneNumberInput"
              className="inline-block mb-2 text-base font-medium"
            >
              Address <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="address"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="Adreess"
              value={validation.values.address}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.address && validation.errors.address ? (
              <div className="text-red-500">{validation.errors.address}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="countryInput"
              className="inline-block mb-2 text-base font-medium"
            >
              country
            </label>
            <input
              type="text"
              name="country"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="Country"
              value={validation.values.country}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.country && validation.errors.country ? (
              <div className="text-red-500">{validation.errors.country}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="numberOfFloorInput"
              className="inline-block mb-2 text-base font-medium"
            >
              number Of Floor
            </label>
            <input
              type="number"
              name="numberOfFloor"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="Number Of Floor"
              value={validation.values.numberOfFloor}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.numberOfFloor &&
            validation.errors.numberOfFloor ? (
              <div className="text-red-500">
                {validation.errors.numberOfFloor}
              </div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="areaSizeInput"
              className="inline-block mb-2 text-base font-medium"
            >
              Area Size
            </label>
            <input
              type="number"
              name="areaSize"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              placeholder="Area Size"
              value={validation.values.areaSize}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.areaSize && validation.errors.areaSize ? (
              <div className="text-red-500">{validation.errors.areaSize}</div>
            ) : null}
          </div>
        </div>

        <div className="flex justify-between gap-2 mt-5">
          <button
            type="submit"
            data-action="next"
            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
          >
            <span className="align-middle">Submit</span>{" "}
            <MoveRight className="inline-block h-4 ml-1 rtl:rotate-180" />
          </button>
        </div>
      </form>
      <ToastContainer theme="colored" />
    </React.Fragment>
  );
};

export default Form;
