// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_MEMBERREQUESTOFPROJECT_MANAGER } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface MembersOfReqWork {
  fullName: string;
  role: string;
  level: number;
  projectCode: string;
  hour: number;
  id: number;
  memberId: number;
}

interface MembersOfReqWorks {
  membersOfReqWorks: MembersOfReqWork[];
}

let val: MembersOfReqWorks;

const useFetchData = (id: any) => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_MEMBERREQUESTOFPROJECT_MANAGER, { reqWorkId: id })
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
