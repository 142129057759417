import React, { useCallback, useState } from "react";
import useFetchData from "./FetchData";
import useFetchDataWorkList from "./useFetchDataWorkList";
import { APIClient } from "helpers/api_helper";

import { ToastContainer, toast } from "react-toastify";
import Modal from "Common/Components/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import { Plus } from "lucide-react";
import Select from "react-select";
import {
  POST_PROJECTADDWORK_TECHINICALDEPUTY,
  POST_EDITWORKCOUNT_TECHINICALDEPUTY,
  getParameterByName,
  GET_DELETEWORKFROMREQUEST_TECHINICALDEPUTY,
  GET_APPROOVEWORK_TECHNICALDEPUTY,
} from "helpers/url_helper";

const api = new APIClient();

const TableWorkRequestWork = () => {
  let x = 1;
  var reqWorkId = getParameterByName("reqWorkId");
  let { data, loading } = useFetchData(reqWorkId);
  let { allWorks, loadingAllworks } = useFetchDataWorkList();
  const [showWork, setShowWork] = useState<boolean>(false);

  const handleApproveWork = (id: any) => {
    console.log(id);
    const fetchData = async () => {
      try {
        await api
          .get(GET_APPROOVEWORK_TECHNICALDEPUTY, { workReqWorkId: id })
          .then(() => {
            window.location.reload();
          });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  //handleSelectChange
  const handleSelectChange = (value: any, action: any) => {
    validationWork.setFieldValue(action.name, value.value);
  };
  //select box modal
  interface Options {
    label: string;
    value?: string;
    isDisabled?: boolean;
    options?: Options[];
  }
  const DefaultOptions: Options[] = [];

  if (!loadingAllworks)
    allWorks.workActivities.map((work: any) => {
      const newOption: Options = {
        label: work.name,
        value: work.id,
      };
      DefaultOptions.push(newOption);
    });

  // validation
  const validationWork: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      count: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("کار را وارد کنید"),
      count: Yup.string().required("تعداد را وارد کنید"),
    }),

    onSubmit: async (values) => {
      try {
        const postDate = {
          workerActivitiesId: Number(values.name),
          reaquestWorksId: Number(reqWorkId),
          count: Number(values.count),
        };
        console.log(postDate);
        await api.create(POST_PROJECTADDWORK_TECHINICALDEPUTY, postDate);
        toast.info("Added to Server");
        // Reload the page to reflect changes
        window.location.reload();
      } catch (error) {
        toast.error("Error");
      }
      toggleWork();
    },
  });
  const toggleWork = useCallback(() => {
    if (showWork) {
      setShowWork(false);
    } else {
      setShowWork(true);

      validationWork.resetForm();
    }
  }, [showWork, validationWork]);

  const [showCount, setShowCount] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);

  // validation Count
  const validationCount: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      count: "",
    },
    validationSchema: Yup.object({
      count: Yup.number().required("تعداد را وارد کنید"),
    }),

    onSubmit: async (values) => {
      try {
        const postDate = {
          count: Number(values.count),
          id: id,
        };
        console.log(postDate);
        await api.create(POST_EDITWORKCOUNT_TECHINICALDEPUTY, postDate);
        toast.info("Added to Server");
        // Reload the page to reflect changes
        window.location.reload();
      } catch (error) {
        toast.error("Error");
      }
      toggleCount(0);
    },
  });

  const toggleCount = useCallback(
    (id: any) => {
      setId(id);
      if (showCount) {
        setShowCount(false);
      } else {
        setShowCount(true);
        validationCount.resetForm();
      }
    },
    [showCount, validationCount]
  );

  const handleDelete = async (id: any) => {
    try {
      await api.get(GET_DELETEWORKFROMREQUEST_TECHINICALDEPUTY, {
        id: id,
      });
      toast.info("Added to Server");
      window.location.reload();
    } catch (error) {
      toast.error("Error");
    }
  };

  if (loading) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <div className="overflow-x-auto">
            <h6 className="mb-12 text-15">اضافه کردن کار به جبهه کاری</h6>
            <button
              type="button"
              className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              onClick={toggleWork}
            >
              <Plus className="inline-block size-3" />{" "}
              <span className="align-middle">اضافه کردن کار</span>
            </button>
          </div>{" "}
        </div>
        <div className="">
          <div className="overflow-x-auto">
            <table className="w-full bg-custom-50 dark:bg-custom-500/10">
              <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                <tr>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    ردیف
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    نام محصول
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    متراژ یا تعداد
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    قیمت واحد
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    قیمت کل
                  </th>
                  {/* <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    واحد
                  </th> */}
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    وضعیت اجرا
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    عملیات
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr> */}
                {!loading &&
                  data.worksOfReqWorks.map((works) => (
                    <tr key={works.id}>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <a
                          href="#!" // Use downloadLink if available, otherwise fallback to "#"
                          className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                        >
                          {x++}
                        </a>
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {works.name || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {works.count || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {works.unitPrice || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {works.wholePrice || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {works.isDone ? (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-custom-500 border-custom-500 text-custom-50">
                            Done
                          </span>
                        ) : (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-red-500 border-red-500 text-red-50">
                            Not Done
                          </span>
                        )}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "red",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                          }}
                          onClick={() => handleDelete(works.id)}
                          className="btn btn-primary"
                        >
                          حذف
                        </button>
                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "orange",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                            marginRight: "3px",
                            marginTop: "3px",
                          }}
                          onClick={() => toggleCount(works.id)}
                          className="btn btn-primary"
                        >
                          ویرایش
                        </button>
                        {!works.isDone && !works.requestWorkFinished ? (
                          <button
                            style={{
                              display: "inline-block",
                              backgroundColor: "green",
                              color: "white",
                              padding: "10px 20px 10px",
                              border: "none",
                              marginRight: "3px",
                              marginTop: "3px",
                            }}
                            onClick={() => handleApproveWork(works.id)}
                            className="btn btn-primary"
                          >
                            تایید
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* work Modal */}
      <Modal
        show={showWork}
        onHide={toggleWork}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
        >
          <Modal.Title className="text-16"> اضافه کردن کار</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validationWork.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <Select
                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                data-choices
                name="name"
                id="name"
                options={DefaultOptions}
                value={
                  DefaultOptions.find(
                    (option) => option.value === validationWork.values.name
                  ) || null
                }
                onChange={handleSelectChange}
                onBlur={() => validationWork.setFieldTouched("name", true)}
              />
              {validationWork.touched.name && validationWork.errors.name ? (
                <p className="text-red-400">{validationWork.errors.name}</p>
              ) : null}
            </div>
            <div>
              <label
                htmlFor="countInput"
                className="inline-block mb-2 text-base font-medium"
              >
                تعداد یا متراژ
              </label>
              <input
                type="text"
                name="count"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder=" تعداد یا متراژ"
                value={validationWork.values.count}
                onChange={validationWork.handleChange}
                onBlur={validationWork.handleBlur}
              />
              {validationWork.touched.count && validationWork.errors.count ? (
                <div className="text-red-500">
                  {validationWork.errors.count}
                </div>
              ) : null}
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggleWork}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showCount}
        onHide={() => toggleCount(0)}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
        >
          <Modal.Title className="text-16">Edit Works</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validationCount.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <label
                htmlFor="countInput"
                className="inline-block mb-2 text-base font-medium"
              >
                میران کار
              </label>
              <input
                type="text"
                id="count"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter count"
                name="count"
                onChange={validationCount.handleChange}
                value={validationCount.values.count || ""}
              />
              {validationCount.touched.count && validationCount.errors.count ? (
                <p className="text-red-400">{validationCount.errors.count}</p>
              ) : null}
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={() => toggleCount(0)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                ویرایش
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer theme="colored" />
    </React.Fragment>
  );
};

export default TableWorkRequestWork;
