import React from "react";
import useFetchData from "./FetchData";
import { getParameterByName } from "helpers/url_helper";
import { useNavigate } from "react-router-dom";
import moment from "moment-jalaali";

const TableWorkRequestInvoices = () => {
  let x = 1;
  var reqWorkId = getParameterByName("reqWorkId");
  let { data, loading } = useFetchData(reqWorkId);

  const history = useNavigate();

  const handleNavigationToWorkOfRequestWork = (id: any) => {
    history(`/accountant/invoice/detail?invoiceId=${id}`);
  };

  if (loading) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-12 text-15"> فاکتورهای جبهه کاری</h6>
          <div className="">
            <div className="overflow-x-auto">
              <table className="w-full bg-custom-50 dark:bg-custom-500/10">
                <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                  <tr>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      ردیف
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      تاریخ
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      نام پرسنل
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      نام کارفرما
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      نام تیم
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      نام مدیر اجرا
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      مبلغ
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      وضعیت تایید پرسنل
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      وضعیت پرداخت
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      عملیات
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr> */}
                  {!loading &&
                    data.wokerInvoices.map((invoice) => (
                      <tr key={invoice.id}>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          <a
                            href="#!" // Use downloadLink if available, otherwise fallback to "#"
                            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                          >
                            {x++}
                          </a>
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {invoice && invoice.date
                            ? moment(invoice.date, "YYYY-MM-DD").format(
                                "jYYYY/jMM/jDD"
                              )
                            : "-"}{" "}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {invoice.workerName || "-"}{" "}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {invoice.employeName || "-"}{" "}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {invoice.teamName || "-"}{" "}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {invoice.managerName || "-"}{" "}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {invoice.amount || "-"}{" "}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {invoice.approved ? (
                            <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-custom-500 border-custom-500 text-custom-50">
                              Approved
                            </span>
                          ) : (
                            <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-red-500 border-red-500 text-red-50">
                              Not Approved
                            </span>
                          )}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {invoice.paidStatus > 0 ? (
                            <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-custom-500 border-custom-500 text-custom-50">
                              پرداخت شده
                            </span>
                          ) : (
                            <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-red-500 border-red-500 text-red-50">
                              پرداخت نشده
                            </span>
                          )}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          <button
                            style={{
                              display: "inline-block",
                              backgroundColor: "grey",
                              color: "white",
                              padding: "10px 20px 10px",
                              border: "none",
                              marginRight: "3px",
                              marginTop: "3px",
                            }}
                            onClick={() =>
                              handleNavigationToWorkOfRequestWork(invoice.id)
                            }
                            className="btn btn-primary"
                          >
                            فاکتور
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TableWorkRequestInvoices;
