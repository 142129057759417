import React from "react";
import useFetchData from "./FetchData";

function getParameterByName(name: any, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
const TableEstimateProject = () => {
  let x = 1;
  let projectId;
  projectId = getParameterByName("projectId");
  let { data, loading } = useFetchData(projectId);

  if (loading) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-12 text-15">Estimate Table</h6>

          <div className="overflow-x-auto">
            <table className="w-full bg-custom-50 dark:bg-custom-500/10">
              <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                <tr>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    row
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    name
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    تعداد
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    قیمت کل
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr> */}
                {!loading &&
                  data.estimates.map((estimate) => (
                    <tr key={estimate.id}>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <a
                          href="#!" // Use downloadLink if available, otherwise fallback to "#"
                          className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                        >
                          {x++}
                        </a>
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {estimate.name || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {estimate.quantity || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {estimate.wholePrice || "-"}{" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TableEstimateProject;
