// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_ADMINDASHBOARD, GET_NOTIFICATION } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface Notification {
  description: string;
  notificationPriority: number;
  title: string;
  createdDate: Date;
}

interface Notifications {
  notifications: Notification[];
}

let val: Notifications;

const useFetchData = () => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api.get(GET_NOTIFICATION, null).then((response) => {
          setData(JSON.parse(JSON.stringify(response)));
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
