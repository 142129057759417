import React from "react";
import useFetchData from "./FetchData";
import { useNavigate } from "react-router-dom";
//شمسی
import moment from "moment-jalaali";

const TableDesingContracts = () => {
  let x = 1;
  let { data, loading } = useFetchData();

  if (!loading) console.log(data);

  const history = useNavigate();

  const handleNavigationReqWork = (id: any) => {
    history(`/TeamHead/exeContract/Detail?projectId=${id}`);
  };
  const handleNavigationExeContract = (id: any) => {
    history(`/TeamHead/exeContract/Create?projectId=${id}`);
  };
  if (loading) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-12 text-15"> قرارداد های طراحی</h6>

          <div className="overflow-x-auto">
            <table className="w-full bg-custom-50 dark:bg-custom-500/10">
              <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                <tr>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    ردیف
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    شماره قراداد
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    تاریخ
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    کد فاکتور قرارداد
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    نام مشتری
                  </th>{" "}
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    کد قرارداد
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    عملیات
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr> */}
                {!loading &&
                  data.contracts.map((contract) => (
                    <tr key={contract.id}>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <a
                          href="#!" // Use downloadLink if available, otherwise fallback to "#"
                          className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                        >
                          {x++}
                        </a>
                      </td>{" "}
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {contract.conNumber || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {data && contract.date
                          ? moment(contract.date, "YYYY-MM-DD").format(
                              "jYYYY/jMM/jDD"
                            )
                          : "-"}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {contract.conNumber || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {contract.customerName || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {contract.projectCode || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "blue",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                            marginRight: "3px",
                            marginTop: "3px",
                          }}
                          onClick={() => handleNavigationReqWork(contract.id)}
                          className="btn btn-primary"
                        >
                          جزییات
                        </button>
                      
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TableDesingContracts;
