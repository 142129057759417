import React, { useCallback, useState } from "react";
import useFetchData from "./FetchData";
import { GET_MANAGER_APPROOVEWORK } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import Modal from "Common/Components/Modal";
import { POST_MANAGER_EDITWORKCOUNT } from "helpers/url_helper";
const api = new APIClient();

function getParameterByName(name: any, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
const TableRequestWork = () => {
  let x = 1;
  let reqWorkId = getParameterByName("reqWorkId");
  let { data, loading } = useFetchData(reqWorkId);

  const handleApproveWork = (id: any) => {
    console.log(id);
    const fetchData = async () => {
      try {
        await api
          .get(GET_MANAGER_APPROOVEWORK, { workReqWorkId: id })
          .then(() => {
            window.location.reload();
          });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const [show, setShow] = useState<boolean>(false);
  const [workId, setWorkId] = useState<number>(0);

  // validation
  const validation: any = useFormik({
    initialValues: {
      count: 0,
    },
    validationSchema: Yup.object({
      count: Yup.number().required("Please Enter Number"),
    }),

    onSubmit: async (values) => {
      try {
        const postData = {
          id: Number(workId),
          count: Number(values.count),
        };
        console.log(postData);
        await api.create(POST_MANAGER_EDITWORKCOUNT, postData);
        toast.info("Added to Server");
        window.location.reload();
      } catch (error) {
        toast.error("Error");
      }
      toggle(0);
    },
  });

  const toggle = useCallback(
    (id: any) => {
      setWorkId(id);
      if (show) {
        setShow(false);
      } else {
        setShow(true);

        validation.resetForm();
      }
    },
    [show, validation]
  );

  if (loading) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-12 text-15">Works Of RequestWork Table</h6>

          <div className="overflow-x-auto">
            <table className="w-full bg-custom-50 dark:bg-custom-500/10">
              <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                <tr>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    row
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    name
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    count
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    unit
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    Is Done
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    Description
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr> */}
                {!loading &&
                  data.worksOfReqWorks.map((work) => (
                    <tr key={work.id}>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <a
                          href="#!" // Use downloadLink if available, otherwise fallback to "#"
                          className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                        >
                          {x++}
                        </a>
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {work.name || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {work.count || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {work.unit || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {work.isDone ? (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-custom-500 border-custom-500 text-custom-50">
                            Done
                          </span>
                        ) : (
                          <span className="px-2.5 py-0.5 text-xs font-medium inline-block rounded border bg-red-500 border-red-500 text-red-50">
                            Not Done
                          </span>
                        )}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {work.description || "-"}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {!work.isDone ? (
                          <button
                            style={{
                              display: "inline-block",
                              backgroundColor: "green",
                              color: "white",
                              padding: "10px 20px 10px",
                              border: "none",
                              marginRight: "3px",
                              marginTop: "3px",
                            }}
                            onClick={() => handleApproveWork(work.id)}
                            className="btn btn-primary"
                          >
                            تایید
                          </button>
                        ) : (
                          ""
                        )}
                        {!work.isDone ? (
                          <button
                            style={{
                              display: "inline-block",
                              backgroundColor: "orange",
                              color: "white",
                              padding: "10px 20px 10px",
                              border: "none",
                              marginRight: "3px",
                              marginTop: "3px",
                            }}
                            onClick={() => toggle(work.id)}
                            className="btn btn-primary"
                          >
                            ویرایش
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* User Modal */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
        >
          <Modal.Title className="text-16">Edit Works</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <label
                htmlFor="countInput"
                className="inline-block mb-2 text-base font-medium"
              >
                میران کار
              </label>
              <input
                type="text"
                id="count"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter count"
                name="count"
                onChange={validation.handleChange}
                value={validation.values.count || ""}
              />
              {validation.touched.count && validation.errors.count ? (
                <p className="text-red-400">{validation.errors.count}</p>
              ) : null}
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                ویرایش
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer theme="colored" />
    </React.Fragment>
  );
};

export default TableRequestWork;
