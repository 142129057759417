import React, { useEffect, useState } from "react";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { withTranslation } from "react-i18next";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import useFetchData from "./FetchData";
import { APIClient } from "helpers/api_helper";
import { UPDATE_CUSTOMER_PROFILE } from "helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const api = new APIClient();
interface Option {
  label: string;
  value: string;
  isDisabled?: boolean;
}
//     { label: "Choice 2", value: "Choice 2" },
//     { label: "Choice 3", value: "Choice 3" },
//     { label: "Choice 4", value: "Choice 4" },
// ];

// const cityOptions: Option[] = [
//     { value: 'Madrid', label: 'Madrid' },
//     { value: 'Toronto', label: 'Toronto' },
//     { value: 'Vancouver', label: 'Vancouver' },
//     { value: 'London', label: 'London' },
//     { value: 'Manchester', label: 'Manchester' },
//     { value: 'Liverpool', label: 'Liverpool' },
//     { value: 'Paris', label: 'Paris' },
//     { value: 'Malaga', label: 'Malaga' },
//     { value: 'Washington', label: 'Washington', isDisabled: true },
//     { value: 'Lyon', label: 'Lyon' },
//     { value: 'Marseille', label: 'Marseille' },
//     { value: 'Hamburg', label: 'Hamburg' },
//     { value: 'Munich', label: 'Munich' },
//     { value: 'Barcelona', label: 'Barcelona' },
//     { value: 'Berlin', label: 'Berlin' },
//     { value: 'Montreal', label: 'Montreal' },
//     { value: 'New York', label: 'New York' },
//     { value: 'Michigan', label: 'Michigan' },
// ];

// const countryOptions: Option[] = [
//     { value: 'USA', label: 'USA' },
//     { value: 'Toronto', label: 'Toronto' },
//     { value: 'Vancouver', label: 'Vancouver' },
//     { value: 'London', label: 'London' },
//     { value: 'Manchester', label: 'Manchester' },
//     { value: 'Liverpool', label: 'Liverpool' },
//     { value: 'Paris', label: 'Paris' },
//     { value: 'Malaga', label: 'Malaga' },
//     { value: 'Washington', label: 'Washington', isDisabled: true },
//     { value: 'Lyon', label: 'Lyon' },
//     { value: 'Marseille', label: 'Marseille' },
//     { value: 'Hamburg', label: 'Hamburg' },
//     { value: 'Munich', label: 'Munich' },
//     { value: 'Barcelona', label: 'Barcelona' },
//     { value: 'Berlin', label: 'Berlin' },
//     { value: 'Montreal', label: 'Montreal' },
//     { value: 'New York', label: 'New York' },
//     { value: 'Michigan', label: 'Michigan' },
// ];

// const zipOptions: Option[] = [
//     { value: '00012', label: '00012' },
//     { value: '00014', label: '00014' },
//     { value: '00016', label: '00016' },
//     { value: '88800', label: '88800' },
//     { value: '00100', label: '00100' },
//     { value: '00001', label: '00001' },
// ];

const PersonalTabs = (props: any) => {
  let { data, loading } = useFetchData();

  const validation: any = useFormik({
    initialValues: {
      firstName: data ? data.firstName : "",
      lastName: data ? data.lastName : "",
      mobile: data ? data.mobile : "",
      country: data ? data.country : "",
      email: data ? data.email : "",
      city: data ? data.city : "",
      state: data ? data.state : "",
    },

    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      mobile: Yup.string().required("Mobile is required"),
      email: Yup.string().email("Please enter a valid email").required("email is required"),
      country: Yup.string().required("Country is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
    }),

    onSubmit: async (values: any) => {
      try {
        console.log(values);
        await api.create(UPDATE_CUSTOMER_PROFILE, values);
        toast.info("Added to Server");
        window.location.reload();
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error");
      }
    },
  });

  // const handleSubmit = async (e: any) => {
  //   e.preventDefault();
  //   try {
  //     // ارسال اطلاعات به سرور برای به‌روزرسانی
  //     const response = await fetch(GET_CUSTOMER_PROFILE, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     });
  //     if (response.ok) {
  //       alert("اطلاعات با موفقیت به‌روزرسانی شد.");
  //     } else {
  //       alert("خطا در به‌روزرسانی اطلاعات.");
  //     }
  //   } catch (error) {
  //     console.error("خطا در ارسال درخواست به سرور:", error);
  //   }
  // };
  // const handleSubmit = () => {};

  if (loading) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-1 text-15">{props.t("Personal Information")}</h6>
          <p className="mb-4 text-slate-500 dark:text-zink-200"></p>
          <form
            action="#!"
            onSubmit={(event: any) => {
              event.preventDefault();
              validation.handleSubmit();
            }}
          >
            <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
              <div className="xl:col-span-6">
                <label
                  htmlFor="inputValue"
                  className="inline-block mb-2 text-base font-medium"
                >
                  {props.t("First Name")}
                </label>
                <input
                  type="text"
                  id="firstName"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter your value"
                  defaultValue={data.firstName}
                  onChange={validation.handleChange}
                  // onBlur={validation.handleBlur}
                />
                {validation.touched.firstName && validation.errors.firstName ? (
                  <div className="text-red-500">
                    {validation.touched.firstName &&
                      validation.errors.firstName}
                  </div>
                ) : null}
              </div>
              <div className="xl:col-span-6">
                <label
                  htmlFor="lastName"
                  className="inline-block mb-2 text-base font-medium"
                >
                  {props.t("Last Name")}
                </label>
                <input
                  type="text"
                  id="lastName"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter your value"
                  defaultValue={data.lastName}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />{" "}
                {validation.touched.lastName && validation.errors.lastName ? (
                  <div className="text-red-500">
                    {validation.errors.lastName}
                  </div>
                ) : null}
              </div>
              <div className="xl:col-span-6">
                <label
                  htmlFor="mobile"
                  className="inline-block mb-2 text-base font-medium"
                >
                  {props.t("Phone Number")}
                </label>
                <input
                  type="text"
                  id="mobile"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="+214 8456 8459 23"
                  defaultValue={data.mobile}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />{" "}
                {validation.touched.mobile && validation.errors.mobile ? (
                  <div className="text-red-500">{validation.errors.mobile}</div>
                ) : null}
              </div>
              <div className="xl:col-span-6">
                <label
                  htmlFor="email"
                  className="inline-block mb-2 text-base font-medium"
                >
                  {props.t("Email Address")}
                </label>
                <input
                  type="email"
                  id="email"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter your email address"
                  defaultValue={data.email}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />{" "}
                {validation.touched.email && validation.errors.email ? (
                  <div className="text-red-500">{validation.errors.email}</div>
                ) : null}
              </div>

              <div className="xl:col-span-4">
                <label
                  htmlFor="country"
                  className="inline-block mb-2 text-base font-medium"
                >
                  {props.t("Country")}
                </label>
                <input
                  type="text"
                  id="country"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter your value"
                  defaultValue={data.country}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />{" "}
                {validation.touched.country && validation.errors.country ? (
                  <div className="text-red-500">
                    {validation.errors.country}
                  </div>
                ) : null}
              </div>
              <div className="xl:col-span-4">
                <label
                  htmlFor="city"
                  className="inline-block mb-2 text-base font-medium"
                >
                  {props.t("City")}
                </label>
                <input
                  type="text"
                  id="city"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter your value"
                  defaultValue={data.city}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />{" "}
                {validation.touched.city && validation.errors.city ? (
                  <div className="text-red-500">{validation.errors.city}</div>
                ) : null}
              </div>

              <div className="xl:col-span-4">
                <label
                  htmlFor="state"
                  className="inline-block mb-2 text-base font-medium"
                >
                  {props.t("State")}
                </label>
                <input
                  type="text"
                  id="state"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter your value"
                  defaultValue={data.state}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />{" "}
                {validation.touched.state && validation.errors.state ? (
                  <div className="text-red-500">{validation.errors.state}</div>
                ) : null}
              </div>
            </div>
            <div className="flex justify-end mt-6 gap-x-4">
              {/* <button
                type="button"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {props.t("Update")}
              </button> */}
              <button
                type="submit"
                data-action="next"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                <span className="align-middle">{props.t("Update")}</span>{" "}
              </button>
            </div>
          </form>{" "}
          <ToastContainer theme="colored" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(PersonalTabs);
