// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_PROJECTLISTWORK_TEAMHEAD } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

export interface ReqWork {
  id: number;
  name: string;
  unit: string;
  description: string;
  height: number;
  width: number;
  length: number;
  workType: number;
  
}
const api = new APIClient();

interface ReqWorksList {
  workActivities: ReqWork[];
}

let val: ReqWorksList;

const useFetchDataWorkList = () => {
  const [reqWorksList, setData] = useState(val);
  const [loading1, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_PROJECTLISTWORK_TEAMHEAD, null)
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    reqWorksList,
    loading1,
  };
};

export default useFetchDataWorkList;
