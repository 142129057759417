import React, { useCallback, useEffect, useState } from "react";
import useFetchData from "./useFetchData";
import { useNavigate } from "react-router-dom";
import { APIClient } from "helpers/api_helper";
import {
  POST_MANAGER_TEAMHEAD,
  POST_REQUESTWORK_TEAMHEAD,
  DELETEREQUESTWORK_PROJECT_TEAMHEAD,
} from "helpers/url_helper";
import { DatePicker } from "zaman";
import styles from "./mystyle.module.css";
import { ToastContainer, toast } from "react-toastify";
import Modal from "Common/Components/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReqWork } from "./useFetchData";
import useFetchDataManager, { Manager } from "./useFetchDataManager";
import "react-toastify/dist/ReactToastify.css";
import { Plus } from "lucide-react";
import Select from "react-select";
//شمسی
import moment from "moment-jalaali";

const api = new APIClient();

function getParameterByName(name: any, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const ColorsTable = (props: any) => {
  const [data, setData] = useState<ReqWork[]>([]); // Initialize with an empty array
  const [manager, setManager] = useState<Manager[]>([]); // Initialize with an empty array
  const [reqworkid, setReqworkid] = useState<number>(0);
  // let x = 1;
  var projectId = getParameterByName("projectId") || "";
  let { reqWorks, loading } = useFetchData(projectId);
  let { managers, loading1 } = useFetchDataManager();
  if (!loading1) console.log(managers);
  const handleSelectChange = (value: any, action: any) => {
    validation2.setFieldValue(action.name, value.value);
  };
  //select box modal manager
  interface Options {
    label: string;
    value?: string;
    isDisabled?: boolean;
    options?: Options[];
  }
  const DefaultOptions: Options[] = [];

  if (!loading1)
    managers.managers.map((manager: any) => {
      const newOption: Options = {
        label: manager.name,
        value: manager.id,
      };
      DefaultOptions.push(newOption);
    });

  //modals show

  const [show, setShow] = useState<boolean>(false);
  const [show1, setShow1] = useState<boolean>(false);
  const [reqdata, setReqdata] = useState(0);
  // Function to toggle the visibility of the "managerModal" modal

  // console.log(data);
  useEffect(() => {
    if (reqWorks) {
      setData(reqWorks.reqWorkOfProjects); // Assuming reqWorkOfProjects is the array you want to set
    }
  }, [reqWorks]); // Dependency array includes reqWorks to react to its changes

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      note: "",
      date1: "",
      // Set initial values from reqWorks if available
      ...(reqWorks && reqWorks.reqWorkOfProjects.length > 0
        ? reqWorks.reqWorkOfProjects[0] // Assuming the first item in reqWorkOfProjects array
        : {}),
    },
    validationSchema: Yup.object({
      note: Yup.string().required("توضیحات را وارد کنید"),
      date1: Yup.date()
        .required("تاریخ را وارد کنید")
        .test(
          "is-valid-date",
          "Invalid date format",
          (value) => !value || new Date(value).toString() !== "Invalid Date"
        ),
    }),

    onSubmit: async (values: any) => {
      try {
        const postDate = {
          note: values.note,
          projectId: projectId,
          date:
            values.date1 && typeof values.date1 === "object"
              ? values.date1.toISOString()
              : values.date1,
        };

        const response = await api.create(POST_REQUESTWORK_TEAMHEAD, postDate);

        const newProject = response.data;
        console.log(newProject);

        // Update the state to immediately show the new row in the UI
        setData((currentData) => [...currentData, values]);
        // console.log(x);
        toast.info("Added to Server");
        window.location.reload();
      } catch (error) {
        toast.error("Error");
      }
      toggle();
    },
  });
  const validation2: any = useFormik({
    initialValues: {
      // Set initial values from reqWorks if available
      ...(managers && managers.managers.length > 0
        ? managers.managers[0] // Assuming the first item in reqWorkOfProjects array
        : {}),
    },
    validationSchema: Yup.object().shape({
      managerId: Yup.string().required(" required."),
    }),
    onSubmit: async (values: any, reqWorkOfProjects: any) => {
      try {
        const postManager1 = {
          managerId: values.managerId,
          reqWorkId: reqworkid,
        };
        console.log(postManager1);
        // API call to create a new manager
        const response = await api.create(POST_MANAGER_TEAMHEAD, postManager1);
        const newManagerData = response.data; // Assume response.data contains the new manager details

        // Update the manager state to include the new manager
        setManager((currentManagers) => [...currentManagers, newManagerData]);
        toast.info("Added to Server");
        window.location.reload();

        // Optionally close the modal
        toggle2(0);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error");
      }
    },
  });
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);

      validation.resetForm();
    }
  }, [show, validation]);

  const toggle2 = useCallback((id: any) => {
    setReqworkid(id);
    setShow1((prevShow1) => {
      return !prevShow1; // This toggles the state
    });
  }, []);

  const handleDelete = async (projectId: any) => {
    try {
      const postDate = {
        reqWorkId: projectId,
      };
      console.log(postDate);
      await api.get(DELETEREQUESTWORK_PROJECT_TEAMHEAD, postDate);
      toast.info("Added to Server");
      // Reload the page to reflect changes
      window.location.reload();
    } catch (error) {
      toast.error("Error");
    }
  };

  const history = useNavigate();

  // const handleNavigationMembers = (id: any) => {
  //   // console.log("handleNavigationMembers");
  //   history(`/TeamHead/ReqWork/Memberlist?reqWorkId=${id}`);
  // };
  const handleNavigationWorks = (id: any) => {
    history(`/TeamHead/ReqWork/Worklist?reqWorkId=${id}`);
  };
  const handleNavigationWorkers = (id: any) => {
    history(`/TeamHead/ReqWork/Workerlist?reqWorkId=${id}`);
  };

  if (!data) return <p>No data available.</p>;
  if (data)
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <button
              type="button"
              className="mb-12 text-15 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              onClick={toggle}
            >
              <Plus className="inline-block size-3" />{" "}
              <span className="align-middle"> اضافه کردن جبهه کاری</span>
            </button>
            <div className="overflow-x-auto">
              <table className="w-full bg-custom-50 dark:bg-custom-500/10">
                <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                  <tr>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      ردیف
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      تاریخ
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      توضیحات
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      کد پروژه
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      مدیر اجرا
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      جمع کل
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                      عملیات
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr> */}

                  {!loading &&
                    data &&
                    data?.map((reqwork, index) => (
                      <tr key={reqwork && reqwork.id}>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          <a
                            href="#!" // Use downloadLink if available, otherwise fallback to "#"
                            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                          >
                            {index + 1}
                          </a>
                        </td>
                        {/* <td>{index + 1}</td> */}
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {/* {reqwork && reqwork.date} */}
                          {reqwork && reqwork.date
                            ? moment(reqwork.date, "YYYY-MM-DD").format(
                                "jYYYY/jMM/jDD"
                              )
                            : "-"}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {reqwork && reqwork.note}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {reqwork && reqwork.projectCode}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {reqwork && reqwork.projectManagerName}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          {reqwork && reqwork.wholeAmount}
                        </td>
                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                          <button
                            style={{
                              display: "inline-block",
                              backgroundColor: "blue",
                              color: "white",
                              padding: "10px 20px 10px",
                              border: "none",
                            }}
                            onClick={() => handleNavigationWorkers(reqwork.id)}
                            className="btn btn-primary"
                          >
                            پرسنل اجرا
                          </button>
                          <button
                            style={{
                              display: "inline-block",
                              backgroundColor: "blue",
                              color: "white",
                              padding: "10px 20px 10px",
                              border: "none",
                              marginRight: "3px",
                            }}
                            onClick={() => handleNavigationWorks(reqwork.id)}
                            className="btn btn-primary"
                          >
                            کارها
                          </button>
                          {/* مدیر  اجرا */}
                          <button
                            type="button"
                            style={{
                              display: "inline-block",
                              backgroundColor: "green",
                              color: "white",
                              //padding: "10px 20px 10px",
                              marginBottom: "auto",
                              border: "none",
                              marginRight: "3px",
                            }}
                            className="mb-12 text-15 text-white btn bg-custom-500 border-custom-500  hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                            onClick={() => toggle2(reqwork.id)}
                          >
                            {/* <Plus className="inline-block size-3" />{" "} */}
                            <span className="">مدیر اجرا</span>
                          </button>
                          <button
                            type="button"
                            style={{
                              display: "inline-block",
                              backgroundColor: "red",
                              color: "white",
                              //padding: "10px 20px 10px",
                              marginBottom: "auto",
                              border: "none",
                              marginRight: "3px",
                            }}
                            className="mb-12 text-15 text-white btn bg-custom-500 border-custom-500  hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                            onClick={() => handleDelete(reqwork.id)}
                          >
                            {/* <Plus className="inline-block size-3" />{" "} */}
                            <span className=""> حذف</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* User Modal */}
        <Modal
          show={show}
          onHide={toggle}
          id="defaultModal"
          modal-center="true"
          className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
          dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
        >
          <Modal.Header
            className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
            closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
          >
            <Modal.Title className="text-16">اضافه کردن جبهه کاری</Modal.Title>
          </Modal.Header>
          <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
            <form
              action="#!"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="mb-3">
                <label
                  htmlFor="noteInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  شرح فعالیت
                </label>
                <input
                  type="text"
                  id="noteInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter note"
                  name="note"
                  onChange={validation.handleChange}
                  value={validation.values.note || ""}
                />
                {validation.touched.note && validation.errors.note ? (
                  <p className="text-red-400">{validation.errors.note}</p>
                ) : null}
              </div>

              <div className="mb-3">
                <label
                  htmlFor="dateInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  تاریخ
                </label>
                <DatePicker
                  className={styles.bigblue}
                  round="x4"
                  position="center"
                  onChange={(newDate) => {
                    console.log(newDate);
                    // Ensure the date is always formatted as a string before updating the state
                    let dateString = "";
                    if (newDate && newDate.value) {
                      // Assuming newDate.value is a date string or a timestamp
                      dateString = new Date(newDate.value).toISOString();
                    }
                    validation.setFieldValue("date1", dateString);
                  }}
                />
                {validation.touched.date1 && validation.errors.date1 ? (
                  <div className="text-red-500">{validation.errors.date1}</div>
                ) : null}
              </div>
              <div className="flex justify-end gap-2 mt-4">
                <button
                  type="reset"
                  data-modal-close="addDocuments"
                  className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                  onClick={toggle}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                >
                  Add
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        {/* work Modal */}
        <Modal
          show={show1}
          onHide={toggle2}
          id="defaultModal2"
          modal-center="true"
          className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
          dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
        >
          <Modal.Header
            className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
            closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
          >
            <Modal.Title className="text-16">اضافه کردن مدیر اجرا</Modal.Title>
          </Modal.Header>
          <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
            <form
              action="#!"
              onSubmit={(e) => {
                e.preventDefault();
                validation2.handleSubmit();
                return false;
              }}
            >
              <div className="mb-3">
                <Select
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  data-choices
                  name="managerId"
                  options={DefaultOptions}
                  value={
                    DefaultOptions.find(
                      (option) => option.value === validation2.values.managerId
                    ) || null
                  } // Provide a default value if find() returns undefined
                  onChange={handleSelectChange}
                  onBlur={() => validation2.setFieldTouched("managerId", true)}
                  // Additional props required by the Select component
                />
                {validation2.touched.managerId &&
                validation2.errors.managerId ? (
                  <p className="text-red-400">{validation2.errors.managerId}</p>
                ) : null}
              </div>

              <div className="flex justify-end gap-2 mt-4">
                <button
                  type="reset"
                  data-modal-close="addDocuments"
                  className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                  onClick={toggle}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                >
                  Add
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <ToastContainer theme="colored" />
      </React.Fragment>
    );
};

export default ColorsTable;
