// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_TEAMHEADٍ_ESTIMATEWORKLIST } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

export interface EstimateOfProject {
  id: number;
  workActivityName: number;
  count: number;
  note: string;
  wholePrice: number;
}
const api = new APIClient();

interface EstimateOfProjects {
  estimateOfProjects: EstimateOfProject[];
}

let val: EstimateOfProjects;

const useFetchData = (id: any) => {
  const [estimate, setData] = useState(val);
  const [loadingEstimate, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_TEAMHEADٍ_ESTIMATEWORKLIST, { projectId: id })
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return {
    estimate,
    loadingEstimate,
  };
};

export default useFetchData;
