// use-fetch-data.js
import { useEffect, useState } from "react";
import { GET_INVOICEOFREQUESTWORK_ACCOUNTANT } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface Invoice {
  amount: number;
  approved: boolean;
  projectAddress: string;
  wholeDayAmount: number;
  description: string;
  hourlyPrice: number;
  managerName: string;
  teamName: string;
  paidStatus: number;
  employeName: string;
  workerName: string;
  id: number;
  date: string;
}

interface Invoices {
  wokerInvoices: Invoice[];
}

let val: Invoices;

const useFetchData = (id: any) => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(GET_INVOICEOFREQUESTWORK_ACCOUNTANT, { reqWorkId: id })
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
