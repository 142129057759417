// use-fetch-data.js
import { useEffect, useState } from "react";
import { POST_DETAILWORKACTIVITY_TECHNICALDEPUTY } from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

interface Work {
  name: string;
  unit: string;
  price: number;
  description: string;
}

let val: Work;

const useFetchData = (id: any) => {
  const [data, setData] = useState(val);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await api
          .get(POST_DETAILWORKACTIVITY_TECHNICALDEPUTY, { workActivityId: id })
          .then((response) => {
            setData(JSON.parse(JSON.stringify(response)));
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchData;
