import React, { useCallback, useState } from "react";
import useFetchData from "./FetchData";
import { APIClient } from "helpers/api_helper";
import { ToastContainer, toast } from "react-toastify";
import Modal from "Common/Components/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { Plus } from "lucide-react";
import Select from "react-select";
import {
  POST_DELETEWORKERS_TECHINICALDEPUTY,
  POST_ADDMEMBERHOUR_TECHINICALDEPUTY,
  POST_PROJECTADDWORKERS_TECHINICALDEPUTY,
  getParameterByName,
} from "helpers/url_helper";
import useFetchDataWorkers from "./useFetchDataWorkers";

const api = new APIClient();

const ColorsTable = () => {
  let x = 1;
  var reqWorkId = getParameterByName("reqWorkId");
  let { allWorkers, loadingAllWorker } = useFetchDataWorkers();
  let { data, loading } = useFetchData(reqWorkId);
  const [workerId, setWorkerId] = useState<number>(0);
  const [showHour, setShowHour] = useState<boolean>(false);

  const [show, setShow] = useState<boolean>(false);

  //handleSelectChange
  const handleSelectChange = (value: any, action: any) => {
    validation.setFieldValue(action.name, value.value);
  };
  //select box modal
  interface Options {
    label: string;
    value?: string;
    isDisabled?: boolean;
    options?: Options[];
  }
  const DefaultOptions: Options[] = [];

  if (!loadingAllWorker)
    allWorkers.workers.map((worker: any) => {
      const newOption: Options = {
        label: worker.name,
        value: worker.id,
      };
      DefaultOptions.push(newOption);
    });
  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      worker: "",
    },
    validationSchema: Yup.object({
      worker: Yup.string().required(" نام پرسنل اجرا را وارد کنید"),
    }),

    onSubmit: async (values) => {
      try {
        const postDate = {
          workerId: values.worker,
          requestWorkId: Number(reqWorkId),
        };
        console.log(postDate);
        await api.create(POST_PROJECTADDWORKERS_TECHINICALDEPUTY, postDate);
        toast.info("Added to Server");
        window.location.reload();
      } catch (error) {
        toast.error("Error");
      }
      toggle();
    },
  });

  const handleDelete = async (memberId: any) => {
    try {
      const postDate = {
        requestWorkId: Number(reqWorkId),
        memberId: memberId,
      };
      console.log(postDate);
      await api.create(POST_DELETEWORKERS_TECHINICALDEPUTY, postDate);
      toast.info("Added to Server");
      window.location.reload();
    } catch (error) {
      toast.error("Error");
    }
  };

  // validation
  const validationHour: any = useFormik({
    initialValues: {
      hour: 0,
    },
    validationSchema: Yup.object({
      hour: Yup.number().required("Please Enter Number"),
    }),

    onSubmit: async (values) => {
      try {
        const postData = {
          workReqId: Number(reqWorkId),
          hour: Number(values.hour),
          memberId: workerId,
        };
        console.log(postData);
        await api.create(POST_ADDMEMBERHOUR_TECHINICALDEPUTY, postData);
        toast.info("Added to Server");
        window.location.reload();
      } catch (error) {
        toast.error("Error");
      }
      toggleHour(0);
    },
  });

  const toggleHour = useCallback(
    (id: any) => {
      setWorkerId(id);
      if (showHour) {
        setShowHour(false);
      } else {
        setShowHour(true);
        validationHour.resetForm();
      }
    },
    [showHour, validationHour]
  );

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);

      validation.resetForm();
    }
  }, [show, validation]);

  if (loading) return <p>loading ...</p>;
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <div className="overflow-x-auto">
            <h6 className="mb-12 text-15">
              اضافه کردن پرسنل اجرا به جبهه کاری
            </h6>
            <button
              type="button"
              className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              onClick={toggle}
            >
              <Plus className="inline-block size-3" />{" "}
              <span className="align-middle">اضافه کردن پرسنل اجرا</span>
            </button>
          </div>{" "}
        </div>
        <div className="">
          <div className="overflow-x-auto">
            <table className="w-full bg-custom-50 dark:bg-custom-500/10">
              <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                <tr>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    ردیف
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    نام پرسنل اجرا
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    لول
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    ساعت کاری
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                    عملیات
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr> */}
                {!loading &&
                  data.membersOfReqWorks.map((worker) => (
                    <tr key={worker.id}>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <a
                          href="#!"
                          className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                        >
                          {x++}
                        </a>
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {worker.fullName || "-"}{" "}
                      </td>

                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {worker.level}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        {worker.hour}{" "}
                      </td>
                      <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "red",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                          }}
                          onClick={() => handleDelete(worker.id)}
                          className="btn btn-primary"
                        >
                          حذف
                        </button>
                        <button
                          style={{
                            display: "inline-block",
                            backgroundColor: "green",
                            color: "white",
                            padding: "10px 20px 10px",
                            border: "none",
                            marginRight: "3px",
                            marginTop: "3px",
                          }}
                          onClick={() => toggleHour(worker.id)}
                          className="btn btn-primary"
                        >
                          ساعت
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
        >
          <Modal.Title className="text-16"> اضافه کردن پرسنل</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <Select
                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                data-choices
                name="worker"
                id="worker"
                options={DefaultOptions}
                value={
                  DefaultOptions.find(
                    (option) => option.value === validation.values.worker
                  ) || null
                }
                onChange={handleSelectChange}
                onBlur={() => validation.setFieldTouched("worker", true)}
              />
              {validation.touched.worker && validation.errors.worker ? (
                <p className="text-red-400">{validation.errors.worker}</p>
              ) : null}
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showHour}
        onHide={toggleHour}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
        >
          <Modal.Title className="text-16">Add Hour</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validationHour.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <label
                htmlFor="hourInput"
                className="inline-block mb-2 text-base font-medium"
              >
                میران ساعت کاری
              </label>
              <input
                type="text"
                id="hour"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter hour"
                name="hour"
                onChange={validationHour.handleChange}
                value={validationHour.values.hour || ""}
              />
              {validationHour.touched.hour && validationHour.errors.hour ? (
                <p className="text-red-400">{validationHour.errors.hour}</p>
              ) : null}
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggleHour}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer theme="colored" />
    </React.Fragment>
  );
};

export default ColorsTable;
